import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Menu, Breadcrumb } from 'antd';
import ProfileLogin from '../../../../src/assets/images/inventive_byte/profile-login.png'
import DropDownIcon from '../../../../src/assets/images/inventive_byte/drop-down.png'
import SiderToggle from '../../../../src/assets/images/inventive_byte/sider-toggle.png'

import LeftPanel from '../LeftPanel'
import './Header.scss'
import ProfileToggle from "../ProfileToggle/ProfileToggle";

const HeaderMobile = props => {
    const [activeLeftPanel,setActiveLeftPanel] = useState(false)

    const handleLeftPanel = () => {
        setActiveLeftPanel(!activeLeftPanel)
    }

    return (
        <div className="about-self-header position-relative flex justify-content-end">
            {activeLeftPanel && <LeftPanel isActiveHeader="true" handleMobilePanel={handleLeftPanel}/> }
            <div className="flex align-items-start menu-btn justify-content-start">
                <img src={SiderToggle} onClick={handleLeftPanel}/>
            </div>
            <div className="flex align-items-center justify-content-endr">
               <ProfileToggle/>
            </div>
        </div>
    )
}

export default withRouter(HeaderMobile)
