import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const getBlogList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_BLOGS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/blogs`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_BLOGS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_BLOGS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getBlog = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_BLOG_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`)
        .then(res => {
          console.log(res,"resresres")
          dispatch({
            type: actionTypes.GET_BLOG_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_BLOG_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getBlogListWithParam = (page) => {
      return async dispatch => {
        dispatch({
          type: actionTypes.GET_BLOGS_INIT,
        })
        await api
          .getwithReqForm(`${process.env.REACT_APP_API_URL}/admin/blogs?page=`+page)
          .then(res => {
            dispatch({
              type: actionTypes.GET_BLOGS_SUCCESS,
              payload: res,
            })
            return
          })
          .catch(error => {
            dispatch({
              type: actionTypes.GET_BLOGS_FAILED,
            })
            throw error?.response?.data?.error || error?.message
          })
      }
    }

  export const postBlog = (data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.POST_BLOGS_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/blogs`, data)
        .then(res => {
          if(res.data) {
            dispatch({
              type: actionTypes.POST_BLOGS_SUCCESS,
              payload: res,
            })
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.POST_BLOGS_FAILED,
          })
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editBlog = (data, id, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_BLOGS_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`, data)
        .then(res => {
          console.log(res,"EDIT_BLOGS_SUCCESS")
          if(res.result) {
            console.log(113)
            dispatch({
              type: actionTypes.EDIT_BLOGS_SUCCESS,
              payload: res.data,
              id: res.data.id
            })
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const deleteBlog = (id, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.DELETE_BLOGS_INIT,
      })
      await api
        .delete(`${process.env.REACT_APP_API_URL}/admin/blog/${id}`)
        .then(res => {
          console.log(res,"EDIT_BLOGS_SUCCESS")
          if(res.result) {
            console.log(113)
            dispatch({
              type: actionTypes.DELETE_BLOGS_SUCCESS,
              payload: res.data,
              id: id
            })
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const setBlogDetail = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.SET_BLOG_DETAIL,
      })
    }
  }