import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Row, Col, Select } from "antd";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import "./AddInvoice.scss";
import InvoiceReport from "./InvoiceReport";

const AddInvoice = (props) => {
  const { Option } = Select;
  const [active, setActive] = useState(false);

  const handleInvoiceReport = () => {
    setActive(true);
  };

  return (
    <div>
      <div className="d-none d-md-none d-lg-block">
        <Header />
      </div>
      <div className="d-block d-md-block d-lg-none">
        <HeaderMobile />
      </div>
      <LeftPanel />
      <div className="client-invoice-wrapper">
        <div className="header-left">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <ArrowLeftOutlined /> Back
          </span>
        </div>

        <>
            {active ? <InvoiceReport/> :
                <div className="client-invoice-main-wrapper">
                    <div className="client-invoice-title-wrapper d-flex justify-content-between align-items-center">
                        <h5 className="client-invoice-title">New Invoice</h5>
                        <CloseOutlined/>
                    </div>
                    <div className="client-invoice-card-main">
                        <div className="client-invoice-card">
                            <Form className="client-invoice-form">
                                <Row
                                    className="input-fields clients-invoice-name-wrapper"
                                    gutter={[15, 15]}
                                >
                                    <Col className="client-invoice-form-group" xl={{span: 12}} lg={{span: 12}}
                                         xs={{span: 24}}>
                                        <Label className="client-invoice-label" for="website">
                                            Invoice No
                                        </Label>
                                        <Input
                                            className="client-invoice-input"
                                            type="text"
                                            name="invoice_no"
                                            id="invoice_no"
                                            onChange=""
                                            value=""
                                        />
                                    </Col>
                                    <Col className="client-invoice-form-group" xl={{span: 12}} lg={{span: 12}}
                                         xs={{span: 24}}>
                                        <Label className="client-invoice-label" for="website">
                                            Project Name
                                        </Label>
                                        <Select defaultvalue="Select the project name"
                                                className="client-invoice-select">
                                            <Option value="IT">IT</Option>
                                            <Option value="HR">HR</Option>
                                        </Select>
                                    </Col>
                                </Row>

                                <div className="client-invoice-buttons flex">
                                    <div className="button-group flex align-items-center justify-content-center">
                                        <Button
                                            className="button-save"
                                            onClick={handleInvoiceReport}
                                        >
                                            Save
                                        </Button>
                                        <Button className="button-cancel">Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            }
        </>
      </div>
    </div>
  );
};

export default withRouter(AddInvoice);
