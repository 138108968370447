import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Empty, message, Spin, Tooltip } from "antd";
import moment from "moment";
import ClientManagement from "../../../../src/assets/images/inventive_byte/client-management.png";
import AccountPendingIcon from "../../../../src/assets/images/inventive_byte/acc-pen.png";
import AccountRecevieIcon from "../../../../src/assets/images/inventive_byte/acc-rec.png";
import DownloadIcon from "../../../../src/assets/images/inventive_byte/download.png";
import ClientDashIcon from "../../../../src/assets/images/inventive_byte/client-dashboard-icon.png";
import LeftPanel from "../LeftPanel";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import * as DashboardActions from "../../../redux/DashboardFlowControl/DashboardActions";
import { saveAs } from 'file-saver';
import "./Dashboard.scss";
import Loader from "../../components/Loader/Loader";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const {
    user_permission,
    project_counts,
    get_monthly_account,
    get_pending_agreement,
    loading,
    pendingAgreementLoading,
    monthlyLoading,
    projectCountLoading
  } = useSelector((state) => state.DashboardReducers);
  const [isLoader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(DashboardActions.getUserPermissions());
    dispatch(DashboardActions.getProjectCounts());
    dispatch(DashboardActions.getMonthlyAccounts());
    dispatch(DashboardActions.getPendingAgreements());
  }, []);

  useEffect(() => {}, [
    user_permission,
    project_counts,
    get_monthly_account,
    get_pending_agreement,
  ]);

  const sendReminder = (id) => {
    setLoader(id)
    dispatch(DashboardActions.sendReminder(
      id,
      (res) => {
        setLoader(false)
        message.success(res);
      },
      (err) => {
        setLoader(false)
        message.error(err);
      }
    ));
  }

  const onDownload = (data, key) => {
    if(data[key]) {
      saveAs(
        `https://d3u1baow9deq5h.cloudfront.net/${data[key]}`,
        data?.name || data[key].split('/').slice(-1)[0]
      );
    }else {
      message.warn("No Agreement Found")

    }
  }

  return (
    <>
    {projectCountLoading ? <Loader /> : 
    
      <div className="client-dashboard-main">
        <div className="client-dashboard-wrapper flex flex-column justify-content-between">
          <div className="clients-dashboard align-items-center d-none d-sm-none d-md-flex">
            <img className="dashboard-img" src={ClientDashIcon} />
            <p className="dashboard-text mb-0">Client Dashboard</p>
          </div>
          <div className="flex justify-content-between align-items-center clients-dashboard-projects">
            <div className="dashboard-project flex justify-content-start align-items-center">
              <Button className="project-number">
                {project_counts?.ongoing}
              </Button>
              <span className="project-type">Ongoing Projects</span>
            </div>
            <div className="dashboard-project flex justify-content-start align-items-center">
              <Button className="project-number">
                {project_counts?.inactive}
              </Button>
              <span className="project-type">Inactive Projects</span>
            </div>
            <div className="dashboard-project flex justify-content-start align-items-center">
              <Button className="project-number">
                {project_counts?.newboarded}
              </Button>
              <span className="project-type">newly onboarded</span>
            </div>
          </div>

          <div className="flex align-items-center justify-content-between clients-dashboard-monthly-list">
            <div className="clients-dashboard-monthly">
              {monthlyLoading && <Spin size="large" />}
              <div className="clients-dashboard-receivables">
                <span className="clients-project">
                  {get_monthly_account?.length}
                </span>
                <span className="clients-project-text">
                  This Month's Account Receivables
                </span>
              </div>

              {get_monthly_account?.length ? get_monthly_account?.map((data, i) => {
                return (
                  <div key={i} className="client-project-list flex justify-content-between align-items-center align-items-sm-center align-items-md-end align-items-lg-end">
                    <div className="d-flex align-items-center clients-project-main">
                      <div className="flex align-items-center">
                        <img src={AccountRecevieIcon} />
                      </div>
                      <div className="clients-project-date">
                        <span className="project-rs">${data?.amount}</span>
                        <div className="project-date">
                          <span>Due Date:</span>
                          <span>
                            {" "}
                            {moment(data?.due_date).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="clients-project-name">
                      <span>Project:</span>
                      <span> {data?.client?.project?.name}</span>
                    </div>
                    <img src={DownloadIcon} className="downloadPdfIcon" onClick={() => onDownload(data, "invoice_pdf")}/>
                  </div>
                );
              }) : !monthlyLoading && <Empty />}
            </div>

            <div className="clients-dashboard-monthly">
              <div className="clients-dashboard-receivables">
                <span className="clients-project">
                  {get_pending_agreement?.length}
                </span>
                <span className="clients-project-text">
                  6 Month's Pending updates
                </span>
              </div>
              {pendingAgreementLoading && <Spin size="large" />}
              {(get_pending_agreement?.length) ? get_pending_agreement?.map((data, i) => {
                return (
                  <div key={i} className="client-agreements-list flex justify-content-between align-items-center align-items-sm-center align-items-md-end">
                    <div className="client-agreements-contact-wrapper">
                      <img src={AccountPendingIcon} />
                      <div className="clients-agreements-contact">
                      <Tooltip title={data?.name} placement="top">
                        <span className="agreements-contact">{data?.name || 'N/A'}</span>
                      </Tooltip>
                        <div className="agreements-date">
                          <span>Issue Date:</span>
                          <span>
                            {" "}
                            {moment(data?.created_at).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="agreements-project-name">
                      <span>Project:</span>
                      <span> {data?.client?.project?.name}</span>
                    </div>
                    <Button className="agreements-project-project" onClick={() => sendReminder(data?.id)}>
                      {isLoader === data?.id ? 'Loading...' : 'Send Reminder'}
                    </Button>
                    <img src={DownloadIcon} className="downloadPdfIcon" onClick={() => onDownload(data, "agreement_pdf")}/>
                  </div>
                );
              }) : !pendingAgreementLoading && <Empty />}
            </div>
          </div>
        </div>
      
    </div>
    }
    </>
  );
};

export default withRouter(Dashboard);
