import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import InvoiceReport from "./InvoiceReport";
import "./AddInvoice.scss";

const AddInvoice = (props) => {
  const [active, setActive] = useState(false);

  const handleInvoiceReport = () => {
    setActive(true);
  };

  return (
    <div >
      <div className="d-none d-md-none d-lg-block">
        <Header />
      </div>
      <div className="d-block d-md-block d-lg-none">
        <HeaderMobile />
      </div>
      <LeftPanel />
      <div className="add-invoice-wrapper">
        <div className="header-left">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <ArrowLeftOutlined /> Back
          </span>
        </div>
        {active ? (
          <InvoiceReport />
        ) : (
          <>
            <div className="invoice-main-wrapper">
              <div className="invoice-title-wrapper d-flex justify-content-between align-items-center">
                <h5 className="invoice-title">New Invoice</h5>
                <CloseOutlined />
              </div>
              <div className="invoice-card-main">
                <div className="add-invoice-card">
                  <Form className="invoice-form">
                    <div className="input-fields invoice-name-wrapper flex flex-column">
                      <FormGroup className="invoice-form-group">
                        <Label className="invoice-label" for="website">
                          Invoice No
                        </Label>
                        <Input
                          className="invoice-input"
                          type="text"
                          name="invoice_no"
                          id="invoice_no"
                          onChange=""
                          value=""
                        />
                      </FormGroup>
                      <div className="add-invoice-user-main">
                        <div className="upload-invoice">Upload Invoice</div>
                        <div className="add-invoice-user-wrapper flex flex-column justify-content-center align-items-center">
                          <div>
                            <img src={UploadIcon} />
                          </div>
                          <span className="invoice-upload-drag">
                            Drag and drop your PDF file here
                          </span>
                          <span className="invoice-or-text">OR</span>
                          <div className="invoice-upload-buttons">
                            <Button className="upload-btn-choose">
                              Choose File
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-buttons flex">
                      <div className="button-group flex align-items-center justify-content-center">
                        <Button
                          className="button-save"
                          onClick={handleInvoiceReport}
                        >
                          Save
                        </Button>
                        <Button className="button-cancel">Cancel</Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(AddInvoice);
