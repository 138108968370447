import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import moment from "moment";
import EditIcon from "../../../../../assets/images/inventive_byte/edit-icon.svg";
import EmailIcon from "../../../../../assets/images/inventive_byte/email-icon.svg";
import GoogleIcon from "../../../../../assets/images/inventive_byte/google-icon.svg";
import PdfIcon from "../../../../../assets/images/inventive_byte/pdf-icon.png";
import TimeZoneIcon from "../../../../../assets/images/inventive_byte/time-zone.svg";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-icon.png";
import TickIcon from "../../../../../assets/images/inventive_byte/Vector.png";
import CrossIcon from "../../../../../assets/images/inventive_byte/cross.png";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import * as UploadActions from "../../../../../redux/UploadFlowControl/UploadActions";
import "./ProjectDetail.scss";
import { DatePicker, Input, InputNumber, message, Select, Spin, Tooltip, Upload } from "antd";
const { Option } = Select;

const ProjectDetail = (props) => {
  const dispatch = useDispatch();
  const { project_detail, project_types, project_cat, project_phase, project_feature } = useSelector((state) => state.LeadReducers);
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState("");
  const [fileList, setFileList] = useState([])
  const [loader, setLoader] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if(project_detail?.data) {
      let newData = {
        types: project_detail?.data?.project?.types.id || '',
        category: project_detail?.data?.project?.category.id || '',
        phase: project_detail?.data?.project?.phase.id || '',
        stage: project_detail?.data?.project?.stage || '',
        total_budget: project_detail?.data?.project?.total_budget || '',
        monthly_budget: project_detail?.data?.project?.monthly_budget || '',
        starting_option: project_detail?.data?.project?.starting_option || '',
        target_date: moment(project_detail?.data?.project?.target_date) || '',
        features: project_detail?.data?.project?.features?.map(item => item.id) || '',
        document: project_detail?.data?.project?.document || '',
      }
      setData(newData)
      let fileData = project_detail?.data?.project?.document?.map((item,key) => {
        return {uid: key+1, key: item, status: 'done', name: item.split('projects/').slice(-1)[0]}
      }) || []
      setFileList(fileData)
    }
  }, [project_detail]);
  console.log(fileList,"fileList")

  const handleChange = (event, key) => {
    let newData = {...data}
    newData[key] = event
    setData(newData)
  };

  const onUpdate = (key) => {
    setLoader(true)
    dispatch(LeadActions.saveClientProject(
      props.clientID, {[key]: data?.[key]},
      (res) => {
        message.success("Project detail updated successfully");
        dispatch(LeadActions.getProjectDetail(
          props.clientID,
          (res) => {
            setLoader(false)
            setEdit('')
          }
        ));
      },
      (err) => {
        message.error(err || "Failed to update Project.");
      }
    ));
  }

  const onUpdateDocument = (key) => {
    setLoader(true)
    dispatch(LeadActions.saveClientProject(
      props.clientID, {[key]: fileList?.map(item => item.key)},
      (res) => {
        message.success("Project detail updated successfully");
        dispatch(LeadActions.getProjectDetail(
          props.clientID,
          (res) => {
            setLoader(false)
            setEdit('')
          }
        ));
      },
      (err) => {
        message.error(err || "Failed to update Project.");
      }
    ));
  }

  const onBannerSelect = async (files, file) => {
    dispatch(await UploadActions.getPresignedUrl("project_doc", file, (res) => {
      setFileList([...fileList, {uid: new Date().getTime(), key: res, status: 'done', name: res.split('projects/').slice(-1)[0]}])
    }));
  };

  const onRemove = (file) => {
    let fileData = [...fileList]
    fileData = fileData.filter(item => item.uid !== file.uid)
    setFileList(fileData)
  }

  const renderSelect = (array, key, props) => {
    return (
      <div className="projectMainWrapper">
        <Select
          style={props?.style ? props.style : {
            width: 120,
          }}
          onChange={(event) => handleChange(event, key)}
          value={data?.[key]}
          className="projectDetailSelect"
          {...props}
        >
          {
            array?.map((item, key) => {
              return <Option key={key} value={item.id}><Tooltip title={item.name}>{item.name}</Tooltip></Option>
            })  
          }
        </Select>
        
        {loader ? <Spin className="themeSpinner"/>:
        <>
          <img src={TickIcon} onClick={() => onUpdate(key)}/>
          <img src={CrossIcon} onClick={() => setEdit('')}/>
        </>
        }
      </div>
    )
  }

  const renderTextField = (key, type) => {
    return (
      <div className="projectMainWrapper">
        {type === 'text' ?
          <InputNumber onChange={(event) => handleChange(event,key)} type={"number"} value={data?.[key]} min={0}/>:
          type === 'date' ? 
          <DatePicker onChange={(event) => handleChange(event, key)} value={data?.[key]} />:
          <Input.TextArea onChange={(event) => handleChange(event.target.value,key)} value={data?.[key]} rows={3}/>
        }
        {loader ? <Spin className="themeSpinner"/>:
        <>
          <img src={TickIcon} onClick={() => onUpdate(key)}/>
          <img src={CrossIcon} onClick={() => setEdit('')}/>
        </>
        }
      </div>
    )
  }

  return (
    <div className="project-details-wrapper">
      {props?.showEmail &&
      <div className="project-email">
        <div className="w-100 flex align-items-center justify-content-end project-email-edit">
          <img src={EditIcon} onClick={() => history.push(`/inventive/lead/profile/${props.clientID}`)}/>
        </div>
        <div className="project-email-detail flex w-100">
          <div className="flex align-items-center">
            <img src={EmailIcon} />
            <div className="flex flex-column email">
              <span className="email-label">Email Address</span>
              <span className="email-text">{!project_detail?.data?.email ? 'N/A' : project_detail?.data?.email}</span>
            </div>
          </div>
          <div className="flex align-items-center">
            <img src={TimeZoneIcon} />
            <div className="flex flex-column email">
              <span className="email-label">Time Zone</span>
              <span className="email-text">{!project_detail?.data?.time_zone ? 'N/A' : project_detail?.data?.time_zone} </span>
            </div>
          </div>
        </div>
      </div>}

      <div className="project-detail-main">
        <div className="flex align-items-center project-detail">
          <img src={GoogleIcon} />
          <div className="details-project">
            <div className="flex align-items-center project-edit">
              <span className="project-name">{!project_detail?.data?.project?.name ? 'N/A' :  project_detail?.data?.project?.name}</span>
              <img src={EditIcon} onClick={() => history.push(`/inventive/lead/project/${props.clientID}`, {
                name: project_detail?.data?.project?.name,
                description: project_detail?.data?.project?.description
              })} style={{cursor: 'pointer'}}/>
            </div>
            <p className="project-text mb-0">
              {!project_detail?.data?.project?.description ? 'N/A' : project_detail?.data?.project?.description}
            </p>
          </div>
        </div>

        <div className="project-desc">
          <div className="flex justify-content-between flex-column flex-md-row desc-main">
            <div className="flex flex-column description">
              <span className="project-desc-label">
                Project type <img src={EditIcon} onClick={() => setEdit('types')}/>
              </span>
              {edit === 'types' ? 
                renderSelect(project_types, 'types') : 
                <span className="project-desc-text">
                  {!project_detail?.data?.project?.types?.name ? 'N/A' : project_detail?.data?.project?.types?.name}
                </span>
              }
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Project category <img src={EditIcon} onClick={() => setEdit('category')}/>
              </span>
              {edit === 'category' ? 
                renderSelect(project_cat, 'category') : 
                <span className="project-desc-text">
                  {!project_detail?.data?.project?.category?.name ? 'N/A' : project_detail?.data?.project?.category?.name}
                </span>
              }
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Phase <img src={EditIcon} onClick={() => setEdit('phase')}/>
              </span>
              {edit === 'phase' ? 
                renderSelect(project_phase, 'phase') : 
              <span className="project-desc-text">
                {!project_detail?.data?.project?.phase?.name ? 'N/A' : project_detail?.data?.project?.phase?.name}
              </span>}
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Development stage <img src={EditIcon} onClick={() => setEdit('stage')}/>
              </span>
              {edit === 'stage' ? 
                renderTextField('stage') : 
                <span className="project-desc-text">
                {!project_detail?.data?.project?.stage ? 'N/A' : project_detail?.data?.project?.stage}
                </span>
              }
            </div>
          </div>

          <div className="flex justify-content-between flex-column flex-md-row mt-4 mt-md-5 desc-main">
            <div className="flex flex-column description">
              <span className="project-desc-label">
                Total budget <img src={EditIcon} onClick={() => setEdit('total_budget')}/>
              </span>
              {edit === 'total_budget' ? 
                renderTextField('total_budget', "text") : 
                <span className="project-desc-text">
                  ${!project_detail?.data?.project?.total_budget ? 'N/A' : project_detail?.data?.project?.total_budget}
                </span>
              }
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Monthly budget <img src={EditIcon} onClick={() => setEdit('monthly_budget')}/>
              </span>
              {edit === 'monthly_budget' ? 
                renderTextField('monthly_budget', "text") : 
                <span className="project-desc-text">
                  ${!project_detail?.data?.project?.monthly_budget ? 'N/A' : project_detail?.data?.project?.monthly_budget}
                </span>
              }
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Want to start <img src={EditIcon} onClick={() => setEdit('starting_option')}/>
              </span>
              {edit === 'starting_option' ? 
                renderTextField('starting_option') : 
                <span className="project-desc-text">{!project_detail?.data?.project?.starting_option ? 'N/A' : project_detail?.data?.project?.starting_option}</span>
              }
            </div>

            <div className="flex flex-column description">
              <span className="project-desc-label">
                Target date <img src={EditIcon} onClick={() => setEdit('target_date')}/>
              </span>
              {edit === 'target_date' ? 
                renderTextField('target_date', "date") :
              <span className="project-desc-text">
                {!project_detail?.data?.project?.target_date? 'N/A' : moment(project_detail?.data?.project?.target_date).format(
                  "MMMM DD, YYYY"
                )}
              </span>}
            </div>
          </div>
          <div className="flex mt-5 features-documents-wrapper">
            <div className="application">
              <span className="project-desc-label" style={{cursor: 'pointer'}}>
                Application features <img src={EditIcon} onClick={() => setEdit('features')}/>
              </span>
              {edit === 'features' ? 
                renderSelect(project_feature, 'features', {mode: 'multiple', maxTagCount: 'responsive', style: {width: 190}}) : 
              <div className="project-desc-text">
                {project_detail?.data?.project?.features?.length === 0 ? 'N/A' : <span>{project_detail?.data?.project?.features?.map((data) => data.name).join(', ')}</span>
                }
              </div>}
            </div>
            <div className="documents flex flex-column" >
              <span className="project-desc-label" style={{cursor: 'pointer'}}>
                Helpful documents <img src={EditIcon} onClick={() => setEdit('document')}/>
              </span>
              
              {edit === 'document' ? 
              <div className="projectMainWrapper">
                <div className="flex justify-content-center flex-column blog-img-upload uploadBoxProjectCon">
                  <div className="add-blog-user-wrapper flex justify-content-center align-items-center flex-column">
                    <div>
                      <img src={UploadIcon} />
                    </div>
                    <div className="flex flex-column align-items-center blog-upload-box ">
                      <span className="blog-upload-type" style={{textAlign: 'center', marginTop: 10}}>
                      <b>Drag and drop your PDF file here</b>
                      </span>
                      <span className="blog-or-text" style={{marginBottom: 10}}><b>OR</b></span>
                      <Upload
                        // {...propsUpload}
                        accept=".pdf"
                        // className={logoError && "img-ratio-error"}
                        onChange={(info) => {
                          console.log(info,"info")
                          if (info.file.status !== 'removed') {
                            console.log(info.file, info.fileList);
                            onBannerSelect(info, info.file.originFileObj)
                          }
                        }}
                        onRemove={(event) => {
                          console.log(event,"Dsdfsdfsd")
                          onRemove(event)
                        }}
                        fileList={fileList}
                      >
                        <div className="camera-icon-wrap">
                          <div className="blog-upload-buttons">
                            <Button className="upload-btn-choose" onClick="">
                              Choose File
                            </Button>
                          </div>
                        </div>
                      </Upload>
                      <div className="blog-upload-buttons"></div>
                    </div>
                  </div>
                </div>
                {loader ? <Spin className="themeSpinner"/>:
                  <>
                    <img src={TickIcon} onClick={() => onUpdateDocument('document')}/>
                    <img src={CrossIcon} onClick={() => setEdit('')}/>
                  </>
                }
              </div>
              : 
                <div className="flex flex-column flex-md-row" style={{flexWrap: 'wrap'}}>
                {project_detail?.data?.project?.document?.length === 0 ? 'N/A' : project_detail?.data?.project?.document?.map((data) => {
                  return (
                    <div className="flex align-items-center">
                      <img src={PdfIcon} />
                      <span className="documents-text">{data}</span>
                    </div>
                  );
                })}
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProjectDetail);
