import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import {
  DatePicker,
  message,
  Upload,
  Modal,
  Spin,
  Col,
  Row,
  Empty,
  Popover,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { UploadOutlined, CloseOutlined, CopyOutlined, SaveOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import ProfileUpload from "../../../../src/assets/images/inventive_byte/profile-upload.png";
import UploadIcon from "../../../../src/assets/images/inventive_byte/upload-icon.png";
import * as BlogActions from "../../../redux/BlogFlowControl/BlogActions";
import * as UploadActions from "../../../redux/UploadFlowControl/UploadActions";
import api from "../../../utils/api";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import "./AddNewBlog.scss";
import Loader from "../../components/Loader/Loader";
import AntdImgCrop from "antd-img-crop";
import Img from "../../../../src/assets/images/inventive_byte/download.jpeg";
import UploadImgIcon from "../../../../src/assets/images/Icons/UploadImgIcon"

const AddNewBlog = (props) => {
  console.log(props,"propsprops")
  const dispatch = useDispatch();
  const { blogDetail, loading, initBlogLoading } = useSelector((state) => state.BlogReducers);
  const { presigned_url } = useSelector((state) => state.UploadReducers);
  console.log(presigned_url,"presigned_url")
  const editorRef = useRef(null);
  const CATEGORY = [
    "Fashion",
    "Food",
    "Technology",
    "Travel",
    "News",
    "Multimedia",
  ];
  const [state, setState] = useState({
    draft: false,
    title: "",
    sub_title: "",
    category: "Fashion",
    author_first_name: "",
    author_last_name: "",
    published_type: "NOW",
    summary: "",
    content: "",
    banner_image: "",
    publish_date: "",
    featured: false
  });

  const [radioActive, setRadioActive] = useState("");
  const [content, setContent] = useState();
  const [logoError, setLogoError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [bannerFileList, setBannerFileList] = useState([])

  console.log(loading,"loading")
  useEffect(() => {
    if(props?.location?.state?.id) {
      dispatch(BlogActions.getBlog(props?.location?.state?.id));
    }
   
  }, [props?.location?.state?.id]);

  useEffect(() => {
    return () => {
      dispatch(BlogActions.setBlogDetail());
    };
  },[])

  useEffect(() => {
    if(blogDetail?.id) {
      console.log(blogDetail,"blogDetail")
      setState({
        draft: false,
        title: blogDetail?.title,
        sub_title: blogDetail?.sub_title,
        category: blogDetail?.category,
        author_first_name: blogDetail?.author_first_name,
        author_last_name: blogDetail?.author_last_name,
        published_type: blogDetail?.published_type,
        summary: blogDetail?.summary,
        content: blogDetail?.content,
        banner_image: blogDetail?.banner_image,
        profile_image: blogDetail?.profile_image,
        publish_date: blogDetail?.publish_date,
        featured: blogDetail?.featured
      })
      
      if(blogDetail?.banner_image) {
        setBannerFileList([{key: blogDetail?.banner_image, status: 'done', uid: new Date().getTime(), name: blogDetail?.banner_image.split('/').slice(-1)[0]}])
      }
    }
  },[blogDetail])

  const handleChangeEvent = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleActive = (active) => {
    setRadioActive(active);
    setState({ ...state, published_type: active });
  };

  const onDateChange = (date, dateString) => {
    setState({ ...state, publish_date: date });
  };

  const onFeatureChange = (event) => {
    setState({ ...state, featured: event });
  };

  const onBannerSelect = async (files, file) => {
    setLogoError(false);
    if (files.file.status !== "removed") {
      dispatch(await UploadActions.getPresignedUrl("blog_banner", file, (res) => {
        setBannerFileList([{key: res, status: 'done', uid: new Date().getTime(), name: res.split('/').slice(-1)[0]}])
      }));
    }
    
  };

  const onProfileSelect = async ({ file }) => {
      api
      .get(
        `${process.env.REACT_APP_API_URL}/admin/getPresignedUrl?key_type=blog_profile&file_name=${file.name}`
      )
      .then((data) => {
        console.log(data,"datadatadata")
        // updateProfile({ profile_image: data?.data?.fields?.key });
        uploadFile(file, data?.data?.fields, data?.data?.url);
      })
      .catch((err) => console.log(err));
  };

  // const updateProfile = (bodyFormData) => {
  //   api
  //     .put(
  //       `${process.env.REACT_APP_API_URL}/admin/client`,
  //       bodyFormData
  //     )
  //     .then((res) => {
  //       setLoader(false);
  //       if (res.result) {
  //         message.info(res.data);
  //         setLoader(false);
  //       } else {
  //         message.error(res.error);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const uploadFile = (file, fields, uploadURL) => {
    console.log(file,fields, uploadURL, "file[0]");
    const formData = new FormData();
    formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
    formData.append("key", fields?.key);
    formData.append("policy", fields?.policy);
    formData.append("signature", fields?.signature);
    formData.append("file", file);

    // Upload file to s3 bucket
    let request = new XMLHttpRequest();
    request.open("POST", uploadURL);
    request.send(formData)
    setTimeout(() => {
      setState({...state, profile_image: fields?.key})
    }, 1000);
    
  };

  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("attachment", mediaFile);
    api
      .postwithReqFormToken(
        `${process.env.REACT_APP_API_URL}/admin/uploadBlogContent`,
        data
      )
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setUploadMediaLoading(false);
        message.error("Media upload failed..");
      });
  };

  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => {
        setUploadMediaModalOpen(false);
        setMediaFile();
        setMediaName();
      }}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {medias?.map((item) => (
        <Row gutter={8}>
          <Col span={6}>
            <span className="media-name">{item?.name}</span>
          </Col>
          <Col span={16}>
            <span className="media-url">{item?.url?.slice(0, 50) + "..."}</span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={item?.url}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
        </Row>
      ))}
      {medias?.length === 0 && <Empty description={"No Saved Media"} />}
    </div>
  );

  const onDraftClick = async () => {
    const data = {
      draft: true,
      title: state?.title,
      sub_title: state?.sub_title,
      category: state?.category,
      author_first_name: state?.author_first_name,
      author_last_name: state?.author_last_name,
      published_type: state?.published_type,
      summary: state?.summary,
      content: state?.content,
      banner_image: bannerFileList?.length ? bannerFileList[0].key : '',
      profile_image: state?.profile_image || '',
      publish_date: state?.publish_date || moment().format(),
      featured: state?.featured
    };
    setDraftLoader(true)
    if(props?.location?.state?.id) {
      dispatch(BlogActions.editBlog(
        data, props?.location?.state?.id,
        () => {
          message.success("Blog updated successfully");
          setDraftLoader(false)
          props.history.push("/inventive/blogs");
          
        },
        (err) => {
          message.error(err || "Failed to update blog.");
          setDraftLoader(false)
        }
      ));
    }else {
      dispatch(BlogActions.postBlog(
        data,
        () => {
          setDraftLoader(false)
          message.success("Blog created successfully");
          props.history.push("/inventive/blogs");
        },
        (err) => {
          setDraftLoader(false)
          message.error(err || "Failed to add blog.");
        }
      ));
    }
  };

  const onPublishClick = async () => {
    const data = {
      draft: false,
      title: state?.title,
      sub_title: state?.sub_title,
      category: state?.category,
      author_first_name: state?.author_first_name,
      author_last_name: state?.author_last_name,
      published_type: state?.published_type,
      summary: state?.summary,
      content: state?.content,
      banner_image: bannerFileList?.length ? bannerFileList[0].key : '',
      profile_image: state?.profile_image || '',
      publish_date: state?.publish_date || moment().format(),
      featured: state?.featured
    };
    setLoader(true)
    if(props?.location?.state?.id) {
      dispatch(BlogActions.editBlog(
        data, props?.location?.state?.id,
        () => {
          setLoader(false)
          message.success("Blog updated successfully");
          props.history.push("/inventive/blogs");
        },
        (err) => {
          setLoader(false)
          console.log(err,"err")
          message.error(err || "Failed to update blog.");
        }
      ));
    }else {
      dispatch(BlogActions.postBlog(
        data,
        () => {
          setLoader(false)
          message.success("Blog created successfully");
          props.history.push("/inventive/blogs");
        },
        (err) => {
          setLoader(false)
          message.error(err || "Failed to add blog.");
        }
      ));
    }
  };

  const onRemoveBanner = (file) => {
    let fileData = [...bannerFileList]
    fileData = fileData.filter(item => item.uid !== file.uid)
    setBannerFileList(fileData)
  }

  return (
    <>
    {initBlogLoading ? <Loader /> :
    
    <div className="add-blog-wrapper">
      <div className="blog-title-wrapper">
        <h5 className="blog-title">{blogDetail?.id ? "Edit Blog" : "New Blog"}</h5>
      </div>
      <div className="blog-card-main">
        <div className="add-blog-card">
          <div className="add-blog-img-wrapper flex justify-content-around align-items-center">
            <div className="imageBoxCon">
              {loader ? (
                  <Spin className="profilePicLoader" />
                ) : !state?.profile_image ? (
                  <img
                    className="blog-img user-icon"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={Img}
                  />
                ) : (
                  <img
                    className="blog-img user-icon"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={process.env.REACT_APP_IMAGE_URL + state?.profile_image}
                  />
              )}
                
              <AntdImgCrop rotate>
                <Upload
                  id="myFileInput"
                  style={{ display: "none" }}
                  showUploadList={false}
                  customRequest={(e) => onProfileSelect(e)}
                  accept=".png, .jpeg, .jpg"
                  // onRemove={(file) => onRemove(file)}
                >
                    <UploadImgIcon className="cameraIcon" />
                </Upload>
              </AntdImgCrop>
            </div>

            <div className="flex justify-content-center flex-column blog-img-upload">
              <span className="add-banner-text">Add Banner</span>
              <div className="add-blog-user-wrapper flex justify-content-center align-items-center">
                <div>
                  <img src={UploadIcon} />
                </div>
                <div className="flex flex-column align-items-center align-items-md-start blog-upload-box">
                  <span className="blog-upload-type">
                    Upload JPG or PNG only
                  </span>
                  <span className="blog-upload-drag">
                    Drag and drop your Image file here
                  </span>
                  <span className="blog-or-text">OR</span>
                  <Upload
                    onChange={(file) =>
                      onBannerSelect(file, file.file.originFileObj)
                    }
                    accept="image/png,image/jpeg"
                    className={logoError && "img-ratio-error"}
                    fileList={bannerFileList}
                    maxCount={1}
                    onRemove={(file) => onRemoveBanner(file)}
                  >
                    <div className="camera-icon-wrap">
                      <div className="blog-upload-buttons">
                        <Button className="upload-btn-choose" onClick="">
                          Choose File
                        </Button>
                      </div>
                    </div>
                  </Upload>
                  <div className="blog-upload-buttons"></div>
                </div>
              </div>
            </div>
          </div>
          <Form className="blog-form">
            <div className="input-fields blog-name-wrapper flex">
              <FormGroup className="blog-fname blog-form-group">
                <Label className="blog-label" for="website">
                  Author First Name
                </Label>
                <Input
                  className="blog-input"
                  type="text"
                  name="author_first_name"
                  id="author_first_name"
                  onChange={handleChangeEvent}
                  value={state?.author_first_name}
                />
              </FormGroup>
              <FormGroup className="blog-lname blog-form-group">
                <Label className="blog-label" for="website">
                  Author Last Name
                </Label>
                <Input
                  className="blog-input"
                  type="text"
                  name="author_last_name"
                  id="author_last_name"
                  onChange={handleChangeEvent}
                  value={state?.author_last_name}
                />
              </FormGroup>
            </div>
            <div className="input-fields">
              <FormGroup className="blog-form-group blog-title-form flex flex-column">
                <Label className="blog-label" for="website">
                  Blog Title
                </Label>
                <Input
                  className="blog-input"
                  type="text"
                  name="title"
                  id="title"
                  onChange={handleChangeEvent}
                  value={state?.title}
                />
              </FormGroup>
              <div className="flex blog-title-category">
                <FormGroup className="blog-form-group flex flex-column blog-sub-title">
                  <Label className="blog-label" for="website">
                    Sub-Title
                  </Label>
                  <Input
                    className="blog-input"
                    type="text"
                    name="sub_title"
                    id="sub_title"
                    onChange={handleChangeEvent}
                    value={state?.sub_title}
                  />
                </FormGroup>
                <FormGroup className="blog-form-group flex flex-column blog-category">
                  <Label className="blog-label" for="website">
                    Category
                  </Label>
                  <select
                    className="blog-select"
                    name="category"
                    onChange={handleChangeEvent}
                  >
                    {CATEGORY?.map((x) => {
                      return <option>{x}</option>;
                    })}
                  </select>
                </FormGroup>
              </div>
              <div className="flex blog-type-date">
                <FormGroup className="publish-type blog-form-group">
                  <Label className="round-info-lable blog-label" for="website">
                    Publish Type
                  </Label>
                  <div className="btn-toggle-wrapper">
                    <div
                      className="btn-group btn-toggle custom-toggle-button custom-switch"
                      data-toggle="buttons"
                    >
                      <label
                        className={`btn btn-default ${
                          (state?.published_type === "NOW" && "active") ||
                          (radioActive === "NOW" && "active")
                        }`}
                        onClick={(title) => handleActive("NOW")}
                      >
                        Publish Now
                      </label>
                      <label
                        className={`btn btn-default ${
                          (state?.published_type === "SCHEDULED" && "active") ||
                          (radioActive === "SCHEDULED" && "active")
                        }`}
                        onClick={(title) => handleActive("SCHEDULED")}
                      >
                        Schedule Publish
                      </label>
                    </div>
                  </div>
                </FormGroup>
                {state?.published_type === "SCHEDULED" && (
                  <FormGroup className="blog-form-group publishing-date flex flex-column">
                    <Label className="blog-label">Choose Publishing Date</Label>
                    <DatePicker
                      className="qa-btn-notans blog-date"
                      format="DD-MM-YYYY"
                      onChange={onDateChange}
                      name="investor_date"
                      defaultValue={moment(new Date())}
                      value={state?.publish_date && moment(state?.publish_date)}
                      disabledDate={current => current <= moment()}
                    />
                  </FormGroup>
                )}
              </div>
            </div>

            <div className="flex blog-type-date">
              <FormGroup className="publish-type blog-form-group flex align-items-center">
                <Label className="round-info-lable blog-label" for="website" style={{marginRight: 10, marginBottom: 0}}>
                  Featured
                </Label>
                <div className="btn-toggle-wrapper">
                  <Switch  name="featured" onChange={onFeatureChange}/>
                </div>
              </FormGroup>
            </div>

            <div className="input-fields blog-summary-content">
              <FormGroup className="blog-form-group blog-summary mb-4">
                <Label className="blog-label" for="website">
                  Short Summary
                </Label>
                <Input
                  className="blog-textarea"
                  type="textarea"
                  name="summary"
                  id="summary"
                  onChange={handleChangeEvent}
                  value={state?.summary}
                />
              </FormGroup>
              <FormGroup className="blog-form-group blog-content">
                <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-start align-items-md-center mb-3">
                  <Label className="media-label" for="website">Content</Label>
                  {/* <div className="d-flex align-items-center media-group">
                    <Button className="d-flex align-items-center save-media" icon={<img src="" alt="saved" />} onClick={savedMediaContent}>
                      <SaveOutlined className="save-icon" />
                      Saved Media
                    </Button>
                    <Button className="d-flex align-items-center upload-media" onClick={() => setUploadMediaModalOpen(true)} icon={<img src="" alt="upload" />}>
                    <UploadOutlined className="upload-icon" />
                      Upload Media
                    </Button>
                  </div> */}
                </div>
                <div className="blog-editor">
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(a, b) => {
                      setState({ ...state, content: a });
                      // setContent(b.getContent({ format: "raw" }));
                    }}
                    init={{
                      height: 500,
                      plugins:
                        "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc",
                      menubar: "file edit view insert format tools table help",
                      toolbar:
                        "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                      toolbar_mode: "wrap",
                      block_formats:
                        "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      setup: function (editor) {
                        editor.addShortcut(
                          "alt+shift+1",
                          "H1 is Permanently Disabled",
                          function () {
                            editor.execCommand();
                          }
                        );
                      },
                    }}
                    value={state?.content || ""}
                    name="content"
                  />
                </div>
              </FormGroup>
            </div>
          </Form>
        </div>
        <div className="add-blog-buttons flex">
          <div className="button-group flex align-items-center justify-content-between justify-content-md-between">
            <Button className="button-cancel" onClick={() => props.history.goBack()}>Cancel</Button>
            <Button
              className="button-save"
              value="draft"
              onClick={onDraftClick}
              disabled={(state?.title?.trim() && state?.sub_title?.trim() && state?.author_first_name?.trim() && state?.author_last_name?.trim()) && !draftLoader ? false : true}
            >
              Save Draft
            </Button>
          </div>
          <Button
            className="button-publish"
            value="publish"
            onClick={onPublishClick}
            disabled={(state?.title?.trim() && state?.sub_title?.trim() && state?.author_first_name?.trim() && state?.author_last_name?.trim()) && !loader ? false : true}
          >
            Publish
          </Button>
        </div>
        {uploadMediaModal}
      </div>
    </div>}
    </>
  );
};

export default withRouter(AddNewBlog);
