import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import PerkIcon from "../../../../src/assets/images/inventive_byte/perk-icon.svg";
import AddPerkIcon from "../../../../src/assets/images/inventive_byte/add-blog.png";
import List1 from "../../../../src/assets/images/inventive_byte/amazon-icon.png";
import List2 from "../../../../src/assets/images/inventive_byte/logo-algolia.png";
import BlogEdit from "../../../../src/assets/images/inventive_byte/blog-edit.png";
import BlogDelete from "../../../../src/assets/images/inventive_byte/blog-delete.png";
import DropDownIcon from "../../../../src/assets/images/inventive_byte/drop-down-icon.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import AddPerk from "./AddPerk";
import "./PerkList.scss";
import ViewPerkDetail from "./ViewPerkDetail";
import * as PerkActions from "../../../redux/PerkFlowControl/PerkActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import api from '../../../utils/api'
import { message } from "antd";
import NoDataFound from "../../../Components/NoDataFound/NoDataFound";

const PerkList = (props) => {
  const dispatch = useDispatch()
  const { loading, perkList } = useSelector(state => state.PerkReducers)
  const handleAddPerk = () => {
    props.history.push('/inventive/add-perk')
  };
  const handleDetail = (id) => {
    props.history.push(`/inventive/view-perk/${id}`, {id})
  };

  useEffect(() => {
    dispatch(PerkActions.getPerkList())
  },[])

  const onRemove = (id) => {
    api.delete(`${process.env.REACT_APP_API_URL}/admin/perks/${id}`)
      .then((data) => {
        console.log(data,"data")
        if(data.result) {
          let array = perkList.filter(itm => itm.id !== id)
          dispatch(PerkActions.updatePerkList(array))
          message.success(data.data)
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
    {loading ? <Loader /> :
    <>
        <div className="perk-list-main">
          <div className="perk-list-wrapper">
            <div className="perk-list-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
              <div className="perk-list-icon-wrapper flex align-items-center justify-content-start">
                <img className="perk-list-icon" src={PerkIcon} />{" "}
                <span className="perk-list-icon-text">Perks</span>
              </div>
              <Button className="perk-list-button" onClick={handleAddPerk}>
                <img className="perk-list-button-img" src={AddPerkIcon} />
                Add Perk
              </Button>
            </div>

            <div className="perk-list-desktop-wrap">
              <div className="main-table-wrapper">
                <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                  <div className="th-data">
                    Logo{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Name{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Value Level{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Website{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data justify-content-center">
                    Status{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data justify-content-center">
                    Action{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                </div>
              </div>
              { perkList?.length ? 
                perkList?.map((item,key) => {
                  return (
                    <div className="table-body flex justify-content-start align-items-center" key={key}>
                      <div className="table-data order-0 order-lg-0">
                        <img src={`https://d3u1baow9deq5h.cloudfront.net/${item?.image}`} />
                      </div>
                      <div className="table-data order-1 order-lg-1">
                        <div className="username" onClick={() => handleDetail(item.id)}>
                          {item?.name}
                        </div>
                      </div>
                      <div className="table-data order-2 order-lg-2">
                        <div className="flex perk-list-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Value Level
                          </label>
                          <div className="value">{item?.perk_type}</div>
                        </div>
                      </div>
                      <div className="table-data order-3 order-lg-3">
                        <div className="flex perk-list-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Website
                          </label>
                          <div className="website">{item?.website}</div>
                        </div>
                      </div>
                      <div className="table-data order-5 order-lg-5 flex justify-content-center">
                        <div className="flex perk-list-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Status
                          </label>
                          <Button className={item?.active ? "perk-list-button" : "perk-list-button-draft"}>{item?.active ? 'Active' : 'Draft'}</Button>
                        </div>
                      </div>
                      <div className="table-data perk-list-action order-6 order-lg-6 flex justify-content-center">
                        <img className="action-img" src={BlogEdit} onClick={() => props.history.push('/inventive/add-perk', {data: item} )}/>
                        <img className="action-img" src={BlogDelete} onClick={() => onRemove(item.id)}/>
                      </div>
                    </div>
                  )
                }) : <NoDataFound />
              }
            </div>
          </div>
        </div>
      </>}
    </>
  );
};

export default withRouter(PerkList);
