import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import LeadProfileIcon from "../../../../../assets/images/inventive_byte/lead-profile-icon.png";
import EditIcon from "../../../../../assets/images/inventive_byte/edit-icon.svg";
import { Rate } from "antd";
import ViewFeedBack from "./ViewFeedBack";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./FeedBackReply.scss";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";

const FeedBackReply = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { single_feedback, loading, single_client } = useSelector(
    (state) => state.ClientReducers
  );
  console.log(single_feedback,"single_feedback")
  useEffect(() => {
    dispatch(ClientActions.getClientDetail(params?.clientID));
    dispatch(ClientActions.getSingleFeedback(params?.feedbackID));
  }, []);

  useEffect(() => {}, [single_feedback, single_client]);
  const [active, setActive] = useState(false);
  const [state, setState] = useState({
    reply: "",
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const submitFeedback = async () => {
    await dispatch(ClientActions.editFeedback(params?.feedbackID, state))
    await dispatch(ClientActions.getSingleFeedback(params?.feedbackID));
    setActive(true);
  }

  return (
    <>
      {loading ? <Loader /> : <>
      <div className="new-feedback-wrapper">
        <div className="header-left d-flex align-items-center justify-content-between">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <ArrowLeftOutlined /> Back
          </span>
        </div>
        {active ? (
          <ViewFeedBack feedbackID = {params?.feedbackID} clientID={params?.clientID}/>
        ) : (
          <div className="feedback-main-wrapper">
            <div className="feedback-card-main">
              <div className="feedback-title-wrapper">
                <div className="d-flex flex-column flex-md-row align-items-center feedback-profile">
                  <img
                    className="img-fluid feedback-profile-img"
                    src={LeadProfileIcon}
                    alt=""
                  />
                  <div className="d-flex flex-column align-items-center align-items-md-start profile-details">
                    <span className="user-name">
                      {single_feedback?.data?.client?.first_name}{" "}
                      {single_feedback?.data?.client?.last_name}
                    </span>
                    <span className="project-name">
                      Project Name :{" "}
                      <span className="project">
                        {single_client?.data?.project?.name}
                      </span>
                    </span>
                    <span className="date">
                      {moment(single_feedback?.data?.created_at).format(
                        "MM-DD-YYYY"
                      )}
                    </span>
                  </div>
                  <Rate
                    className="feedback-rate"
                    allowHalf
                    defaultValue={single_feedback?.data?.rating}
                    disabled
                  />
                </div>
                <p className="feedback-profile-text">
                  {single_feedback?.data?.feedback}
                </p>
              </div>
              <div className="add-feedback-card">
                <Form className="feedback-form">
                  <div className="input-fields feedback-name-wrapper flex flex-column">
                    <FormGroup className="feedback-form-group">
                      <Label className="feedback-label" for="website">
                        Reply to feedback
                      </Label>
                      <Input
                        className="feedback-textarea"
                        type="textarea"
                        name="reply"
                        id="reply"
                        onChange={handleChange}
                        value={state?.reply}
                      />
                    </FormGroup>
                  </div>
                  <div className="feedback-buttons flex">
                    <div className="button-group flex align-items-center justify-content-center">
                      <Button className="button-save" onClick={submitFeedback} disabled={!state?.reply?.trim()}>
                        Save
                      </Button>
                      <Button className="button-cancel" onClick={() => props.history.goBack()}>Cancel</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
      </>}
    </>
  );
};

export default withRouter(FeedBackReply);
