import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { message, Input } from 'antd'
import { Modal } from 'react-bootstrap'
import InventiveLogo from '../../../src/assets/images/inventive_byte/inventive-logo.png'
import * as AdminLoginActions from '../../redux/AdminLoginFlowControl/AdminLoginActions'
import './AdminLogin.scss'
import { IsValidForm } from '../../utils/validations'

const ForgotPassword = props => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    email: '',
  })

  const handleEventChange = (e) => {
    let newErrors = { ...errors };
    if(e.target.name === "email") {
      const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (validEmail.test(e.target.value)) {
        newErrors[e.target.name] = "";
      }else {
        newErrors[e.target.name] = "Please enter valid email"
      }
    }else {
      newErrors[e.target.name] = "";
    }
    setErrors(newErrors)
    setState({...state,[e.target.name]: e.target.value})
  }

  const handleLoginSubmit = async () => {
    const data = {
      ...state
      }
      let fields = ["email"];
      let formValidation = IsValidForm(fields, data);
      setErrors(formValidation.errors);
      if (formValidation?.validate) {
        dispatch(AdminLoginActions.adminForgotPassword(
          data,
          () => {
            message.success("Email sent successfully");
            props.history.push("/")
          },
          (err) => {
            console.log(err,"errrrr")
            message.error(err || "Failed to Forgot Password.");
          }
        ));
      }
  }

  return (
    <div>
        <div className="admin-login-main">
          <div className="admin-login-img">
            <img src={InventiveLogo} />
          </div>

          <Modal.Dialog className="admin-login-modal">
            <Modal.Header className="admin-login-head-main">
              <h3 className="admin-login-head">Forgot Password</h3>
            </Modal.Header>

            <Modal.Body className="admin-login-modal-body">
              <Form className="admin-login-form" autoComplete='off'>
                <FormGroup>
                  <Label className="admin-login-lable" for="email">Email Address</Label>
                  <Input className="admin-login-input" type="text" name="email" id="email" onChange={handleEventChange} value={state?.email}  autoComplete='off'/>
                  {errors?.email ? (
                    <p className="validation-eror">{errors?.email}</p>
                  ) : null}
                </FormGroup>
              </Form>
            </Modal.Body>

            <Modal.Footer className="admin-login-footer">
            <p className="admin-login-forgot" onClick={() => props.history.goBack()} style={{cursor: 'pointer'}}>Sign In</p>
              <Button className="button-signin" variant="primary" onClick={handleLoginSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
    </div>
  )
}

export default withRouter(ForgotPassword)
