import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  blog_list: [],
  msg: '',
  blogDetail: null,
  initBlogLoading: false
};

const BlogReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BLOGS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        blog_list: action.payload,
      };
    case actionTypes.GET_BLOGS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_BLOGS_SUCCESS:
      let blogListData = {...state.blog_list}
      let index = blogListData.data.findIndex(itm => itm.id === action.id)
      blogListData.data[index] = action.payload
      return {
        ...state,
        loading: false,
        blog_list: blogListData,
      };
    case actionTypes.DELETE_BLOGS_SUCCESS:
        let blogListData1 = {...state.blog_list}
        let blogdata = blogListData1.data.filter(itm => itm.id !== action.id)
        blogListData1.data = blogdata
        return {
          ...state,
          loading: false,
          blog_list: blogListData1,
        };

      case actionTypes.GET_BLOG_INIT:
      return {
        ...state,
        loading: true,
        initBlogLoading: true
      };
    case actionTypes.GET_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        blogDetail: action.payload,
        initBlogLoading: false
      };
    case actionTypes.GET_BLOG_FAILED:
      return {
        ...state,
        loading: false,
        initBlogLoading: false
      };
    case actionTypes.SET_BLOG_DETAIL:
      return {
        ...state,
        loading: false,
        blogDetail: null,
      };
    default:
      return { ...state };
  }
};

export default BlogReducers;
