import { combineReducers } from 'redux'
import AdminLoginReducers from './AdminLoginFlowControl/AdminLoginReducers'
import DashboardReducers from './DashboardFlowControl/DashboardReducers'
import BlogReducers from './BlogFlowControl/BlogReducers'
import UploadReducers from './UploadFlowControl/UploadReducers'
import TeamReducers from './TeamFlowControl/TeamReducers'
import LeadReducers from './LeadFlowControl/LeadReducers'
import ClientReducers from './ClientFlowControl/ClientReducers'
import PerkReducers from './PerkFlowControl/PerkReducers'

export default combineReducers({
    AdminLoginReducers: AdminLoginReducers,
    DashboardReducers: DashboardReducers,
    BlogReducers: BlogReducers,
    UploadReducers: UploadReducers,
    TeamReducers: TeamReducers,
    LeadReducers: LeadReducers,
    ClientReducers: ClientReducers,
    PerkReducers: PerkReducers
})
