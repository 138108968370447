import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Pagination, Spin, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ReviewIcon from "../../../../../assets/images/inventive_byte/review-icon.svg";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import CrossIcon from "../../../../../assets/images/inventive_byte/review-cross-icon.svg";
import CompleteIcon from "../../../../../assets/images/inventive_byte/lead-active.png";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./ReviewList.scss";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const ReviewList = (props) => {
  const dispatch = useDispatch();
  const { review_list, loading } = useSelector((state) => state.ClientReducers);

  useEffect(() => {
    dispatch(ClientActions.getReviews(props.clientID));
  }, []);

  useEffect(() => {}, [review_list]);

  const postReview = async (id, value) => {
    if (value === "approved") {
      await dispatch(ClientActions.postReview(props?.clientID, id, value, (res) => {
        if(res?.error) {
          message.error(res.error)
        }
      }));
      await dispatch(ClientActions.getReviews(props.clientID));
    } else {
      await dispatch(ClientActions.postReview(props?.clientID, id, value, (res) => {
        if(res?.error) {
          message.error(res.error)
        }
      }));
      await dispatch(ClientActions.getReviews(props.clientID));
    }
  };

  return (
    <div className="client-review-main">
      <div className="client-review-desktop-wrap">
        <Spin className="client-review-spin" spinning={loading}>
          <div className="main-client-wrapper">
            <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
              <div className="th-data">
                Review Platform{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>
              </div>
              <div className="th-data">
                Credit Value{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>
              </div>
              <div className="th-data">
                Review Request{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>{" "}
              </div>
              <div className="th-data d-flex justify-content-end">
                Action <span className="img-icon"></span>{" "}
              </div>
            </div>
          </div>
          {review_list?.data?.length ? review_list?.data?.map((data) => {
            return (
              <div className="table-body flex justify-content-start align-items-center">
                <div className="table-data">
                  <div className="username" onClick="">
                    <img className="review-img" src={ReviewIcon} />
                    {data?.platform}
                  </div>
                </div>
                <div className="table-data">
                  <div className="flex review-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Credit Value
                    </label>
                    <div className="credit flex align-items-center">
                      ${data?.value}
                    </div>
                  </div>
                </div>

                <div className="table-data">
                  <div className="flex review-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Review Request
                    </label>
                    <div className="flex align-items-center">
                      {data?.reviewed === true ? (
                        <span className="review-yes">Yes</span>
                      ) : (
                        <span className="review-no">No</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="table-data d-flex justify-content-center justify-content-lg-end">
                  <div className="d-flex justify-content-center justify-content-lg-end review-card">
                    <div className="action-buttons">
                      {data?.status === "approved" ? (
                        <div className="d-flex align-items-center btn-approved">
                          <img
                            className="btn-approved-icon"
                            src={CompleteIcon}
                            alt=""
                          />
                          <span className="btn-approved-text">Approved</span>
                        </div>
                      ) : data?.status === "rejected" ? (
                        <div className="d-flex align-items-center btn-rejected">
                          <img className="btn-rejected-icon" src={CrossIcon} alt=""/>
                          <span className="btn-rejected-text">Rejected</span>
                        </div>
                      ) : (
                        <>
                          <Button
                            className="button-approve"
                            onClick={() => {
                              postReview(data?.id, "approved");
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            className="button-reject"
                            onClick={() => {
                              postReview(data?.id, "rejected");
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }):
            <NoDataFound />
          }
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(ReviewList);
