export function formatCurrency(number) {
    return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }
  
  export function getNumberFromCurrency(currencyAmount) {
    // If already a valid number, return immediately.
    if (Number.isInteger(currencyAmount)) {
      return currencyAmount
    }
  
    if (currencyAmount === null) {
      return null
    }
  
    if (!(typeof currencyAmount === 'string')) {
      throw new Error('Type of currency amount should be a string or integer.')
    }
  
    const numberStringFromCurrencyAmount = currencyAmount.replace(/,/g, '').replace(/\$/g, '')
    if (isValidIntegerString(numberStringFromCurrencyAmount)) {
      return parseInt(numberStringFromCurrencyAmount)
    }
  
    return null
  }
  
  export function formatFileToName(string) {
    const sections = string.split('-')
    return sections[sections.length - 1]
  }
  
  function isValidIntegerString(obj) {
    if (isNaN(obj)) {
      return false
    }
  
    const floorNumber = Math.floor(Number(obj))
    return floorNumber !== Infinity && String(obj) === obj
  }
  
  export function setTokenInLocalStorage(token) {
    if (token === null || token === undefined) {
      localStorage.removeItem('token')
      return
    }
  
    localStorage.setItem('token', token)
    return
  }
  
  export function getTokenInLocaleStorage() {
    return localStorage.getItem('token')
  }
  
  function getIdentityFromToken(token) {
    if (token === null || token === undefined) {
      throw new Error('Cannot get identity from token if token is null or undefined.')
    }
  
    const base64Payload = token.split('.')[1]
  
    const payloadJson = JSON.parse(window.atob(base64Payload))
  
    return payloadJson.identity
  }
  
  export function getEmailFromToken(token) {
    return getIdentityFromToken(token).email
  }
  
  export function getUserTypeFromToken(token) {
    return getIdentityFromToken(token).model
  }
  
  export function requireNonEmpty(obj, message) {
    if (obj === null || obj === undefined || obj === '') {
      throw new Error(message)
    }
  
    return obj
  }

  export function getFileNameFromUrl(url) {
    const name = url?.split('-')?.[url?.split('-')?.length - 1]
    return name
  }  