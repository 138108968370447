import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Tabs, DatePicker, Slider, Pagination } from "antd";
import Button from "react-bootstrap/Button";
import SearchIcon from "../../../../src/assets/images/inventive_byte/search.png";
import TeamDashboard from "./TeamDashboard";
import InviteTeamMemberList from "./InviteTeamMemberList";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import * as TeamActions from "../../../redux/TeamFlowControl/TeamActions";
import "./TeamTabs.scss";
import { useDispatch, useSelector } from "react-redux";

const TeamTabs = (props) => {
  const dispatch = useDispatch()
  const { department_list,project_list,team_member_list } = useSelector(state => state.TeamReducers)
  const { TabPane } = Tabs;
  const [active, setActive] = useState("Team Members");
  const [selectedParams, setSelectedParams] = useState([])

  useEffect(()=>{
    dispatch(TeamActions.getDepartmentList())
    dispatch(TeamActions.getProjectList())
    dispatch(TeamActions.getTeamMemberList())
    dispatch(TeamActions.getPermissionList());
  },[])

  useEffect(()=>{},[department_list,project_list,team_member_list])

  const handleFilter = async (e,label) => {
    let newParams = {label: label, data: e.target.value}
    let params = [...selectedParams,newParams]
    setSelectedParams(params)
    dispatch(await TeamActions.getTeamMemberFilterList(params))
  } 

  const operations =
    active === "Team Members" ? (
      <div className="team-tabs-section d-none d-md-none d-lg-flex">
        <div className="team-tabs-button">
          <input
            type="number"
            className="assigned-project"
            placeholder="No. Assigned Projects"
            onChange={(e)=>{handleFilter(e,'project_count')}}
          />
          <div className="project-wrap">
            <span className="projects-name">Department</span>
            <select className="satus" onChange={(e)=>{handleFilter(e,'department')}}>
              <option value={""}></option> 
              {department_list?.map((data)=>{
                return (
                  <option value={data?.id}>{data?.name}</option>
                )
              })}
            </select>
          </div>
          <div className="project-wrap">
            <span className="projects-name">Assigned Projects</span>
            <select className="satus" onChange={(e)=>{handleFilter(e,'project')}}>
              <option value={""}></option> 
              {project_list?.map((data)=>{
                return (
                  <option value={data?.id}>{data?.name}</option>    
                )
              })}
            </select>
          </div>
          <div className="project-wrap">
            <span className="projects-name">Status</span>
            <select className="satus" onChange={(e)=>{handleFilter(e,'status')}}>
              <option value={""}></option> 
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </div>
        <div className="team-input-wrapper">
          <img className="team-input-img" src={SearchIcon} />
          <input className="team-input" type="text" placeholder="Search" onChange={(e)=>{handleFilter(e,'search')}}/>
        </div>
      </div>
    ) : (
      ""
    );

  const handleChange = (k) => {
    setActive(k);
  };

  return (
    <div className="team-tab-main">
      
      <Tabs
        className="team-tabs-wrapper"
        defaultActiveKey="1"
        onChange={handleChange}
        tabBarExtraContent={operations}
      >
        <TabPane tab="Team Members" key="Team Members">
          <TeamDashboard />
        </TabPane>
        <TabPane
          className="qa-form-buttons"
          tab="Invited Team Members"
          key="Invited Team Members"
        >
          <InviteTeamMemberList />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withRouter(TeamTabs);
