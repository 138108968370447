import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Menu, Breadcrumb } from 'antd';
import ProfileLogin from '../../../../src/assets/images/inventive_byte/profile-login.png'
import DropDownIcon from '../../../../src/assets/images/inventive_byte/drop-down.png'
import './Header.scss'
import ProfileToggle from "../ProfileToggle/ProfileToggle";

const Header = props => {

    return (
        <div className="about-self-header flex justify-content-end">
            <ProfileToggle/>
        </div>
    )
}

export default withRouter(Header)
