export const GET_LEAD_MEMBERS_INIT= 'GET_LEAD_MEMBERS_INIT'
export const GET_LEAD_MEMBERS_SUCCESS= 'GET_LEAD_MEMBERS_SUCCESS'
export const GET_LEAD_MEMBERS_FAILED= 'GET_LEAD_MEMBERS_FAILED'
export const GET_PROJECT_DETAIL_INIT= 'GET_PROJECT_DETAIL_INIT'
export const GET_PROJECT_DETAIL_SUCCESS= 'GET_PROJECT_DETAIL_SUCCESS'
export const GET_PROJECT_DETAIL_FAILED= 'GET_PROJECT_DETAIL_FAILED'
export const GET_AGREEMENT_LIST_INIT= 'GET_AGREEMENT_LIST_INIT'
export const GET_AGREEMENT_LIST_SUCCESS= 'GET_AGREEMENT_LIST_SUCCESS'
export const GET_AGREEMENT_LIST_FAILED= 'GET_AGREEMENT_LIST_FAILED'
export const GET_INVOICE_LIST_INIT= 'GET_INVOICE_LIST_INIT'
export const GET_INVOICE_LIST_SUCCESS= 'GET_INVOICE_LIST_SUCCESS'
export const GET_INVOICE_LIST_FAILED= 'GET_INVOICE_LIST_FAILED'
export const GET_TEAM_LIST_INIT= 'GET_TEAM_LIST_INIT'
export const GET_TEAM_LIST_SUCCESS= 'GET_TEAM_LIST_SUCCESS'
export const GET_TEAM_LIST_FAILED= 'GET_TEAM_LIST_FAILED'
export const DELETE_TEAM_MEMBER_INIT= 'DELETE_TEAM_MEMBER_INIT'
export const DELETE_TEAM_MEMBER_SUCCESS= 'DELETE_TEAM_MEMBER_SUCCESS'
export const DELETE_TEAM_MEMBER_FAILED= 'DELETE_TEAM_MEMBER_FAILED'
export const GET_ADD_TEAM_LIST_INIT= 'GET_ADD_TEAM_LIST_INIT'
export const GET_ADD_TEAM_LIST_SUCCESS= 'GET_ADD_TEAM_LIST_SUCCESS'
export const GET_ADD_TEAM_LIST_FAILED= 'GET_ADD_TEAM_LIST_FAILED'
export const ADD_TEAM_MEMBER_INIT= 'ADD_TEAM_MEMBER_INIT'
export const ADD_TEAM_MEMBER_SUCCESS= 'ADD_TEAM_MEMBER_SUCCESS'
export const ADD_TEAM_MEMBER_FAILED= 'ADD_TEAM_MEMBER_FAILED'
export const ADD_AGREEMENT_INIT= 'ADD_AGREEMENT_INIT'
export const ADD_AGREEMENT_SUCCESS= 'ADD_AGREEMENT_SUCCESS'
export const ADD_AGREEMENT_FAILED= 'ADD_AGREEMENT_FAILED'
export const GET_AGREEMENT_INIT= 'GET_AGREEMENT_INIT'
export const GET_AGREEMENT_SUCCESS= 'GET_AGREEMENT_SUCCESS'
export const GET_AGREEMENT_FAILED= 'GET_AGREEMENT_FAILED'
export const GET_PROJECT_TYPE_INIT= 'GET_PROJECT_TYPE_INIT'
export const GET_PROJECT_TYPE_SUCCESS= 'GET_PROJECT_TYPE_SUCCESS'
export const GET_PROJECT_TYPE_FAILED= 'GET_PROJECT_TYPE_FAILED'
export const GET_PROJECT_CAT_INIT= 'GET_PROJECT_CAT_INIT'
export const GET_PROJECT_CAT_SUCCESS= 'GET_PROJECT_CAT_SUCCESS'
export const GET_PROJECT_CAT_FAILED= 'GET_PROJECT_CAT_FAILED'
export const GET_PROJECT_PHASE_INIT= 'GET_PROJECT_PHASE_INIT'
export const GET_PROJECT_PHASE_SUCCESS= 'GET_PROJECT_PHASE_SUCCESS'
export const GET_PROJECT_PHASE_FAILED= 'GET_PROJECT_PHASE_FAILED'
export const GET_PROJECT_FEATURE_INIT= 'GET_PROJECT_FEATURE_INIT'
export const GET_PROJECT_FEATURE_SUCCESS= 'GET_PROJECT_FEATURE_SUCCESS'
export const GET_PROJECT_FEATURE_FAILED= 'GET_PROJECT_FEATURE_FAILED'