import React from 'react';
import NoDataFoundIcon from '../../assets/images/Icons/NoDataFoundIcon';
import './NoDataFound.scss'

const NoDataFound = () => {
    return (
			<>
				<div className='noDataFoundCon'>
					<NoDataFoundIcon />
					<div className='title'>No data available</div>
					<div className='normalText'>Please try again later</div>
				</div>
			</>
    )
}

export default NoDataFound