import React, { useState,useEffect } from "react";
import { Layout, Menu, Dropdown, Badge, Divider } from "antd";
import { withRouter } from "react-router-dom";
import {
  CloseOutlined,
  MenuOutlined,
  SettingOutlined,
  CheckOutlined,
  SendOutlined,
  SmileOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import Tubelight from "../../../src/assets/images/MainLayout/tubelight.png";
import dashboardIcon from "../../../src/assets/images/MainLayout/dashboard.png";
import updatesIcon from "../../../src/assets/images/MainLayout/updates_icon.png";
import invoicesIcon from "../../../src/assets/images/MainLayout/invoices_icon.png";
import perksIcon from "../../../src/assets/images/MainLayout/perks_icon.png";
import reviewsIcon from "../../../src/assets/images/MainLayout/reviews_icon.png";
import supportIcon from "../../../src/assets/images/MainLayout/support_icon.png";
import ProfileLogin from "../../../src/assets/images/MainLayout/profile-login.png";
import DropDownIcon from "../../../src/assets/images/MainLayout/drop-down.png";
import profileIcon from "../../../src/assets/images/MainLayout/profile-icon.png";
import projectInfoIcon from "../../../src/assets/images/MainLayout/project-info-icon.png";
import agreementsIcon from "../../../src/assets/images/MainLayout/agreements-icon.png";
import settingIcon from "../../../src/assets/images/MainLayout/setting-icon.png";
import logoutIcon from "../../../src/assets/images/MainLayout/logout-icon.png";
import notification from "../../../src/assets/images/MainLayout/notification.svg";
import notificationLight from "../../../src/assets/images/MainLayout/notification-icon-light.png";
import SupportAvatar from "../../../src/assets/images/Support/support_avatar.png";
import SupportHomeImg from "../../../src/assets/images/Support/support_home_icon.png";
import DashboardIcon from "../../../src/assets/images/inventive_byte/dashboard.svg";
import LeadManagementIcon from "../../../src/assets/images/inventive_byte/lead-management.svg";
import ClientManagementIcon from "../../../src/assets/images/inventive_byte/client-management.svg";
import BlogsIcon from "../../../src/assets/images/inventive_byte/blogs.svg";

import "./MainLayout.scss";
import ProfileToggle from "../../pages/admin/ProfileToggle/ProfileToggle";
import { useSelector } from "react-redux";

const { Header, Content, Sider } = Layout;

function MainLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [isOpened, setIsOpen] = useState(false);
  const [currentChatPopupIndex, setCurrentChatPopupIndex] = useState(0);

  const currentRoute =
    props.location.pathname.split("/")[
      props.location.pathname.split("/").length - 1
    ];
  const [activeKey, setActiveKey] = useState("");
  const { user_permission } = useSelector(
    (state) => state.DashboardReducers
  );
  console.log(user_permission,"user_permission")

  useEffect(() => {
    if (props.location.pathname.includes('/inventive/admin')) {
      setActiveKey("2");
      return;
    }
    if (props.location.pathname.includes('/inventive/lead')) {
      setActiveKey("3");
      return;
    }
    if (props.location.pathname.includes('/inventive/client')) {
      setActiveKey("4");
      return;
    }
    if (props.location.pathname.includes('/inventive/blogs')) {
      setActiveKey("5");
      return;
    }
  }, [currentRoute]);

  const handleClick = (e) => {
    switch (e.key) {
      case "1":
        props.history.push("/inventive/admin");
        break;
      case "2":
        props.history.push("/inventive/admin");
        break;
      case "3":
        props.history.push("/inventive/lead");
        break;
      case "4":
        props.history.push("/inventive/client");
        break;
      case "5":
        props.history.push("/inventive/blogs");
        break;
      default:
        return e.key;
    }
  };

  const renderChatBot = () => {
    switch (currentChatPopupIndex) {
      case 0:
        return (
          <div className="chat-icon-wrapper d-flex align-items-center justify-content-center">
            <div
              onClick={() => setCurrentChatPopupIndex(1)}
              role={"button"}
              className="chat-warpper d-flex align-items-center justify-content-center"
            >
              <img src={supportIcon} className="img-fluid chat-icon" />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="chatting-main">
            <div className="chatting-wrapper">
              <div className="d-flex align-items-center justify-content-between chatting-wrapper-header">
                <div className="d-flex chatting-user-wrapper">
                  <img
                    className="img-fliud chatting-icon"
                    src={SupportAvatar}
                  />
                  <div className="d-flex flex-column chatting-name-wrapper">
                    <span className="chatting-name">First name</span>
                    <span className="chatting-sub-title">Support Chat</span>
                  </div>
                </div>

                <CloseOutlined onClick={() => setCurrentChatPopupIndex(0)} />
              </div>
              <div className="chatting-wrapper-body">
                <div className="d-flex flex-column align-items-center justify-content-center chatting-wrapper-main">
                  <img
                    className="img-fluid chatting-body-img"
                    src={SupportHomeImg}
                  />
                  <h3 className="chatting-body-name">Hello, Johnny!</h3>
                  <h6 className="chatting-body-sub-title">
                    First name is your dedicated Project Manager.
                  </h6>
                  <p className="chatting-body-text">
                    First Name is here to discuss updates, questions, or any
                    issues you may have. He will always try to get back to you
                    as soon as possible within 48 business hours.
                  </p>
                  <button
                    className="chatting-body-button"
                    onClick={() => setCurrentChatPopupIndex(2)}
                  >
                    Chat now
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="chatting-main">
            <div className="chatting-wrapper">
              <div className="d-flex align-items-center justify-content-between chatting-wrapper-header">
                <div className="d-flex chatting-user-wrapper">
                  <img
                    className="img-fliud chatting-icon"
                    src={SupportAvatar}
                  />
                  <div className="d-flex flex-column chatting-name-wrapper">
                    <span className="chatting-name">First name</span>
                    <span className="chatting-sub-title">Support Chat</span>
                  </div>
                </div>

                <CloseOutlined onClick={() => setCurrentChatPopupIndex(0)} />
              </div>
              <div className="chatting-chat-wrapper-body">
                <div className="d-flex flex-column align-items-center justify-content-center chatting-chat-wrapper-main">
                  <div className="d-flex align-items-center justify-content-center chatting-features">
                    <SettingOutlined />
                    <span className="chatting-features-text">
                      Request New Feature
                    </span>
                  </div>
                  <div className="chatting-chat">
                    <Divider>Today</Divider>

                    <div className="d-flex align-items-start flex-column chatting-admin-chat">
                      <div className="d-flex align-items-center admin-chat-wrapper">
                        <img
                          className="img-fluid admin-img"
                          src={SupportAvatar}
                        />
                        <div className="d-flex flex-column admin-chat-message">
                          <span className="admin-name">First name</span>
                          <span className="admin-meassage">hahah</span>
                        </div>
                      </div>
                      <span className="admin-chat-date mt-2">2:55 PM</span>
                    </div>

                    <div className="d-flex align-items-end flex-column chat-user-chat">
                      <div className="d-flex align-items-center user-chat-wrapper">
                        <div className="d-flex flex-column user-chat-message">
                          <span className="user-meassage">hahahahah</span>
                        </div>
                      </div>
                      <span className="d-flex align-items-center user-chat-date mt-2">
                        <span>2:55 PM </span>
                        <CheckOutlined />
                      </span>
                    </div>
                  </div>

                  <div className="chatting-card-meassage">
                    <div className="d-flex align-items center chatting-meassage-wrapper">
                      <div className="measssage-input">
                        <input className="input" type="text" />
                        <SendOutlined className="meassage-icon" />
                      </div>

                      <div className="d-flex align-items-center justify-conten-center chat-icons">
                        <SmileOutlined className="icons" />
                      </div>

                      <div className="d-flex align-items-center justify-conten-center chat-files">
                        <PaperClipOutlined className="files" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Layout className="main-layout">
      <Sider
        collapsed={collapsed}
        trigger={null}
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
          } else {
            setIsOpen(false);
            setCollapsed(false);
          }
        }}
        breakpoint="md"
        collapsedWidth={0}
        className={isOpened ? "opened" : "closed"}
      >
        <Menu
          className="left-menu flex flex-column"
          defaultSelectedKeys={"1"}
          mode="inline"
          selectedKeys={[activeKey]}
        >
          {isOpened && (
            <Menu.Item
              disabled
              className="close-item"
              onClickCapture={() => setIsOpen(false)}
              icon={<CloseOutlined style={{cursor: 'pointer'}}/>}
            ></Menu.Item>
          )}
          <Menu.Item key="1" onClick={handleClick} disabled>
            <img className="left-menu-img" src={Tubelight} />
          </Menu.Item>
          <Menu.Item key="2" onClick={handleClick}>
            <img className="left-menu-img" src={DashboardIcon} />
            <p className="left-menu-p">Dashboard</p>
          </Menu.Item>
          {user_permission?.length && user_permission?.includes('Lead Management') ? <Menu.Item key="3" onClick={handleClick}>
            <img className="left-menu-img" src={LeadManagementIcon} />
            <p className="left-menu-p" style={{height: 13}}>Lead</p>
            <p className="left-menu-p">Management</p>
          </Menu.Item>:null}
          {user_permission?.length && user_permission?.includes('Lead Management') ? <Menu.Item key="4" onClick={handleClick}>
            <img className="lef-menu-img" src={ClientManagementIcon} />
            <p className="left-menu-p" style={{height: 13}}>Client</p>
            <p className="left-menu-p">Management</p>
          </Menu.Item>:null}
          {user_permission?.length && user_permission?.includes('Blogs') ? <Menu.Item key="5" onClick={handleClick}>
            <img className="left-menu-img" src={BlogsIcon} />
            <p className="left-menu-p">Blogs</p>
          </Menu.Item>:null}
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <MenuOutlined
            onClick={() => {
              setCollapsed(false);
              setIsOpen(true);
            }}
            className="d-md-none d-flex text-white menu-icon"
          />
          <div className="d-flex align-items-center justify-content-center">
            {/* <Badge count={2} className="mx-4">
              <img
                role={"button"}
                src={notificationLight}
                alt="notification"
                className="d-md-none"
              />
              <img
                role={"button"}
                src={notification}
                alt="notification"
                className="d-none d-md-flex"
              />
            </Badge> */}
            {/* <Dropdown
              placement="bottomRight"
              overlay={menu}
              trigger={["click"]}
            >
              <div
                role="button"
                className="flex align-items-center justify-content-center"
              >
                <img src={ProfileLogin} />
                <span className="about-self-username ">Olivia</span>
                <img src={DropDownIcon} />
              </div>
            </Dropdown> */}
            <ProfileToggle />
          </div>
        </Header>
        <Content>
          <div className="site-layout-background d-flex justify-content-center mainContentCon">
            {props?.children}
          </div>
          {/* {renderChatBot()} */}
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(MainLayout);
