import React from "react"
import { hydrate, render } from "react-dom"
import "./index.css"
import App from "./containers/App"
import "tachyons"

import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { store, persistor } from "./redux/store"
import 'antd/dist/antd.css'
import "bootstrap/dist/css/bootstrap.min.css"
import * as serviceWorker from "./serviceWorker"

const rootElement = document.getElementById("root")

if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <BrowserRouter>
                <App />
            </BrowserRouter>
            {/* </PersistGate> */}
        </Provider>,
        rootElement
    )
} else {
    render(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <BrowserRouter>
                <App />
            </BrowserRouter>
            {/* </PersistGate> */}
        </Provider>,
        rootElement
    )
}

serviceWorker.unregister()
