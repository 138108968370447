import React, { useState, useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import DropDownIcon from "../../../assets/images/inventive_byte/drop-down.png";
import ProfileLogin from "../../../assets/images/inventive_byte/profile-login.png";
import PerkIcon from "../../../assets/images/inventive_byte/perk-icon.svg";
import TeamIcon from "../../../assets/images/inventive_byte/team-icon.png";
import ProfileIcon from "../../../assets/images/inventive_byte/profile-icon.svg";
import SettingIcon from "../../../assets/images/inventive_byte/setting-icon.png";
import LogoutIcon from "../../../assets/images/inventive_byte/logout-icon.png";
import './ProfileToggle.scss'
import { useSelector } from 'react-redux';

const ProfileToggle = props => {
    const location = useLocation()
    const [activeProfile, setActiveProfile] = useState(false)


    const handleProfileClick = () => {
        setActiveProfile(!activeProfile)
    }

    const redirectToPerk = () => {
        props.history.push('/inventive/perk')
    }

    const redirectToProfile = () => {
        props.history.push('/inventive/profile')
    }

    const redirectToSettings = () => {
        props.history.push('/inventive/setting')
    }

    const redirectToTeam = (e) => {
        props.history.push('/inventive/team')
    }

    const handleLogout = () => {
        localStorage.clear()
        window.location.href = '/'
      }
      const { user_permission, user } = useSelector(
        (state) => state.DashboardReducers
      );
        console.log(user,"user")
    return (
        <div className="profile-setting-dropdown">
            <div className="profile-setting-wrapper">
                <Dropdown isOpen={activeProfile} toggle={handleProfileClick}>
                    <DropdownToggle style={{display: 'flex', alignItems: 'center'}}>
                        {user?.profile_image ? <img src={`${process.env.REACT_APP_IMAGE_URL}${user?.profile_image}`} onClick={handleProfileClick} style={{width: 45, height: 45, borderRadius: '50%'}}/>:
                            <div style={{width: 45, height: 45, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, textTransform: 'capitalize', background: '#E5C243', color: '#000'}}>{user?.first_name?.charAt()}</div>
                        }
                        <span className="profile-user-name">{user?.first_name}</span>
                        <img src={DropDownIcon} onClick={handleProfileClick}/>
                    </DropdownToggle>
                    <DropdownMenu>
                        {user_permission?.length && user_permission?.includes('Profile Information') ?<DropdownItem onClick={redirectToProfile} className={location.pathname.includes('/inventive/profile') && "active"}>
                            <img src={ProfileIcon} />
                            Profile
                        </DropdownItem>:null}
                        <DropdownItem onClick={redirectToPerk} className={location.pathname.includes('/inventive/perk') && "active"}>
                            <img src={PerkIcon} />
                            Perks
                        </DropdownItem>
                        {user_permission?.length && user_permission?.includes('Team Management') ? <DropdownItem onClick={redirectToTeam} className={location.pathname.includes('/inventive/team') && "active"}>
                            <img src={TeamIcon} />
                            Team Management
                        </DropdownItem>:null}
                        <DropdownItem onClick={redirectToSettings} className={location.pathname.includes('/inventive/setting') && "active"}>
                            <img src={SettingIcon} />
                            Settings
                        </DropdownItem>
                        <DropdownItem onClick={handleLogout}>
                            <img src={LogoutIcon} />
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    )
}

export default withRouter(ProfileToggle)
