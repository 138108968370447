import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const getClientList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_CLIENT_LIST_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/clients`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_CLIENT_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_CLIENT_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getClientFilterList = (data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/clients`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_CLIENT_LIST_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_CLIENT_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_CLIENT_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getClientDetail = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_CLIENT_DETAIL_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/client/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_CLIENT_DETAIL_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_CLIENT_DETAIL_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getUpdateList = (id, data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/updates/${id}`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_UPDATE_LIST_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_UPDATE_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_UPDATE_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getFeedbackList = (id, data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/feedbacks/${id}`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_FEEDBACKS_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_FEEDBACKS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_FEEDBACKS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const addUpdates = (id, data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_UPDATES_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/updates/${id}`, data)
        .then(res => {
          console.log(res,"EDIT_BLOGS_SUCCESS")
          if(res.data) {
            dispatch({
              type: actionTypes.ADD_UPDATES_SUCCESS,
              payload: res,
            })
            onSuccess();
          }else {
            onFail(res.error);
            dispatch({
              type: actionTypes.ADD_UPDATES_FAILED,
            })
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_UPDATES_FAILED,
          })
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editUpdates = (id, data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_UPDATES_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/update/${id}`, data)
        .then(res => {
          console.log(res,"EDIT_BLOGS_SUCCESS")
          if(res.data) {
            dispatch({
              type: actionTypes.ADD_UPDATES_SUCCESS,
              payload: res,
            })
            onSuccess();
          }else {
            onFail(res.error);
            dispatch({
              type: actionTypes.ADD_UPDATES_FAILED,
            })
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_UPDATES_FAILED,
          })
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editUpdate = (id, data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.EDIT_UPDATE_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/update/${id}`, data)
        .then(res => {
          if(res.data) {
            dispatch({
              type: actionTypes.EDIT_UPDATE_SUCCESS,
              payload: res,
            })
            onSuccess();
          }else {
            onFail(res.error);
            dispatch({
              type: actionTypes.EDIT_UPDATE_FAILED,
            })
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.EDIT_UPDATE_FAILED,
          })
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }
  

  export const getSingleUpdate = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_SINGLE_UPDATE_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/update/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_SINGLE_UPDATE_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_SINGLE_UPDATE_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getReviews = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_REVIEWS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/reviews/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_REVIEWS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_REVIEWS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const postReview = (clientid,reviewid,data,onSuccess) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.POST_REVIEWS_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/review/${clientid}/${reviewid}`, {
          status: data
        })
        .then(res => {
          if(onSuccess) {
            onSuccess(res)
          }
          dispatch({
            type: actionTypes.POST_REVIEWS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.POST_REVIEWS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getReferrals = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_REFERRALS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/referrals/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_REFERRALS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_REFERRALS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getFeedbacks = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_FEEDBACKS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/feedbacks/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_FEEDBACKS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_FEEDBACKS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getSingleFeedback = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_SINGLE_FEEDBACK_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/feedback/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_SINGLE_FEEDBACK_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_SINGLE_FEEDBACK_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editFeedback = (id,data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.EDIT_SINGLE_FEEDBACK_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/feedback/${id}`, data)
        .then(res => {
          dispatch({
            type: actionTypes.EDIT_SINGLE_FEEDBACK_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_SINGLE_FEEDBACK_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editReferral = (id,data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.EDIT_REFERRAL_STATUS_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/referral/${id}`, {status: data})
        .then(res => {
          dispatch({
            type: actionTypes.EDIT_REFERRAL_STATUS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.EDIT_REFERRAL_STATUS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getSupportList = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_SUPPORT_LIST_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/support/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_SUPPORT_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_SUPPORT_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const postSupport = (id, data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.POST_SUPPORT_INIT,
      })
      await api
        .postwithReqFormToken(`${process.env.REACT_APP_API_URL}/admin/support/${id}`, data)
        .then(res => {
          dispatch({
            type: actionTypes.POST_SUPPORT_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.POST_SUPPORT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editClient = (id,data, onSuccess, onFail) => {
    return async dispatch => {
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/client/${id}`, data)
        .then(res => {
          if(res.result) {
            onSuccess(res.data);
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }