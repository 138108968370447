import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Pagination, Rate, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import FeedbackIcon from "../../../../../assets/images/inventive_byte/feedback.svg";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./FeedBackList.scss";
import moment from "moment";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const FeedBackList = (props) => {
  const dispatch = useDispatch();
  const { feedback_list, loading } = useSelector(
    (state) => state.ClientReducers
  );
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const pageSize = 5;

  useEffect(() => {
    dispatch(ClientActions.getFeedbacks(props.clientID));
  }, []);

  useEffect(() => {
    setState({
      data: feedback_list?.paginations?.total_docs,
      totalPage: feedback_list?.paginations?.total_docs / pageSize,
      current: feedback_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, [feedback_list]);

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: "page", data: page }];
    dispatch(await ClientActions.getFeedbackList(props.clientID, newParams));
  };

  const handleFeedBack = (feedbackID) => {
    props.history.push(
      `/inventive/client/feedback/${props?.clientID}/${feedbackID}`
    );
    
  };
  
  return (
    <div className="client-feedback-main">
      <div className="client-feedback-desktop-wrap">
        <div className="main-client-wrapper">
          <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
            <div className="th-data">
              Feedback Stars{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data justify-content-center">
              Submission Date{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data d-flex justify-content-center justify-content-lg-end">
              Action <span className="img-icon"></span>{" "}
            </div>
          </div>
        </div>
        <Spin className="client-feedback-spin" spinning={loading}>
          {feedback_list?.data?.length ? feedback_list?.data?.map((data, index) => {
            return (
              index >= state.minIndex &&
              index < state.maxIndex && (
                <div className="table-body flex justify-content-start align-items-center">
                  <div className="table-data">
                    <div className="username" onClick="">
                      <img className="feedback-img" src={FeedbackIcon} />
                      <Rate allowHalf defaultValue={data?.rating} />
                    </div>
                  </div>
                  <div className="table-data justify-content-center">
                    <div className="flex feedback-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Submission Date
                      </label>
                      <div className="submission-date flex align-items-center">
                        {moment(data?.created_at).format("MM-DD-YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="table-data justify-content-center justify-content-lg-end">
                    <div className="d-flex justify-content-center justify-content-lg-end feedback-card">
                      <div className="replay-button-group">
                        {!data?.reply ? (
                          <Button
                            className="replay-button"
                            onClick={() => handleFeedBack(data?.id)}
                          >
                            Reply Now
                          </Button>
                        ) : (
                          <div className="replay-button-group d-flex align-items-center">
                            <CheckOutlined className="replay-text-icon" />
                            <span className="replay-text">Replied</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          }):
            <NoDataFound />
          }
        </Spin>
      </div>
      {feedback_list?.paginations?.total_docs > 5 ? <div className="flex align-items-baseline justify-content-center client-feedback-pagination">
        <Pagination
          defaultCurrent={1}
          total={feedback_list?.paginations?.total_docs}
          pageSize={pageSize}
          onChange={handleChange}
          current={state.current}
        />
      </div>:null}
    </div>
  );
};

export default withRouter(FeedBackList);
