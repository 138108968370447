import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { Button } from "react-bootstrap";
import LeadProfileIcon from "../../../../assets/images/inventive_byte/lead-profile-icon.png";
import BlackCheck from "../../../../assets/images/inventive_byte/black-check.png";
import WhiteCheck from "../../../../assets/images/inventive_byte/white-check.svg";
import EditIcon from "../../../../assets/images/inventive_byte/edit-icon.svg";
import AddIcon from "../../../../assets/images/inventive_byte/add-blog.png";
import Header from "../../Header/Header";
import HeaderMobile from "../../Header/HeaderMobile";
import LeftPanel from "../../LeftPanel";
import EmailIcon from "../../../../assets/images/inventive_byte/email-icon.svg";
import TimeZoneIcon from "../../../../assets/images/inventive_byte/time-zone.svg";
import GoogleIcon from "../../../../assets/images/inventive_byte/google-icon.svg";
import PdfIcon from "../../../../assets/images/inventive_byte/pdf-icon.png";
import "./EditClientDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import * as LeadActions from "../../../../redux/LeadFlowControl/LeadActions";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import ProjectDetail from "../../LeadManagement/LeadMemberDetail/ProjectDetail/ProjectDetail";

const EditClientDetail = (props) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [active, setActive] = useState(false)
  const { project_detail, loading } = useSelector((state) => state.LeadReducers);

  useEffect(() => {
    if(params?.id) {
      dispatch(LeadActions.getProjectDetail(params?.id))
      dispatch(LeadActions.getProjectType());
      dispatch(LeadActions.getProjectCategory());
      dispatch(LeadActions.getProjectPhase());
      dispatch(LeadActions.getProjectFeatures());
    }
  },[])

  return (
    <>
      {loading ? <Loader /> : <>
      <div className="clients-details-wrapper">
        <div className="header-left">
          <span className="back-button" onClick={() => props.history.goBack()}>
            <ArrowLeftOutlined /> Back
          </span>
        </div>
        <div className="client-management-profile">
          <div className="w-100 flex align-items-center justify-content-end client-email-edit">
            <img src={EditIcon} />
          </div>
          <div className="d-flex flex-md-column flex-lg-row client-management-wrapper">
            <div className="d-flex align-items-center flex-column flex-lg-row client-profile" style={{minWidth: 200}}>
              <img src={project_detail?.data?.profile_image ? `https://d3u1baow9deq5h.cloudfront.net/` + project_detail?.data?.profile_image : LeadProfileIcon} style={{width: 100, borderRadius: '50%'}} />
              <div className="d-flex flex-column align-items-center align-items-lg-start ml-0 ml-lg-3">
                <span className="user-name">{project_detail?.data?.first_name + ' ' + project_detail?.data?.last_name}</span>
                <Button className="active-btn">{project_detail?.data?.active ? "Active" : "Inactive"}</Button>
              </div>
            </div>

            <div className="flex align-items-center client-email">
              <img src={EmailIcon} />
              <div className="flex flex-column email">
                <span className="email-label">Email Address</span>
                <span className="email-text">{project_detail?.data?.email}</span>
              </div>
            </div>

            <div className="flex align-items-center client-time">
              <img src={TimeZoneIcon} />
              <div className="flex flex-column time">
                <span className="time-label">Time Zone</span>
                <span className="time-text">{project_detail?.data?.time_zone}</span>
              </div>
            </div>
          </div>
        </div>
        <ProjectDetail clientID={params?.id} />
      </div>
      </>}
    </>
  );
};

export default withRouter(EditClientDetail);
