import React, { useEffect, useState, useRef } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import "./AddTextAgreement.scss";

const AddTextAgreement = (props) => {
  const params = useParams()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false);
  const editorRef = useRef(null);
  const [state, setState] = useState({
    name: '',
    details: ''
  })
  const [content, setContent] = useState();

  const handleInvoiceReport = () => {
    setActive(true);
  };

  const onHandleChange = (e) => {
    setState({...state,[e.target.name] : e.target.value})
  }

  const saveAgreement = async () => {
   await dispatch(LeadActions.addAgreement(params?.id,state))
   setState({
    ...state,
    name: '',
    details: ''
   })
   props.history.push("/inventive/lead")
  }

  const cancelClick = () => {
    props.history.goBack()
  }

  return (
    <div>
      
      <div className="new-agreement-wrapper">
        <div className="header-left d-flex align-items-center justify-content-between">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <ArrowLeftOutlined /> Back
          </span>

          {/* <div className="d-flex align-items-center agreements-templates">
            <span className="agreements-templates-text">Templates:</span>
            <span className="agreements-templates-sow">SOW</span>
            <span className="agreements-templates-msa">MSA</span>
          </div> */}
        </div>
        <div className="agreement-main-wrapper">
          <div className="agreement-title-wrapper d-flex justify-content-between align-items-center">
            <h5 className="agreement-title">Text Editor</h5>
            <CloseOutlined />
          </div>
          <div className="agreement-card-main">
            <div className="add-agreement-card">
              <Form className="agreement-form">
                <div className="input-fields agreement-name-wrapper flex flex-column">
                  <FormGroup className="agreement-form-group mb-3 mb-md-5">
                    <Label className="agreement-label" for="website">
                      Contract Name
                    </Label>
                    <Input
                      className="agreement-input"
                      type="text"
                      name="name"
                      id="name"
                      onChange={onHandleChange}
                      value={state?.name}
                    />
                  </FormGroup>
                  <FormGroup className="agreement-form-group">
                    <Label className="agreement-label" for="website">
                      Agreement Details
                    </Label>
                    <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(a, b) => {
                      setState({ ...state, details: a });
                      setContent(b.getContent({ format: "raw" }));
                    }}
                    init={{
                      height: 500,
                      plugins:
                        "print preview paste importcss searchreplace autolink autosave save directionality code fullpage visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc",
                      paste_data_images: true,
                      menubar: "file edit view insert format tools table help",
                      toolbar:
                        "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                      toolbar_mode: "wrap",
                      block_formats:
                        "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      setup: function (editor) {
                        editor.addShortcut(
                          "alt+shift+1",
                          "H1 is Permanently Disabled",
                          function () {
                            editor.execCommand();
                          }
                        );
                      },
                    }}
                    value={state?.details}
                    name="content"
                  />
                  </FormGroup>
                </div>
                <div className="agreement-buttons flex">
                  <div className="button-group flex align-items-center justify-content-center">
                    <Button className="button-save" onClick={saveAgreement} disabled={(state?.name && editorRef?.current?.getContent()) ? false : true}>
                      Save
                    </Button>
                    <Button className="button-cancel" onClick={cancelClick}>Cancel</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddTextAgreement);
