import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import SendIcon from "../../../../../assets/images/inventive_byte/invite-team-icon.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./AddUpdate.scss";
import { message } from "antd";

const EditUpdate = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { single_update } = useSelector((state) => state.ClientReducers);
  const [active, setActive] = useState(false);
  const [state, setState] = useState({
    name: "",
    details: "",
    draft: false,
  });
  console.log(state,"state")

  useEffect(() => {
    dispatch(ClientActions.getSingleUpdate(params?.id));
  }, []);

  useEffect(() => {
    setState({
      name: single_update?.data?.name,
      details: single_update?.data?.details,
      draft: single_update?.data?.draft,
    });
  }, [single_update]);

  const handleInvoiceReport = () => {
    setActive(true);
  };

  const onHandleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const editUpdate = async (value) => {
    if (value === true) {
      const payload = {
        name: state?.name,
        details: state?.details,
        draft: value,
      };
      dispatch(ClientActions.editUpdates(
        params?.id, payload,
        () => {
          message.success("Edit update successfully");
          props.history.push("/inventive/client");
        },
        (err) => {
          message.error(err || "Failed to Edit update.");
        }
      ));
    } else {
      const payload = {
        name: state?.name,
        details: state?.details,
        draft: value,
      };
      dispatch(ClientActions.editUpdates(
        params?.id, payload,
        () => {
          message.success("Edit update successfully");
          props.history.push("/inventive/client");
        },
        (err) => {
          message.error(err || "Failed to Edit update.");
        }
      ));
    }
  };

  const cancelClick = () => {
    props.history.push("/inventive/client");
  };

  return (
    <>
      <div className="new-update-wrapper">
        <div className="header-left d-flex align-items-center justify-content-between">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <ArrowLeftOutlined /> Back
          </span>
        </div>
        <div className="update-main-wrapper">
          <div className="update-title-wrapper d-flex justify-content-between align-items-center">
            <h5 className="update-title">New Update</h5>
            <CloseOutlined />
          </div>
          <div className="update-card-main">
            <div className="add-update-card">
              <Form className="update-form">
                <div className="input-fields update-name-wrapper flex flex-column">
                  <FormGroup className="update-form-group mb-3 mb-md-5">
                    <Label className="update-label" for="website">
                      Update Name
                    </Label>
                    <Input
                      className="update-input"
                      type="text"
                      name="name"
                      id="name"
                      onChange={onHandleChange}
                      value={state?.name}
                    />
                  </FormGroup>
                  <FormGroup className="update-form-group">
                    <Label className="update-label" for="website">
                      Update Details
                    </Label>
                    <Input
                      className="update-textarea"
                      type="textarea"
                      name="details"
                      id="details"
                      onChange={onHandleChange}
                      value={state?.details}
                    />
                  </FormGroup>
                </div>
                <div className="update-buttons flex">
                  <div className="button-group flex-column-reverse flex-md-row flex align-items-center justify-content-between">
                    <Button className="button-cancel" onClick={cancelClick}>
                      Cancel
                    </Button>
                    <div className="d-flex justify-content-end button-group">
                      <Button
                        className="button-save"
                        onClick={() => {
                          editUpdate(true);
                        }}
                        disabled={(state.name && state.details) ? false : true}
                      >
                        Save Draft
                      </Button>
                      <Button
                        className="button-save"
                        onClick={() => {
                          editUpdate(false);
                        }}
                      >
                        <img src={SendIcon} />
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(EditUpdate);
