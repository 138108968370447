import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { getTokenInLocaleStorage } from '../utils/general'

function ProtectedRoute(props) {
  const token = getTokenInLocaleStorage()
  const tokenData = token ? jwt_decode(token) : {}
  console.log(tokenData,"tokenData")
  return tokenData?.user_id ? <Route {...props} /> : <Redirect to="/" />
}
export default ProtectedRoute
