import React from "react";
import "./ErrorRoute404.scss";

import { Link } from "react-router-dom";

const ErrorRoute404 = () => {
  return (
    <div className="error-page">
      <h1 className="error-404">404</h1>
      <h1 className="title-404">Oops! Page Not Found</h1>
      <p className="subtitle-404">
        Sorry, but the page you are looking for is not found. Please make sure
        you have typed the correct URL.
      </p>
      <div className="return-to-home-404">
        <Link to="/">
          <h6 className="r2h-button">Return to Home</h6>
        </Link>
      </div>
    </div>
  );
};

export default ErrorRoute404;
