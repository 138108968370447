import * as actionTypes from './ActionTypes'

const initialState = {
    loading: false,
    user:""
}

const AdminLoginReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        default:
            return { ...state }
    }
}

export default AdminLoginReducers
