export const GET_TEAM_MEMBERS_INIT= 'GET_TEAM_MEMBERS_INIT'
export const GET_TEAM_MEMBERS_SUCCESS= 'GET_TEAM_MEMBERS_SUCCESS'
export const GET_TEAM_MEMBERS_FAILED= 'GET_TEAM_MEMBERS_FAILED'
export const GET_TEAM_MEMBERS_DETAIL_INIT= 'GET_TEAM_MEMBERS_DETAIL_INIT'
export const GET_TEAM_MEMBERS_DETAIL_SUCCESS= 'GET_TEAM_MEMBERS_DETAIL_SUCCESS'
export const GET_TEAM_MEMBERS_DETAIL_FAILED= 'GET_TEAM_MEMBERS_DETAIL_FAILED'
export const GET_DEPARTMENT_INIT= 'GET_DEPARTMENT_INIT'
export const GET_DEPARTMENT_SUCCESS= 'GET_DEPARTMENT_SUCCESS'
export const GET_DEPARTMENT_FAILED= 'GET_DEPARTMENT_FAILED'
export const GET_PROJECT_INIT= 'GET_PROJECT_INIT'
export const GET_PROJECT_SUCCESS= 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAILED= 'GET_PROJECT_FAILED'
export const GET_INVITED_TEAM_MEMBERS_INIT= 'GET_INVITED_TEAM_MEMBERS_INIT'
export const GET_INVITED_TEAM_MEMBERS_SUCCESS= 'GET_INVITED_TEAM_MEMBERS_SUCCESS'
export const GET_INVITED_TEAM_MEMBERS_FAILED= 'GET_INVITED_TEAM_MEMBERS_FAILED'
export const GET_PERMISSION_INIT= 'GET_PERMISSION_INIT'
export const GET_PERMISSION_SUCCESS= 'GET_PERMISSION_SUCCESS'
export const GET_PERMISSION_FAILED= 'GET_PERMISSION_FAILED'
export const EDIT_TEAM_MEMBER_INIT= 'EDIT_TEAM_MEMBER_INIT'
export const EDIT_TEAM_MEMBER_SUCCESS= 'EDIT_TEAM_MEMBER_SUCCESS'
export const EDIT_TEAM_MEMBER_FAILED= 'EDIT_TEAM_MEMBER_FAILED'
export const POST_INVITE_MEMBER_INIT= 'POST_INVITE_MEMBER_INIT'
export const POST_INVITE_MEMBER_SUCCESS= 'POST_INVITE_MEMBER_SUCCESS'
export const POST_INVITE_MEMBER_FAILED= 'POST_INVITE_MEMBER_FAILED'
export const DELETE_TEAM_MEMBER_INIT= 'DELETE_TEAM_MEMBER_INIT'
export const DELETE_TEAM_MEMBER_SUCCESS= 'DELETE_TEAM_MEMBER_SUCCESS'
export const DELETE_TEAM_MEMBER_FAILED= 'DELETE_TEAM_MEMBER_FAILED'
export const RESEND_INVITE_INIT= 'RESEND_INVITE_INIT'
export const RESEND_INVITE_SUCCESS= 'RESEND_INVITE_SUCCESS'
export const RESEND_INVITE_FAILED= 'RESEND_INVITE_FAILED'