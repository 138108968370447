import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { Button } from "react-bootstrap";
import LeadProfileIcon from "../../../../assets/images/inventive_byte/lead-profile-icon.png";
import BlackCheck from "../../../../assets/images/inventive_byte/black-check.png";
import WhiteCheck from "../../../../assets/images/inventive_byte/white-check.svg";
import EditIcon from "../../../../assets/images/inventive_byte/edit-icon.svg";
import AddIcon from "../../../../assets/images/inventive_byte/add-blog.png";
import Header from "../../Header/Header";
import HeaderMobile from "../../Header/HeaderMobile";
import LeftPanel from "../../LeftPanel";
import InvoicesList from "./Invoices/InvoicesList";
import "./ClientManagementDetail.scss";
import AgreementList from "./Agreements/AgreementList";
import UpdateList from "./Updates/UpdateList";
import ReviewList from "./Reviews/ReviewList";
import ReferralList from "./Referrals/ReferralList";
import FeedBackList from "./FeedBack/FeedBackList";
import SupportChat from "./Support/SupportChat";
import TeamList from "./Team/TeamList";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import * as LeadActions from "../../../../redux/LeadFlowControl/LeadActions";

const LeadMemberDetailMain = (props) => {
  const dispatch = useDispatch()
  const { TabPane } = Tabs;
  const params = useParams();
  const { project_detail, loading } = useSelector((state) => state.LeadReducers);
  const [active, setActive] = useState("Invoices");
  const [loader, setLoader] = useState(false)

  const { user_permission } = useSelector(
    (state) => state.DashboardReducers
  );

  const handleChange = (k) => {
    setActive(k);
  };
  

  useEffect(() => {
    setLoader(true)
    dispatch(LeadActions.getProjectDetail(params?.id)).then(() => {
      setLoader(false)
    });
  }, []);


  const redirectToInvoice = () => {
    props.history.push("/inventive/client/invoice");
  };

  const redirectToAgreement = () => {
      props.history.push(`/inventive/client/agreement/${params?.id}`);
  };

  const redirectToUpdate = () => {
      props.history.push(`/inventive/client/update/${params?.id}`);
  };

  const redirectToTeam = () => { 
    props.history.push(`/inventive/client/team/${params?.id}`);
  };

  const redirectToDetail = () => {
    props.history.push(`/inventive/client/detail/${params?.id}`);
  };

  const redirectToEditProfile = () => {
      props.history.push(`/inventive/client/profile/${params?.id}`);
  };
  

  const operations =
    // active === "Invoices" ? (
    //   <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
    //     <Button
    //       className="invoice-button d-flex align-items-center"
    //       onClick={redirectToInvoice}
    //     >
    //       <img className="invoice-button-img" src={AddIcon} />
    //       Add Invoice
    //     </Button>
    //   </div>
    // ) : 
    active === "Agreements" && user_permission?.includes('Agreements') ? (
      <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
        <Button className="invoice-button d-flex align-items-center" onClick={redirectToAgreement}>
          <img className="invoice-button-img" src={AddIcon} />
          Add Agreement
        </Button>
      </div>
    ) : active === "Updates" && user_permission?.includes('Updates') ? (
      <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
        <Button className="invoice-button d-flex align-items-center" onClick={redirectToUpdate}>
          <img className="invoice-button-img" src={AddIcon} />
          Add Update
        </Button>
      </div>
    ): active === "Team" && user_permission?.includes('Team Management') ? (
        <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
          <Button className="invoice-button d-flex align-items-center" onClick={redirectToTeam}>
            <img className="invoice-button-img" src={AddIcon} />
            Add Team Member
          </Button>
        </div>
    ) : (
      ""
    );
  return (
    <>
    {loader ? <Loader /> :
    <div className="client-management-details-wrapper">
      <div className="header-left">
        <span className="back-button" onClick={() => props.history.goBack()}>
          <ArrowLeftOutlined /> Back
        </span>
      </div>
      <div className="client-management-profile d-flex flex-md-column flex-lg-row justify-content-between">
        <div className="d-flex flex-column align-items-center justify-content-center project-profile avatarMainCon" style={{minWidth: 200}}>
          {/* <img src={project_detail?.data?.profile_image ? `https://d3u1baow9deq5h.cloudfront.net/` + project_detail?.data?.profile_image : LeadProfileIcon} style={{width: 100, borderRadius: '50%'}} /> */}
          {!project_detail?.data?.profile_image ? (
            <p className="d-flex align-items-center justify-content-center user-icon">
              {project_detail?.data?.first_name?.charAt(0).toUpperCase()}
            </p>
          ) : (
            <img
              className="blog-img user-icon"
              src={
                `https://d3u1baow9deq5h.cloudfront.net/` +
                project_detail?.data?.profile_image
              }
            />
          )}
          <span className="user-name" style={{marginBottom: 5}}>
            {project_detail?.data?.first_name + ' ' + project_detail?.data?.last_name} <img src={EditIcon} onClick={redirectToEditProfile} />
          </span>
          <Button className="active-btn" style={!project_detail?.data?.active ? {background: 'rgba(242, 72, 34, 0.34)', color: '#F24822'} : {}}>{project_detail?.data?.active ? "Active" : "Inactive"}</Button>
        </div>
        <div className="d-flex flex-column flex-md-row  justify-content-between client-project-details">
          <div className="d-flex flex-row flex-md-column justify-content-start justify-content-md-center project-details-wrapper">
            <span className="project-label">Project Name</span>
            <span className="project-text">{project_detail?.data?.project?.name}</span>
          </div>
          <div className="d-flex flex-row flex-md-column justify-content-start justify-content-md-center project-details-wrapper">
            <span className="project-label">Project type</span>
            <span className="project-text">{project_detail?.data?.project?.types?.name}</span>
          </div>
          <div className="d-flex flex-row flex-md-column justify-content-start justify-content-md-center project-details-wrapper">
            <span className="project-label">Project category</span>
            <span className="project-text">{project_detail?.data?.project?.category?.name}</span>
          </div>
          <div className="d-flex flex-row flex-md-column justify-content-start justify-content-md-center project-details-wrapper">
            <span className="project-label">Budget</span>
            <span className="project-text">${project_detail?.data?.project?.total_budget}</span>
          </div>
          <div className="d-flex flex-row flex-md-column justify-content-start justify-content-md-center project-details-wrapper">
            <span className="project-label">Target Date</span>
            <span className="project-text">{project_detail?.data?.project?.target_date ? moment(project_detail?.data?.project?.target_date).format('MMM DD, YYYY') : 'N/A'}</span>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center project-button-wrapper">
          <Button className="project-button" onClick={redirectToDetail}>View More</Button>
        </div>
      </div>
      <div>
        <Tabs
          className="client-management-status-tab"
          defaultActiveKey="1"
          onChange={handleChange}
          tabBarExtraContent={operations}
        >
          {user_permission?.includes('Invoices') ?<TabPane tab="Invoices" key="Invoices">
            <InvoicesList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Agreements') ? <TabPane className="" tab="Agreements" key="Agreements">
            <AgreementList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Updates') ? <TabPane className="" tab="Updates" key="Updates">
            <UpdateList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Reviews') ? <TabPane className="" tab="Reviews" key="Reviews">
            <ReviewList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Referrals') ? <TabPane className="" tab="Referrals" key="Referrals">
            <ReferralList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Feedback') ? <TabPane className="" tab="Feedback" key="Feedback">
            <FeedBackList clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Support') ? <TabPane className="" tab="Support" key="Support">
            <SupportChat clientID = {params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Team Management') ? <TabPane className="" tab="Team" key="Team">
            <TeamList clientID = {params?.id}/>
          </TabPane>:null}
        </Tabs>
      </div>
    </div>}
    </>
  );
};

export default withRouter(LeadMemberDetailMain);
