import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import ActiveIcon from "../../../../../assets/images/inventive_byte/lead-active.png";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import "./AgreementDetail.scss";
import { Spin } from "antd";

const AgreementDetail = (props) => {
  const [active, setActive] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const { get_agreement_detail, loading } = useSelector(
    (state) => state.LeadReducers
  );

  useEffect(() => {
    dispatch(LeadActions.getAgreementDetail(params?.id));
  }, []);

  useEffect(() => {}, [get_agreement_detail]);

  return (
    <>
      <div className="agreement-detail-wrapper">
        <div className="agreement-detail-main">
          <div className="header-left d-flex align-items-center justify-content-between">
            <span
              className="back-button flex align-items-center"
              onClick={() => props.history.goBack()}
            >
              <ArrowLeftOutlined /> Back
            </span>
          </div>
          <Spin spinning={loading}>
            <div className="agreement-main-wrapper">
              <div className="agreement-title-wrapper d-flex justify-content-between align-items-center">
                <h5 className="agreement-title">
                  {get_agreement_detail?.data?.name}
                </h5>
              </div>
              <div className="agreement-card-main">
                <div className="add-agreement-card">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: get_agreement_detail?.data?.details,
                    }}
                  ></p>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center add-agreement-footer">
                  <div className="d-flex align-items-center agreement-text-wrapper">
                    <img src={ActiveIcon} />{" "}
                    <span className="agreement-detail-text">
                      Olivia Khan has agreed to the terms and conditions within
                      this agreement.
                    </span>
                  </div>
                  <div className="agreement-date-wrapper d-flex">
                    <span className="agreement-detail-date-label">
                      Completion Date:
                    </span>
                    <span className="agreement-detail-date">
                      {" "}
                      11-11-2021 (10:12 PM PT)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default withRouter(AgreementDetail);
