export const GET_BLOGS_INIT= 'GET_BLOGS_INIT'
export const GET_BLOGS_SUCCESS= 'GET_BLOGS_SUCCESS'
export const GET_BLOGS_FAILED= 'GET_BLOGS_FAILED'
export const POST_BLOGS_INIT= 'POST_BLOGS_INIT'
export const POST_BLOGS_SUCCESS= 'POST_BLOGS_SUCCESS'
export const POST_BLOGS_FAILED= 'POST_BLOGS_FAILED'
export const EDIT_BLOGS_SUCCESS= 'EDIT_BLOGS_SUCCESS'
export const GET_BLOG_INIT= 'GET_BLOG_INIT'
export const GET_BLOG_SUCCESS= 'GET_BLOG_SUCCESS'
export const GET_BLOG_FAILED= 'GET_BLOG_FAILED'
export const SET_BLOG_DETAIL= 'SET_BLOG_DETAIL'
export const DELETE_BLOGS_INIT= 'DELETE_BLOGS_INIT'
export const DELETE_BLOGS_SUCCESS= 'DELETE_BLOGS_SUCCESS'