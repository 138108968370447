import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  SettingOutlined,
  CheckOutlined,
  PaperClipOutlined,
  SmileOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Divider, message, Upload } from "antd";
import moment from "moment";
import SupportAvatar from "../../../../../assets/images/inventive_byte/support_avatar.png";
import SendIcon from "../../../../../assets/images/inventive_byte/send-icon.svg";
import CrossIcon from "../../../../../assets/images/inventive_byte/cross-icon.svg";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import * as UploadActions from "../../../../../redux/UploadFlowControl/UploadActions";
import api from "../../../../../utils/api";
import "./SupportChat.scss";
import EmojiPicker from 'emoji-picker-react';

const SupportChat = (props) => {
  const dispatch = useDispatch();
  const { support_list, loading } = useSelector(
    (state) => state.ClientReducers
  );
  const { presigned_url } = useSelector((state) => state.UploadReducers);
  const [presignedState, setPresignedState] = useState(presigned_url);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [emoji, setEmoji] = useState(false);
  const messageEl = useRef(null);
  const [state, setState] = useState({
    message: "",
    attachment: "",
  });

  useEffect(() => {
    dispatch(ClientActions.getSupportList(props.clientID)).then(() => {
      scrollToMyRef();
    })
  }, []);

  useEffect(() => {}, [support_list]);

  useEffect(() => {
    if (presignedState?.attachment) {
      setState({
        ...state,
        attachment: presignedState?.attachment?.data?.fields?.key,
      });
    }
    setLoadingPDF(false);
  }, [presignedState?.agreement]);

  const onBannerSelect = async (files, file) => {
    if (files?.file?.status !== "removed" && files?.file?.status !== "uploading") {
      const formData = new FormData();
        formData.append("attachment", files.file.originFileObj);
        api
          .postwithReqFormToken(
            `${process.env.REACT_APP_API_URL}/admin/support/${props?.clientID}`,
            formData
          )
          .then((res) => {
            console.log(res,":resresres")
            if (res.result) {
              message.success(res.data)
              dispatch(ClientActions.getSupportList(props.clientID)).then(() => {
                scrollToMyRef();
              })
            }else {
              message.error(res.data)
            }
          }).catch(err => {
            console.log(err,"errerrerrerr")
            message.error("Network request failed")
          })
    }
    
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const postSupport = async () => {
    if(!state?.message?.trim()) return 
    const formData = new FormData();
    formData.append("message", state?.message);
    await api
      .postwithReqFormToken(
        `${process.env.REACT_APP_API_URL}/admin/support/${props?.clientID}`,
        formData
      )
      .then((res) => {
        setEmoji(false)
        if (res.result) {
          message.success(res.data)
          setState({
            ...state,
            message: "",
          });
          dispatch(ClientActions.getSupportList(props.clientID)).then(() => {
            scrollToMyRef();
          })
        }
      });
  };

  const scrollToMyRef = () => {
    const scroll =
      messageEl.current.scrollHeight - messageEl.current.clientHeight;
    console.log(
      scroll,
      "scroll",
      messageEl.current.scrollHeight,
      messageEl.current.clientHeight
    );
    messageEl.current.scrollTo(0, scroll);
  };

  const addEmoji = e => {
    setState({
      ...state,
      message: state.message + e.emoji
    })
  }

  return (
    <div className="client-support-chat-main">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between client-support-setting-wrapper">
        <div className="d-flex align-items-center justify-content-center client-support-chat-wrapper">
          <img src="" />
        </div>
      </div>

      <div className="client-support-chat-card">
        <div className="client-support-card-body">
          <div className="client-support-card-wrapper" ref={messageEl}>
            <Divider>Today</Divider>
            {support_list?.data?.messages?.map((chat) => {
              return chat?.sender_name !== "admin" ? (
                !chat?.requested_feature ?
                <div className="d-flex align-items-start flex-column client-support-admin-chat">
                  <div className="d-flex align-items-center admin-chat-wrapper">
                    <img className="img-fluid admin-img" src={SupportAvatar} />
                    <div className="d-flex flex-column admin-chat-message">
                      <span className="admin-name">{chat?.sender_name}</span>
                      <span className="admin-meassage">{chat?.message}</span>
                      {chat?.attachments?.length === 0
                        ? ""
                        : chat?.attachments?.map((attachment) => {
                            return (
                              <img
                                src={`https://d3u1baow9deq5h.cloudfront.net/${attachment}`}
                                style={{ maxWidth: 150 }}
                              />
                            );
                          })}
                    </div>
                  </div>
                  <span className="admin-chat-date mt-2">
                    {chat?.sender_name !== "admin" &&
                      moment(chat?.created_at).format("hh:mm A")}
                  </span>
                </div>:
                <div className="d-flex flex-column support-features-chat">
                <div className="d-flex flex-column features-chat-wrapper" style={{borderRadius: '15px 15px 15px 0px'}}>
                  <div className="features-settings d-flex align-items-center">
                    <SettingOutlined className="features-settings-icon" />
                    <span className="features-settings-text">
                      New feature request
                    </span>
                  </div>
                  <div className="d-flex flex-column features-chat-message">
                    <span className="features-name">
                      {chat?.feature_name}
                    </span>
                    <span className="features-meassage">
                      {chat?.feature_details}
                    </span>
                  </div>
                </div>
                <span className="d-flex align-items-center features-chat-date mt-2">
                  <span>{moment(chat?.created_at).format("hh:mm A")}</span>
                  <CheckOutlined />
                </span>
              </div>
              ) : (
                <div className="d-flex align-items-end flex-column client-support-user-chat">
                  <div className="d-flex align-items-center user-chat-wrapper">
                    <div className="d-flex flex-column user-chat-message">
                      <span className="user-meassage">{chat?.message}</span>
                      {chat?.sender_name === "admin" &&
                      chat?.attachments?.length === 0
                        ? ""
                        : chat?.attachments?.map((attachment) => {
                            return (
                              <img
                                src={`https://d3u1baow9deq5h.cloudfront.net/${attachment}`}
                                style={{ maxWidth: 150 }}
                              />
                            );
                          })}
                    </div>
                  </div>
                  <span className="d-flex align-items-center user-chat-date mt-2">
                    <span>
                      {chat?.sender_name === "admin" &&
                        moment(chat?.created_at).format("hh:mm A")}
                    </span>
                    <CheckOutlined />
                  </span>
                </div>
              );
            })}
          </div>

          <div className="client-support-card-meassage">
            <div className="d-flex align-items center client-support-meassage-wrapper">
              <div className="measssage-input">
                <input
                  className="input"
                  type="text"
                  name="message"
                  onChange={handleChange}
                  value={state?.message}
                />
                <img
                  className="meassage-icon"
                  src={SendIcon}
                  onClick={postSupport}
                  style={{cursor: 'pointer'}}
                />
              </div>

              <div className="d-flex align-items-center justify-conten-center chat-icons" style={{position: 'relative'}}>
                <SmileOutlined className="icons" style={{cursor: 'pointer'}} onClick={() => setEmoji(!emoji)}/>
                {emoji && <div style={{position: 'absolute', bottom: 70, right: -70}}>
                  <EmojiPicker onEmojiClick={addEmoji}/>
                </div>}
              </div>

              <div className="d-flex align-items-center justify-conten-center chat-files">
                <Upload
                  // onChange={(file) =>
                  //   onAttachmentSelect(file, file.file.originFileObj)
                  // }
                  accept="image/png,image/jpeg"
                  showUploadList={false}
                  onChange={(file) =>
                    onBannerSelect(file, file.file.originFileObj)
                  }
                  maxCount={1}
                  // {...propsUpload}
                >
                  <PaperClipOutlined className="files" />
                </Upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SupportChat);
