import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import LeadProfileIcon from "../../../../src/assets/images/inventive_byte/lead-profile-icon.png";
import ProfileUpload from "../../../assets/images/inventive_byte/profile-upload.png";
import EditIcon from "../../../assets/images/inventive_byte/edit-icon.svg";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import Location from "../../../assets/images/inventive_byte/location.png";
import Search from "../../../assets/images/inventive_byte/search.png";
import ProfileIcon from "../../../assets/images/inventive_byte/profile-icon.svg";
import "./Profile.scss";
import api from '../../../utils/api'
import { useSelector } from "react-redux";
import { Avatar, message, Spin, Upload } from "antd";
import Loader from "../../components/Loader/Loader";
import { UserOutlined } from "@ant-design/icons";
import CameraIcon from "../../../assets/images/Icons/CameraIcon";

const Profile = (props) => {
  const [loader, setLoader] = useState(false)
  const [profileLoader, setProfileLoader] = useState(false)
  const[imageLoader, setImageLoader] = useState(false)
  const [data, setData] = useState(null)
  const [activeBio, setActiveBio] = useState(false);
  const [activeLocation, setActiveLocation] = useState(false);
  const [mediaFile, setMediaFile] = useState(null)
  console.log(data,"data")
  const handleActiveBio = () => {
    setActiveBio(true);
  };

  const handleActiveLocation = () => {
    setActiveLocation(true);
  };

  const cancelActiveBio = () => {
    setActiveBio(false);
  };

  const cancelActiveLocation = () => {
    setActiveLocation(false);
  };

  useEffect(() => {
    setLoader(true)
    api.get(`${process.env.REACT_APP_API_URL}/admin/profile`)
      .then(res => {
        setLoader(false)
        if(res.result) {
          setData(res.data)
        }
      })
  },[])

  const onChange = (event, key) => {
    let newData = {...data}
    newData[key] = event.target.value;
    setData(newData)
  }

  console.log(mediaFile,"mediaFile")

  const onSubmit = (image) => {
    let payload = {
      bio: data?.bio,
      location: data?.location
    }
    if(image) {
      payload.profile_image = image
    }
    setProfileLoader(true)
    api.put(`${process.env.REACT_APP_API_URL}/admin/profile`, payload)
      .then(res => {
        setProfileLoader(false)
        if(res.result) {
          setActiveBio(false);
          setActiveLocation(false);
          message.success("Profile Updated Successfully!")
        }else {
          message.error(res.error)
        }
      })
  }

  const onChangeMedia = (file) => {
    console.log(file,"file")
    setImageLoader(true)
    if (file.file.status !== "uploading") {
      api
      .get(
        `${process.env.REACT_APP_API_URL}/admin/getPresignedUrl?key_type=admin_profile&file_name=${file.file.name}`
      )
      .then((data) => {
        setImageLoader(false)
        uploadFile(file.fileList, data?.data?.fields, data?.data?.url);
      })
      .catch((err) => console.log(err));
    } else if (file.file.status === "error") {
      message.error(`${file.file.name} file upload failed.`);
    }
    
  }

  const uploadFile = async (file, fields, uploadURL) => {
    console.log(file[0], "file[0]");
    const formData = new FormData();
    formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
    formData.append("key", fields?.key);
    formData.append("policy", fields?.policy);
    formData.append("signature", fields?.signature);
    formData.append("file", file[0].originFileObj);

    // Upload file to s3 bucket
    let request = new XMLHttpRequest();
    request.open("POST", uploadURL);
    request.send(formData)
    setData({...data, profile_image: fields?.key})
    onSubmit(fields?.key)
  };

  return (
    <div>
      {loader ? <Loader /> : 
      <div className="profile-view-main-wrapper">
        <div className="profile-header">
          <img src={ProfileIcon} />
          <h6>Profile</h6>
        </div>
        <div className="profile-card-main">
          <div className="profile-title-wrapper">
            <div className="d-flex flex-column flex-md-row align-items-center perk-profile">
              <div className="profile-img-wrapper">
                <div className="edit-lead-profile">
                  {imageLoader && <div style={{position: 'absolute', zIndex: 100, top: 60}}><Spin /></div>}
                  <Avatar icon={<UserOutlined />} src={`https://d3u1baow9deq5h.cloudfront.net/${data?.profile_image}`} />
                  <div className="cameraIcon">
                    <Upload onChange={(file) => onChangeMedia(file)}>
                      <CameraIcon />
                    </Upload>
                  </div>
                </div>
                <div className="user-info">
                  <h4>{data?.first_name + ' ' + data?.last_name}</h4>
                  <span>{data?.email}</span>
                </div>
              </div>
              <div className="profile-info-wrapper">
                {activeBio ? (
                  <div className="input-profile-wrapper">
                    <div className="input-fields feedback-name-wrapper flex flex-column">
                      <FormGroup className="feedback-form-group">
                        <Label className="feedback-label" for="website">
                          Bio
                        </Label>
                        <Input
                          className="feedback-textarea"
                          type="textarea"
                          name="feedback_detail"
                          id="feedback_detail"
                          onChange={(event) => onChange(event, 'bio')}
                          value={data?.bio}
                        />
                      </FormGroup>
                    </div>
                    <div className="feedback-buttons flex">
                      <div className="button-group flex align-items-center justify-content-center">
                        <Button className="button-save" onClick={() => onSubmit('')} disabled={profileLoader || data?.bio?.length < 5}>
                          {profileLoader ? <Spin /> : 'Save'}
                        </Button>
                        <Button
                          className="button-cancel"
                          onClick={cancelActiveBio}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="input-fields">
                    <Label className="feedback-label" for="website">
                      Bio
                    </Label>
                    <img src={EditIcon} onClick={handleActiveBio} />
                    <p>
                      {data?.bio}
                    </p>
                  </div>
                )}

                <div className="d-inline-flex justify-content-between flex-1 w-100">
                  <div className="location-wrapper d-flex w-100">
                    {activeLocation ? (
                      <div className="main-location w-100">
                        <FormGroup>
                          <Label className="yourself-lable" for="location">
                            Location
                          </Label>
                          <div className="form-group-location">
                            <img className="location-img" src={Location} />
                            <Input
                              className="location-input"
                              type="text"
                              name="location"
                              id="location"
                              onChange={(event) => onChange(event, 'location')}
                              value={data?.location}
                            />
                            <img className="location-search" src={Search} />
                          </div>
                        </FormGroup>{" "}
                        <div className="feedback-buttons flex">
                          <Button className="button-save" onClick={() => onSubmit('')} disabled={profileLoader}>
                            {profileLoader ? <Spin /> : 'Save'}
                          </Button>
                          <Button
                            className="button-cancel"
                            onClick={cancelActiveLocation}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="custom-supp flex-1">
                          <h6>Department</h6>
                          <p>support, Development</p>
                        </div>
                      </div>
                    ) : (
                      <div className="location-page d-flex flex-column flex-md-row flex-1">
                        <div className="location-wrap">
                          <div className="title">
                            <h5>Location</h5>
                            <img
                              src={EditIcon}
                              onClick={handleActiveLocation}
                            />
                          </div>
                          <p>{data?.location}</p>
                        </div>
                        <div className="custom-supp flex-1">
                          <h6>Department</h6>
                          <p>{data?.department?.name}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex flex-md-row flex-column project-permission-wrapper">
                  <div className="custom-title flex-1" style={{maxWidth: '50%'}}>
                    <h6>Projects</h6>
                    <p>{data?.projects?.map(item => item.name).join(", ")}</p>
                  </div>
                  <div className="custom-title flex-1" style={{maxWidth: '50%'}}>
                    <h6>Permissions</h6>
                    <p>{data?.permissions?.map(item => item.name).join(", ")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default withRouter(Profile);
