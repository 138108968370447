import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import LeadProfileIcon from "../../../../src/assets/images/inventive_byte/lead-profile-icon.png";
import "./ViewPerkDetail.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import * as PerkActions from "../../../redux/PerkFlowControl/PerkActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const ViewPerkDetail = (props) => {
  const dispatch = useDispatch()
  const { loading, perkDetail } = useSelector(state => state.PerkReducers)
  console.log(perkDetail,"perkDetail")
  
  console.log(props,"props")

  useEffect(() => {
    dispatch(PerkActions.getPerkDetail(props?.location?.state?.id))
  },[])

  return (
    <>
    {loading ? <Loader /> : 
    <div>

      <div className="perk-view-main-wrapper">
        <div className="header-left d-flex align-items-center justify-content-between">
          <span
            className="back-button flex align-items-center"
            onClick={() => props.history.goBack()}
            style={{cursor: 'pointer'}}
          >
            <ArrowLeftOutlined /> Back
          </span>
        </div>
        <div className="perk-card-main">
          <div className="perk-title-wrapper">
            <div className="d-flex flex-column flex-md-row align-items-center perk-profile">
              <div className="profile-img-wrapper">
              <img
                className="img-fluid perk-profile-img"
                src={`https://d3u1baow9deq5h.cloudfront.net/${perkDetail?.image}`}
              />
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-start profile-details">
                <span className="user-name">{perkDetail?.name}</span>
                <p className="profile-text">
                  {perkDetail?.description}
                </p>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center type-website-wrapper">
                  <div className="d-flex flex-column align-items-center align-items-md-start type-wrapper">
                    <span className="profile-label">Type</span>
                    <span className="value">{perkDetail?.perk_type}</span>
                  </div>
                  <div className="d-flex flex-column align-items-center align-items-md-start website-wrapper">
                    <span className="profile-label">Website</span>
                    <span className="website">{perkDetail?.website}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="add-perk-card d-flex flex-column align-items-center align-items-md-start">
            <span className="perk-replay">Deal Details</span>
            <p className="replay-text">
              {perkDetail?.details}
            </p>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default withRouter(ViewPerkDetail);
