import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import BlogProfile from "../../../../src/assets/images/inventive_byte/blog-profile.png";
import CancelIcon from "../../../../src/assets/images/inventive_byte/cancel-icon.png";
import "./TeamDetail.scss";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import * as TeamActions from "../../../redux/TeamFlowControl/TeamActions";
import LeftPanel from "../LeftPanel";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const TeamDetail = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { team_member_detail, department_list, project_list, permission_list, loading } =
    useSelector((state) => state.TeamReducers);

  const [state, setState] = useState({
    active: false,
    department: "",
    projects: [],
    permissions: [],
  });
  const [projectData, setProjectData] = useState([]);
  const [permissionData, setPermissionData] = useState([]);

  useEffect(() => {
    dispatch(TeamActions.getTeamMemberDetail(params?.id));
    dispatch(TeamActions.getDepartmentList());
    dispatch(TeamActions.getProjectList());
    dispatch(TeamActions.getPermissionList());
  }, []);

  console.log(team_member_detail,"team_member_detail")

  useEffect(() => {
    if(team_member_detail) {
      setState({
        ...state,
        active: team_member_detail?.active,
        department: team_member_detail?.department?.id,
        projects: team_member_detail?.projects,
        permissions: team_member_detail?.permissions,
      });
      setProjectData(team_member_detail?.projects?.map(item => item.id))
      setPermissionData(team_member_detail?.permissions?.map(item => item.id))
    }
    
  }, [team_member_detail]);

  const handleActive = (active) => {
    setState({ ...state, active: active });
  };

  const addDepartment = (id) => {
    setState({ ...state, department: id });
  };

  const addProjects = (ids) => {
    let data = state?.projects;
    if (state?.projects?.some((e) => e.id === ids.id)) {
      data = data.filter((itm) => itm.id !== ids.id);
    } else {
      data = [...data, ids];
    }
    const arrData = data?.map((x) => x.id);
    setState({ ...state, projects: [...data] });
    setProjectData(arrData);
  };

  const addPermissions = (ids) => {
    let data = state?.permissions;
    if (state?.permissions?.some((e) => e.id === ids.id)) {
      data = data.filter((itm) => itm.id !== ids.id);
    } else {
      data = [...data, ids];
    }
    const arrData = data?.map((x) => x.id);
    setState({ ...state, permissions: [...data] });
    setPermissionData(arrData);
  };

  const submitTeamDetail = async () => {
    const payload = {
      ...state,
      active: state?.active,
      department: state?.department,
      projects: projectData,
      permissions: permissionData
    }
    dispatch(await TeamActions.editTeamDetail(params?.id,payload))
    props.history.push('/inventive/team')
  }

  return (

    <>
      {loading ? <Loader /> : <div className="team-detail-main-layout">
        <div className="reading-material-detail-header">
          <div className="header-left">
            <span
              className="back-button"
              onClick={() => props.history.goBack()}
            >
              <ArrowLeftOutlined /> Back
            </span>
          </div>
        </div>
        <div className="blog-detail-body">
          <div className="blog-detail-container">
            <div className="blog-section">
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-4">
                <div className="user-detail flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row">
                  <div className="flex align-items-center flex-column flex-md-row">
                    <div className="user-icon">
                      <img src={BlogProfile} />
                    </div>
                    <div className="user-info flex align-items-center align-items-md-start flex-column ">
                      <h6>
                        {team_member_detail?.first_name}{" "}
                        {team_member_detail?.last_name}
                      </h6>
                      <p>{team_member_detail?.email}</p>
                    </div>
                  </div>

                  <div className="flex align-items-center">
                    <div className="btn-toggle-wrapper flex justify-content-end align-items-center">
                      <div
                        className="btn-group btn-toggle custom-team-toggle-button custom-switch"
                        data-toggle="buttons"
                      >
                        <label
                          className={`btn btn-default ${
                            state?.active && "active"
                          }`}
                          onClick={(title) => handleActive(true)}
                        >
                          Active
                        </label>
                        <label
                          className={`btn btn-default ${
                            !state?.active && "active"
                          }`}
                          onClick={(title) => handleActive(false)}
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-detail-context">
              <h5 className="team-department-text text-center text-md-left">
                Department
              </h5>
              <div className="text-center text-md-left">
                {department_list?.map((data) => {
                  return (
                    <Button
                      className={
                        state?.department === data?.id
                          ? "team-department-button-active"
                          : "team-department-button"
                      }
                      onClick={() => {
                        addDepartment(data?.id);
                      }}
                    >
                      {data?.name}{" "}
                      {state?.department === data?.id && (
                        <img src={CancelIcon} onClick="" />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div className="team-project-context">
              <h5 className="team-project-text text-center text-md-left">
                Project
              </h5>
              <div className="text-center text-md-left">
                {project_list?.map((data) => {
                  return (
                    <Button
                      className={
                        state?.projects?.some((e) => e.id === data?.id)
                          ? "team-project-button-active"
                          : "team-project-button"
                      }
                      onClick={() => {
                        addProjects(data);
                      }}
                    >
                      {data?.name}
                      {state?.projects?.some((e) => e.id === data?.id) && (
                        <img src={CancelIcon} />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div className="team-permissions-context">
              <h5 className="team-permission-text text-center text-md-left">
                Permissions
              </h5>
              <div className="text-center text-md-left">
                {permission_list?.map((data) => {
                  return (
                    <Button
                      className={
                        state?.permissions?.some((e) => e.id === data?.id)
                          ? "team-permission-button-active"
                          : "team-permission-button"
                      }
                      onClick={() => {
                        addPermissions(data);
                      }}
                    >
                      {data?.name}
                      {state?.permissions?.some((e) => e.id === data?.id) && (
                        <img src={CancelIcon} />
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="add-blog-buttons flex">
            <div className="button-group flex align-items-center justify-content-center">
              <Button className="button-save" onClick={submitTeamDetail}>Save</Button>
              <Button className="button-cancel" onClick={() => props.history.goBack()}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default withRouter(TeamDetail);
