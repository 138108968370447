import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './root-reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [thunkMiddleware]

// export const store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(...middlewares))
// );
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))

export const persistor = persistStore(store)
