import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { CheckOutlined, CloseOutlined, InfoOutlined } from "@ant-design/icons";
import ReferralIcon from "../../../../../assets/images/inventive_byte/referral-icon.svg";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import CrossIcon from "../../../../../assets/images/inventive_byte/review-cross-icon.svg";
import CompleteIcon from "../../../../../assets/images/inventive_byte/lead-active.png";
import InactiveIcon from "../../../../../assets/images/inventive_byte/lead-inactive.png";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./ReferralList.scss";
import moment from "moment";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const ReferralList = (props) => {
  const dispatch = useDispatch();
  const { referral_list, loading } = useSelector(
    (state) => state.ClientReducers
  );

  useEffect(() => {
    dispatch(ClientActions.getReferrals(props.clientID));
  }, []);

  useEffect(() => {}, [referral_list]);

  const editReferral = async (id, value) => {
    if (value === "approved") {
      await dispatch(ClientActions.editReferral(id, value));
      await dispatch(ClientActions.getReferrals(props.clientID));
    } else {
      await dispatch(ClientActions.editReferral(id, value));
      await dispatch(ClientActions.getReferrals(props.clientID));
    }
  };

  return (
    <div className="client-referral-main">
      <div className="client-referral-desktop-wrap">
        <div className="main-client-wrapper">
          <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
            <div className="th-data align-items-center">
              Referred Client Name{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Referral Email{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Sign Up Date{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data">
              Project Start Date{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data">
              Credit Value{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data flex justify-content-end">
              Action <span className="img-icon"></span>{" "}
            </div>
          </div>
        </div>
        <Spin className="client-referral-spin" spinning={loading}>
          {referral_list?.data?.length ? referral_list?.data?.map((data) => {
            return (
              <div className="table-body flex justify-content-start align-items-center">
                <div className="table-data">
                  <div className="username" onClick="">
                    <img className="referral-img" src={ReferralIcon} />
                    {data?.referred_to?.first_name} {data?.referred_to?.last_name}
                  </div>
                </div>
                <div className="table-data">
                  <div className="flex referral-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Referral Email
                    </label>
                    <div className="referral-email flex align-items-center">
                      {data?.referred_by?.email}{" "}
                      {data?.status === "approved" ? (
                        <span className="referral-checkout d-flex align-items-center justify-content-center"><CheckOutlined className="referral-checkout-icon" /></span>
                        // <img src={CompleteIcon} />
                      ) : (
                        <span className="referral-info d-flex align-items-center justify-content-center"><InfoOutlined className="referral-info-icon" /></span>
                        // <img src={CrossIcon} />
                      )}
                    </div>
                  </div>
                </div>

                <div className="table-data">
                  <div className="flex referral-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Sign Up Date
                    </label>
                    <div className="flex align-items-center">
                      <span className="sign-date">
                        {moment(data?.created_at).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-data">
                  <div className="flex referral-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Project Start Date
                    </label>
                    <div className="flex align-items-center">
                      <span className="start-date">{!data?.referred_to?.project ? 'N/A' : moment(data?.referred_to?.project).format('MM-DD-YYYY')}</span>
                    </div>
                  </div>
                </div>
                <div className="table-data">
                  <div className="flex referral-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Credit Value
                    </label>
                    <div className="flex align-items-center">
                      <span className="credit">${data?.credit_amount}</span>
                    </div>
                  </div>
                </div>

                <div className="table-data justify-content-center justify-content-lg-end">
                  <div className="d-flex justify-content-center justify-content-lg-end referral-card">
                    <div className="action-buttons d-flex">
                      {data?.status === "approved" ? (
                        <div className="btn-approved d-flex align-items-center">
                          <CheckOutlined className="btn-approved-icon" />
                          {/* <img
                            
                            src={CompleteIcon}
                            alt=""
                          /> */}
                          <span className="btn-approved-text">Approved</span>
                        </div>
                      ) : data?.status === "rejected" ? (
                        <div className="btn-rejected d-flex align-items-center">
                          <CloseOutlined className="btn-rejected-icon" />
                          {/* <img
                            
                            src={CrossIcon}
                            alt=""
                          /> */}
                          <span className="btn-rejected-text">Rejected</span>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <Button
                            className="button-approve"
                            onClick={() => {
                              editReferral(data?.id, "approved");
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            className="button-reject"
                            onClick={() => {
                              editReferral(data?.id, "rejected");
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }): 
            <NoDataFound />
          }
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(ReferralList);
