import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import "./InvoiceReport.scss";

const InvoiceReport = (props) => {
    return (
        <div className="invoice-report-wrapper">
            <div className="invoice-report-card">
                <div className="d-flex justify-content-between align-items-center invoice-report-header">
                    <h5 className="invoice-report-title">003</h5>
                    <img src={DownloadIcon} />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center invoice-report-body">
                    <div className="d-flex justify-content-between align-items-center invoice-date-wrapper w-100">
                        <div className="d-flex flex-column date-wrapper">
                            <span className="date-label"> Issue Date </span>
                            <span className="date"> 06/06/2019 </span>
                        </div>
                        <div className="d-flex flex-column date-wrapper w-40">
                            <span className="date-label">Due Date</span>
                            <span className="date">
                16/06/2019 <span className="due-date">-Due in 2 Days</span>
              </span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center w-100 mt-4">
                        <div className="d-flex flex-column">
                            <span className="label">For</span>
                            <b className="bold-text">Company Inc.</b>
                            <span className="text">2178 Washington Street</span>
                            <span className="text">Corpus Christi</span>
                            <span className="text">United States</span>
                        </div>
                        <div className="d-flex flex-column w-40">
                            <span className="label">From</span>
                            <b className="bold-text">UXDN Inc.</b>
                            <span className="text">2178 Washington Street</span>
                            <span className="text">Corpus Christi</span>
                            <span className="text">United States</span>
                        </div>
                    </div>

                    <table className="d-flex flex-column w-100 mt-5">

                        <thead className="table-thead">
                        <tr className="d-flex">
                            <th className="label table-th">Item</th>
                            <th className="label table-th">Weeks</th>
                            <th className="label table-th">Rate</th>
                            <th className="label table-th">Amount</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr className="d-flex">
                            <td className="table-td">UX Design services (Weekly Rate)</td>
                            <td className="table-td">2</td>
                            <td className="table-td">$2,000.00</td>
                            <td className="table-td">$4,000.00</td>
                        </tr>
                        </tbody>

                    </table>

                    <div className="d-flex justify-content-between w-100 mt-5">
                        <div className="d-flex flex-column justify-content-end">
                            <span className="label-que">Any questions?Please let us know</span>
                            <span className="label-email">invoice@uxdn.co</span>
                        </div>
                        <div className="w-40">
                            <h5 className="invoice-head">INVOICE SUMMARY</h5>
                            <div className="d-flex justify-content-between">
                                <span className="invoice-label">Subtotal</span>
                                <span className="invoice-text">$4,000.00</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="invoice-label">VAT 0%</span>
                                <span className="invoice-text">0.00</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="invoice-label">Total + VAT</span>
                                <span className="invoice-text">$4,000.00</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="invoice-label">Amount Due</span>
                                <span className="invoice-text">$4,000.00</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(InvoiceReport);
