import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import UpdateIcon from "../../../../../assets/images/inventive_byte/update-icon.svg";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./UpdateDetail.scss";
import moment from "moment";
import { Spin } from "antd";

const UpdateDetail = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { single_update, loading } = useSelector(
    (state) => state.ClientReducers
  );
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(ClientActions.getSingleUpdate(params?.id));
  }, []);

  useEffect(() => {}, [single_update]);

  const handleInvoiceReport = () => {
    setActive(true);
  };

  return (
    <div>
      <Spin className="update-spin" spinning={loading}>
        <div className="update-detail-wrapper">
          <div className="update-detail-main">
            <div className="header-left d-flex align-items-center justify-content-between">
              <span
                className="back-button flex align-items-center"
                onClick={() => props.history.goBack()}
              >
                <ArrowLeftOutlined /> Back
              </span>
            </div>
            <div className="update-main-wrapper">
              <div className="update-card-main">
                <div className="update-title-wrapper d-flex  align-items-center">
                  <img src={UpdateIcon} />
                  <div className="d-flex align-items-start flex-column ml-3">
                    <h5 className="update-title">
                      {single_update?.data?.name}
                    </h5>
                    <span className="update-title-date">
                      {moment(single_update?.data?.created_at).format(
                        "MM-DD-YYYY"
                      )}
                    </span>
                    {single_update?.data?.draft === false ? <span className="update-title-sent">sent</span> : <span className="update-title-draft">draft</span>}
                  </div>
                </div>
                <div className="add-update-card">
                  <p className="add-update-text">
                    {single_update?.data?.details}
                  </p>
                  {/* <div className="d-flex flex-column link-wrapper">
                    <a className="links" href="#">
                      https://www.google.co.in/
                    </a>
                    <a className="links" href="#">
                      https://www.linkedin.com/
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default withRouter(UpdateDetail);
