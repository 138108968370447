export const GET_USER_PERMISSION_INIT= 'GET_USER_PERMISSION_INIT'
export const GET_USER_PERMISSION_SUCCESS= 'GET_USER_PERMISSION_SUCCESS'
export const GET_USER_PERMISSION_FAILED= 'GET_USER_PERMISSION_FAILED'
export const GET_PROJECT_COUNT_INIT= 'GET_PROJECT_COUNT_INIT'
export const GET_PROJECT_COUNT_SUCCESS= 'GET_PROJECT_COUNT_SUCCESS'
export const GET_PROJECT_COUNT_FAILED= 'GET_PROJECT_COUNT_FAILED'
export const GET_MONTHLY_ACCOUNT_INIT= 'GET_MONTHLY_ACCOUNT_INIT'
export const GET_MONTHLY_ACCOUNT_SUCCESS= 'GET_MONTHLY_ACCOUNT_SUCCESS'
export const GET_MONTHLY_ACCOUNT_FAILED= 'GET_MONTHLY_ACCOUNT_FAILED'
export const GET_PENDING_AGREEMENT_INIT= 'GET_PENDING_AGREEMENT_INIT'
export const GET_PENDING_AGREEMENT_SUCCESS= 'GET_PENDING_AGREEMENT_SUCCESS'
export const GET_PENDING_AGREEMENT_FAILED= 'GET_PENDING_AGREEMENT_FAILED'
export const GET_REMINDER_INIT= 'GET_REMINDER_INIT'
export const GET_REMINDER_SUCCESS= 'GET_REMINDER_SUCCESS'
export const GET_REMINDER_FAILED= 'GET_REMINDER_FAILED'