import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { message, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import TeamIcon from "../../../../../assets/images/inventive_byte/lead-icon.png";
import AddTeamIcon from "../../../../../assets/images/inventive_byte/add-team.svg";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import SearchIcon from "../../../../../assets/images/inventive_byte/search.png";
import FilterIcon from "../../../../../assets/images/inventive_byte/filter-icon.png";
import CancelIcon from "../../../../../assets/images/inventive_byte/cancel-icon.png";
import InfoIcon from "../../../../../assets/images/inventive_byte/info-icon.png";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import * as TeamActions from "../../../../../redux/TeamFlowControl/TeamActions";
import api from "../../../../../utils/api";
import "./AddTeamMates.scss";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const AddTeamMates = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { add_team_list, loading } = useSelector(
    (state) => state.LeadReducers
  );
  const { department_list, project_list, team_member_list } = useSelector(
    (state) => state.TeamReducers
  );

  useEffect(() => {
    dispatch(LeadActions.getAddTeamMemberList(params?.id));
    dispatch(TeamActions.getDepartmentList());
    dispatch(TeamActions.getProjectList());
    dispatch(TeamActions.getTeamMemberList());
  }, []);

  useEffect(() => {}, [
    add_team_list,
    department_list,
    project_list,
    team_member_list,
  ]);

  const [activeDetail, setActiveDetail] = useState(false);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [activeProfile, setActiveProfile] = useState(false);
  const [selectedParams, setSelectedParams] = useState([]);
  const [state, setState] = useState({
    members: [],
  });

  const handleProfileClick = () => {
    setActiveProfile(!activeProfile);
  };

  const handleActiveDetailClick = () => {
    setActiveDetail(true);
  };

  const handleFilter = async (e, label) => {
    let newParams = { label: label, data: e.target.value };
    let parameters = [...selectedParams, newParams];
    setSelectedParams(parameters);
    dispatch(await LeadActions.getAddTeamFilterList(params?.id,parameters));
  };

  const selectTeamMember = (id) => {
    let data = state?.members || [];
    if (state?.members?.includes(id)) {
      data = data.filter((itm) => itm !== id);
    } else {
      data = [...data, id];
    }
    setState({ ...state, members: [...data] });
  };

  const addTeamMember = async () => {
    await api
      .post(
        `${process.env.REACT_APP_API_URL}/admin/addTeamMember/${params?.id}`,
        state
      )
      .then((res) => {
        if (res.data) {
          message.success(res.data);
          dispatch(LeadActions.getAddTeamMemberList(params?.id));
          props.history.goBack();
        }
      });
  };

  return (
    <div className="team-master-main">
      <div className="team-master-wrapper">
        <div className="add-team-master-main">
          <div className="team-master-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-row">
            <div className="team-icon-wrapper flex align-items-center justify-content-start">
              <img className="team-icon" src={AddTeamIcon} />{" "}
              <span className="team-icon-text">Select Project Teammates</span>
            </div>

            <div className="flex d-none d-md-none d-lg-flex">
              <div className="btn-team-wrapper">
                <div className="custom-toggle-button d-flex justify-content-center">
                  <input
                    type="number"
                    className="team-select"
                    placeholder="No. Assigned Projects"
                    onChange={(e) => {
                      handleFilter(e, "project_count");
                    }}
                  />
                  <select
                    className="team-select"
                    onChange={(e) => {
                      handleFilter(e, "department");
                    }}
                  >
                    {department_list?.map((data) => {
                      return <option value={data?.id}>{data?.name}</option>;
                    })}
                  </select>
                  <select
                    className="team-select"
                    onChange={(e) => {
                      handleFilter(e, "project");
                    }}
                  >
                    {project_list?.map((data) => {
                      return <option value={data?.id}>{data?.name}</option>;
                    })}
                  </select>
                  <select
                    className="team-select"
                    onChange={(e) => {
                      handleFilter(e, "status");
                    }}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                  <div className="team-input-wrapper">
                    <img className="team-input-img" src={SearchIcon} />
                    <input
                      className="team-input"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        handleFilter(e, "search");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add-team-desktop-wrap">
            <div className="main-team-wrapper">
              <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                <div className="th-data d-none d-lg-flex">
                  <input
                    className="add-team-head-checkbox"
                    type="checkbox"
                    name="checkteam"
                  />
                  <p className="checkmark"></p>
                </div>
                <div className="th-data">
                  Full Name{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>
                </div>
                <div className="th-data">
                  Email Address{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data">
                  Department{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data">
                  Assigned Projects{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data justify-content-center">
                  Status{" "}
                  <span className="img-icon">
                    <img src="" />{" "}
                  </span>{" "}
                </div>
              </div>
            </div>
            {loading && <div className="blog-main"><div className="blog-wrapper"><div className="investor-desktop-wrap"><Spin className="investor-spin" size="large" /></div></div></div>}
            {add_team_list?.data?.length ? add_team_list?.data?.map((data) => {
              let projectData = "";
              let permissionData = "";
              data?.projects?.map((p) => {
                projectData += p.name + ", ";
              });
              data?.permissions?.map((p) => {
                permissionData += p.name + ", ";
              });
              let projectTooltip = projectData.slice(0, -2);
              let permissionTooltip = permissionData.slice(0, -2);

              return (
                <div className="table-body flex justify-content-start align-items-center ">
                  <div className="table-data order-0 order-lg-0 d-none d-lg-flex">
                    <input
                      type="checkbox"
                      className="add-team-checkbox"
                      name="members"
                      onChange={() => {
                        selectTeamMember(data?.id);
                      }}
                    />
                    <p className="checkmark"></p>
                  </div>
                  <div className="table-data order-1 order-lg-1">
                    <div
                      className="username d-flex align-items-center"
                      onClick={handleActiveDetailClick}
                    >
                      <p className="d-flex align-items-center justify-content-center user-icon">
                        {data?.first_name?.charAt(0).toUpperCase()}
                      </p>
                      <p className="user-name">
                        {data?.first_name} {data?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="table-data order-3 order-lg-2">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Email Address
                      </label>
                      <div className="email flex align-items-center">
                        <Tooltip
                          className="department-tooltip"
                          placement="top"
                          title={data?.email}
                        >
                          <p className="email-texl">{data?.email}</p>
                        </Tooltip>
                        <img src="" />
                      </div>
                    </div>
                  </div>
                  <div className="table-data order-4 order-lg-3">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Department
                      </label>
                      <Tooltip
                        className="department-tooltip"
                        placement="right"
                        title={permissionTooltip}
                      >
                        <div className="job">
                          {data?.department?.name}{" "}
                          {permissionTooltip === "" ? (
                            ""
                          ) : (
                            <img src={InfoIcon} />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="table-data order-5 order-lg-4">
                    <div4d4d4 className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Assigned Projects
                      </label>
                      <Tooltip
                        className="department-tooltip"
                        placement="right"
                        title={projectTooltip}
                      >
                        <div className="project">{data?.project_count}</div>
                      </Tooltip>
                    </div4d4d4>
                  </div>
                  <div className="table-data order-2 order-lg-5 flex justify-content-center">
                    <div className="flex blog-card justify-content-center">
                      {data?.active === true ? (
                        <Button className="blog-button-active">Active</Button>
                      ) : (
                        <Button className="blog-button-inactive">
                          Inactive
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            }):<NoDataFound />}
          </div>

          {!loading && add_team_list?.data?.length ? <div className="flex align-items-baseline justify-content-center team-buttons">
            <Button className="btn-cancel" onClick={() => props.history.goBack()}>Cancel</Button>
            <Button className="btn-add" onClick={addTeamMember} disabled={state?.members?.length ? false : true}>
              Add
            </Button>
          </div>:null}
        </div>
      </div>
      <div className="team-filter-main d-block d-lg-none">
        {/* <div className="team-filter-wrapper">
          <Dropdown isOpen={activeProfile} toggle={handleProfileClick}>
            <DropdownToggle className="team-filter-img">
              <img src={FilterIcon} />
            </DropdownToggle>
            <DropdownMenu className="team-filter-menu">
              <DropdownItem>
                <div className="flex justify-content-between align-items-center team-filter-cancel">
                  <h4 className="team-filter-text"> Filter </h4>
                  <img src={CancelIcon} className="img-fluid team-cancel" />
                </div>
                <div className="team-dashboard-button flex justify-content-center align-items-center d-block d-md-block d-lg-none">
                  <div className="team-input-wrapper flex justify-content-center align-items-center">
                    <div className="d-flex w-100 mobile-filter">
                      <select
                        className="flter-team-select"
                        name="dept"
                        id="dept"
                      >
                        <option value="">Department</option>
                        <option value="admin">admin</option>
                        <option value="development">development</option>
                      </select>
                      <select
                        className="flter-team-select"
                        name="status"
                        id="status"
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <select
                      className="flter-team-select"
                      name="Projects"
                      id="projects"
                    >
                      <option value="">No. Assigned Projects</option>
                    </select>

                    <select
                      className="flter-team-select"
                      name="point"
                      id="points"
                    >
                      <option value="">Assigned Projects Gravity Points</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>

                    <div className="filter-team-input-wrapper">
                      <img className="team-input-img" src={SearchIcon} />
                      <input
                        className="team-input"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                  </div>

                  <Button className="team-apply-btn">Apply</Button>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div> */}
      </div>
    </div>
  );
};

export default withRouter(AddTeamMates);
