import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  client_list: {},
  update_list: [],
  single_update: {},
  review_list: [],
  referral_list: [],
  feedback_list: [],
  single_feedback: {},
  single_client: {},
  support_list: {},
};

const ClientReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        client_list: action.payload,
      };
    case actionTypes.GET_CLIENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_CLIENT_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        single_client: action.payload,
      };
    case actionTypes.GET_CLIENT_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_UPDATE_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        update_list: action.payload,
      };
    case actionTypes.GET_UPDATE_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SINGLE_UPDATE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SINGLE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        single_update: action.payload,
      };
    case actionTypes.GET_SINGLE_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_REVIEWS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        review_list: action.payload,
      };
    case actionTypes.GET_REVIEWS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_REFERRALS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
        referral_list: action.payload,
      };
    case actionTypes.GET_REFERRALS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_FEEDBACKS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        feedback_list: action.payload,
      };
    case actionTypes.GET_FEEDBACKS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SINGLE_FEEDBACK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SINGLE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        single_feedback: action.payload,
      };
    case actionTypes.GET_SINGLE_FEEDBACK_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SUPPORT_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SUPPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        support_list: action.payload,
      };
    case actionTypes.GET_SUPPORT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default ClientReducers;
