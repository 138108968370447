import React, { useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import InventiveLogo from "../../../src/assets/images/inventive_byte/inventive-logo.png";
import "./CreatePassword.scss";
import { IsValidForm } from "../../utils/validations";
import * as AdminLoginActions from "../../redux/AdminLoginFlowControl/AdminLoginActions";
import { Input, message } from "antd";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    new_password: "",
    confirm_password: "",
  });

  const handleEventChange = (e) => {
    let newErrors = { ...errors };
    if (e.target.name === "confirm_password") {
      console.log(state?.new_password, e.target.value);
      if (state?.new_password !== e.target.value) {
        newErrors[e.target.name] =
          "New Password and Confirm Password Not Matched";
      } else {
        newErrors[e.target.name] = "";
      }
    } else {
      newErrors[e.target.name] = "";
    }
    setErrors(newErrors);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleLoginSubmit = async () => {
    let token = location?.search?.split("token=")?.slice(-1)[0];
    const data = {
      ...state,
      token,
    };
    let fields = ["new_password", "confirm_password"];
    let formValidation = IsValidForm(fields, data);

    setErrors(formValidation.errors);
    if (formValidation?.validate && token) {
      dispatch(
        AdminLoginActions.adminResetPassword(
          data,
          () => {
            message.success("Password reset successfully");
            props.history.push("/");
          },
          (err) => {
            message.error(err || "Failed to Reset Password.");
          }
        )
      );
    }
  };

  return (
    <div>
      <div className="forgot-password-wrapper">
        <img className="admin-login-img" src={InventiveLogo} />
        <Modal.Dialog className="forgot-password-modal">
          <Modal.Header className="forgot-password-head-main">
            <div className="text-center flex justify-content-center flex-column">
              <h3 className="create-password">Reset Password</h3>
              <h5 className="forgot-password-text">
                Please set a strong password that you have not used before.
              </h5>
            </div>
          </Modal.Header>

          <Modal.Body className="forgot-password-body">
            <Form className="forgot-password-form">
              <FormGroup>
                <Label className="forgot-password-lable" for="new_password">
                  New Password (Must be at least 8 characters)
                </Label>
                <div className="forgot-password-formgroup">
                  <Input.Password
                    className="forgot-password-input"
                    type="password"
                    name="new_password"
                    onChange={handleEventChange}
                    value={state?.new_password}
                    placeHolder="Enter password"
                  />
                  {errors?.new_password ? (
                    <p className="validation-eror">{errors?.new_password}</p>
                  ) : null}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="forgot-password-lable" for="confirm_password">
                  Confirm password (Both passwords must match)
                </Label>
                <div className="forgot-password-formgroup">
                  <Input.Password
                    className="forgot-password-input"
                    type="password"
                    name="confirm_password"
                    onChange={handleEventChange}
                    value={state?.confirm_password}
                    placeHolder="Enter confirm password"
                  />
                  {errors?.confirm_password ? (
                    <p className="validation-eror">
                      {errors?.confirm_password}
                    </p>
                  ) : null}
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer className="forgot-password-footer">
            <Button
              className="button-signin"
              variant="primary"
              onClick={handleLoginSubmit}
            >
              Sign Up
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
