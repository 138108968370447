import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import EmailIcon from '../../../../assets/images/inventive_byte/verification-email.svg'
import ProfileUpload from '../../../../assets/images/inventive_byte/profile-upload.png'
import './VerifyEmail.scss'

const VerifyEmail = props => {
    return (
        <div className="verify-email-wrapper d-flex flex-column align-items-center justify-content-center">
           <img src={EmailIcon} style={{width: 100}}/>
           <span className="verify-email-text">A verification email has been sent to verify the updated email address.</span>
        </div>
    );
}

export default withRouter(VerifyEmail)
