import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  team_member_list: [],
  department_list: [],
  project_list: [],
  invited_team_member_list:[],
  permission_list: [],
  team_member_detail: null
};

const TeamReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEAM_MEMBERS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        team_member_list: action.payload,
      };
    case actionTypes.GET_TEAM_MEMBERS_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_TEAM_MEMBERS_DETAIL_INIT:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.GET_TEAM_MEMBERS_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          team_member_detail: action.payload,
        };
      case actionTypes.GET_TEAM_MEMBERS_DETAIL_FAILED:
        return {
          ...state,
          loading: false,
        };
    case actionTypes.GET_DEPARTMENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        department_list: action.payload,
      };
    case actionTypes.GET_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PROJECT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project_list: action.payload,
      };
    case actionTypes.GET_PROJECT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVITED_TEAM_MEMBERS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVITED_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        invited_team_member_list: action.payload,
      };
    case actionTypes.GET_INVITED_TEAM_MEMBERS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PERMISSION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permission_list: action.payload,
      };
    case actionTypes.GET_PERMISSION_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default TeamReducers;
