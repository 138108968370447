import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import TeamIcon from "../../../../src/assets/images/inventive_byte/team-icon.png";
import InviteTeamIcon from "../../../../src/assets/images/inventive_byte/invite-team-icon.png";
import BlogName from "../../../../src/assets/images/inventive_byte/blog-name.png";
import InfoIcon from "../../../../src/assets/images/inventive_byte/info-icon.png";
import DropDownIcon from "../../../../src/assets/images/inventive_byte/drop-down-icon.png";
import TeamDetail from "./TeamDetail";
import InviteTeamMember from "./InviteTeamMember";
import * as TeamActions from "../../../redux/TeamFlowControl/TeamActions";
import "./InviteTeamMemberList.scss";
import { message, Spin } from "antd";

const InviteTeamMemberList = (props) => {
  const dispatch = useDispatch()
  const {invited_team_member_list, loading} = useSelector(state => state.TeamReducers)
  const [activeDetail, setActiveDetail] = useState(false);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [activeProfile, setActiveProfile] = useState(false);
  const [activeInviteTeam, setActiveInviteTeam] = useState(false);
  const [isLoading, setLoading] = useState(false)

  useEffect(()=>{
    dispatch(TeamActions.getInvitedTeamMemberList())   
  },[])

  useEffect(()=>{},[invited_team_member_list])

  const handleResendInvitation = (id) => {
    setLoading(id)
    dispatch(TeamActions.resendInvitation(
      id,
      (res) => {
        setLoading(false)
        message.success("Blog updated successfully");
      },
      (err) => {
        setLoading(false)
        message.error(err || "Failed to update blog.");
      }
    ));
  }

  const handleProfileClick = () => {
    setActiveProfile(!activeProfile);
  };

  const handleInviteTeamClick = () => {
    // setActiveInviteTeam(true);
    props.history.push("/inventive/invite-team");
  };

  const handleChange = (pagination, filter, sorter) => {
    console.log("Various parameters", pagination, filter, sorter);
    setSortedInfo(sorter);
  };
  const handleActiveDetailClick = () => {
    setActiveDetail(true);
  };

  return (
    <>
      <div className="team-tabs-main invite-team-member-main">
        
          <div className="tabs-wrapper blog-wrapper">
            <div className="blog-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
              <div className="blog-icon-wrapper flex align-items-center justify-content-start">
                <img className="blog-icon" src={TeamIcon} />{" "}
                <span className="blog-icon-text">Team Management</span>
              </div>

              <div className="flex">
                <div>
                  <Button
                    className="blog-button"
                    onClick={handleInviteTeamClick}
                  >
                    <img className="blog-button-img" src={InviteTeamIcon} />
                    Invite team
                  </Button>
                </div>
              </div>
            </div>
            <div>{/*<TeamTabs/>*/}</div>
            <div className="team-desktop-wrap tab-desktop-wrap">
            {loading && <Spin className="investor-spin" size="large" />}
              <div className="main-table-wrapper">
                <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                  <div className="th-data">
                    Members Name{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Email Address{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Department{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Projects{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Invited Date{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data justify-content-center">
                    Action{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                </div>
              </div>
              {invited_team_member_list?.map((data)=>{
                return (
                  <div className="table-body flex justify-content-start align-items-center">
                  <div className="table-data order-1 order-lg-1">
                    <div className="d-flex align-items-center justify-content-center username" onClick={handleActiveDetailClick}>
                    <p className="d-flex align-items-center justify-content-center user-icon">{data?.first_name?.charAt(0).toUpperCase()}</p>
                    <p className="user-name"> {data?.first_name} {data?.last_name} </p>
                    </div>
                  </div>
                  <div className="table-data order-2 order-lg-2">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Email Address
                      </label>
                      <div className="technology">{data?.email}</div>
                    </div>
                  </div>
                  <div className="table-data order-3 order-lg-3">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Department
                      </label>
                      <div className="job">
                        {data?.department?.name} <img src={InfoIcon} />
                      </div>
                    </div>
                  </div>
                  <div className="table-data order-4 order-lg-4">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Projects
                      </label>
                      <div className="project">{data?.projects?.length}</div>
                    </div>
                  </div>
                  <div className="table-data order-5 order-lg-5">
                    <div className="flex blog-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Invited Date
                      </label>
                      <div className="job">{moment(data?.invited_at).format('DD-MM-YYYY')}</div>
                    </div>
                  </div>
                  <div className="table-data order-6 order-lg-6 justify-content-center">
                    <div className="flex justify-content-center blog-card">
                      <div className="flex">
                        <Button
                            className="blog-button"
                            onClick={()=>{handleResendInvitation(data?.id)}}
                        >
                          <img
                              className="blog-button-img"
                              src={InviteTeamIcon}
                          />
                          {isLoading === data?.id ? "Loading..." : "Resend Invite"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                )
              })}
            </div>

          </div>
      </div>
    </>
  );
};

export default withRouter(InviteTeamMemberList);
