import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'react-bootstrap'
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import BlogPause from '../../../../src/assets/images/inventive_byte/blog-pause.png'
import BlogEdit from '../../../../src/assets/images/inventive_byte/blog-edit.png'
import BlogDelete from '../../../../src/assets/images/inventive_byte/blog-delete.png'
import BlogProfile from '../../../../src/assets/images/inventive_byte/blog-profile.png'
import BlogDetail1 from '../../../../src/assets/images/inventive_byte/blog-detail1.png'
import BlogDetail2 from '../../../../src/assets/images/inventive_byte/blog-detail2.png'
import LinkedInIcon from '../../../../src/assets/images/inventive_byte/linkedin-icon.png'
import TwitterIcon from '../../../../src/assets/images/inventive_byte/twitter-icon.png'
import FBIcon from '../../../../src/assets/images/inventive_byte/fb-icon.png'
import * as BlogActions from "../../../redux/BlogFlowControl/BlogActions";
import './BlogDetails.scss'

const BlogDetails = props => {
  const { loading } = useSelector((state) => state.BlogReducers);
  return (
    <>
      <div className="blog-detail-main-layout">
        <div className="reading-material-detail-header">
          <div className="header-left">
            <span className="back-button" onClick={() => props.history.goBack()}>
              <ArrowLeftOutlined /> Back
            </span>
          </div>
        </div>
        <div className="blog-detail-body">
        {loading && <Spin className="investor-spin" size="large" />}
          <div className="d-flex justify-content-end mobile-sticky">
           {props?.blogData?.draft === false ? <Button className="mark-btn">Move to Draft</Button> : ''} 
            <img className="mr-3" src={BlogPause} />
            <img className="mr-3" src={BlogEdit} />
            <img src={BlogDelete} />
          </div>
          <div className="blog-detail-container">
              <div className="blog-content-wrapper">
              <h5>{props?.blogData?.category}</h5>
              <p className="body-header-text">
                {props?.blogData?.title}
              </p>
               {props?.blogData?.active ? <Button className="btn-active">Active</Button> : <Button className="btn-inactive">Inactive</Button>} 
            </div>

            <div className="blog-section">
              <h5>{props?.blogData?.sub_title}</h5>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-4">
              <div className="user-detail">
                <div className="user-icon">
                  <img src={`https://d3u1baow9deq5h.cloudfront.net/`+props?.blogData?.profile_image} height="64px" width="64px"/>
                </div>
                <div className="user-info">
                  <h6>{props?.blogData?.author_first_name} {props?.blogData?.author_last_name}</h6>
                  <p>{moment(props?.blogData?.publish_date).format('DD-MM-YYYY')}</p>
                </div>
              </div>
              <div className="social-links">
                <img className="mr-2" src={LinkedInIcon}/>
                <img className="mr-2"   src={TwitterIcon}/>
                <img src={FBIcon}/>

              </div>
            </div>
            </div>
            <div className="blog-detail-context" dangerouslySetInnerHTML={{ __html: props?.blogData?.content }}>
            </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(BlogDetails)
