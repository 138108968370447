import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import LeadProfileIcon from "../../../../../assets/images/inventive_byte/lead-profile-icon.png";
import EditIcon from "../../../../../assets/images/inventive_byte/edit-icon.svg";
import { Rate, Spin } from "antd";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import "./ViewFeedBack.scss";
import moment from "moment";

const ViewFeedBack = (props) => {
  const dispatch = useDispatch();
  const { single_feedback, loading, single_client } = useSelector(
    (state) => state.ClientReducers
  );
  useEffect(() => {
    // dispatch(ClientActions.getClientDetail(props?.clientID));
    // dispatch(ClientActions.getSingleFeedback(props?.feedbackID));
  }, []);
  // useEffect(() => {}, [single_feedback, single_client]);
  // const [active, setActive] = useState(false);

  // const handleInvoiceReport = () => {
  //   setActive(true);
  // };

  return (
    <div>
      <div className="feedback-view-main-wrapper">
        <Spin spinning={loading}>
          <div className="feedback-card-main">
            <div className="feedback-title-wrapper">
              <div className="d-flex flex-column flex-md-row align-items-center feedback-profile">
                <img
                  className="img-fluid feedback-profile-img"
                  src={LeadProfileIcon}
                />
                <div className="d-flex flex-column align-items-center align-items-md-start profile-details">
                  <span className="user-name">
                    {single_feedback?.data?.client?.first_name}{" "}
                    {single_feedback?.data?.client?.last_name}
                  </span>
                  <span className="project-name">
                    Project Name :
                    <span className="project">
                      {single_client?.data?.project?.name}
                    </span>
                  </span>
                  <span className="date">
                    {moment(single_feedback?.data?.created_at).format(
                      "MM-DD-YYYY"
                    )}
                  </span>
                </div>
                <Rate
                  className="feedback-rate"
                  allowHalf
                  defaultValue={single_feedback?.data?.rating}
                />
              </div>
              <p className="feedback-profile-text">
                {single_feedback?.data?.feedback}
              </p>
            </div>
            <div className="add-feedback-card d-flex flex-column">
              <span className="feedback-replay">Reply</span>
              <span className="feedback-replay-date">
                Reply Date:{" "}
                <span className="replay-date">
                  {moment(single_feedback?.data?.reply_date).format(
                    "MM-DD-YYYY"
                  )}
                </span>
              </span>
              <p className="replay-text">{single_feedback?.data?.reply}</p>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(ViewFeedBack);
