import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const getUserPermissions = (onSuccess) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_USER_PERMISSION_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/user`)
        .then(res => {
          if(res.result) {
            dispatch({
              type: actionTypes.GET_USER_PERMISSION_SUCCESS,
              payload: res.data?.permissions.map(item => item?.name),
              user: res.data
            })
            if(onSuccess) {
              onSuccess()
            }
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_USER_PERMISSION_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectCounts = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_COUNT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projectCount`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PROJECT_COUNT_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_COUNT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getMonthlyAccounts = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_MONTHLY_ACCOUNT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/monthlyAccount`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_MONTHLY_ACCOUNT_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_MONTHLY_ACCOUNT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getPendingAgreements = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PENDING_AGREEMENT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/pendingAgreements`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PENDING_AGREEMENT_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PENDING_AGREEMENT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const sendReminder = (id, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_REMINDER_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/agreementReminder/${id}`)
        .then(res => {
          if(res.result) {
            dispatch({
              type: actionTypes.GET_REMINDER_SUCCESS,
              payload: res.data,
            })
            onSuccess(res.data)
          }else {
            dispatch({
              type: actionTypes.GET_REMINDER_FAILED,
            })
            onFail(res.error)
          }
          
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_REMINDER_FAILED,
          })
          onFail(error.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }