import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import ProfileUpWhite from '../../../src/assets/images/inventive_byte/profile-white.png'
import ProfileUpload from '../../../src/assets/images/inventive_byte/profile-upload.png'
import Location from '../../../src/assets/images/inventive_byte/location.png'
import Search from '../../../src/assets/images/inventive_byte/search.png'
import NextArrow from '../../../src/assets/images/inventive_byte/next-arrow.png'
import './AboutSelf.scss'

const AboutSelf = props => {

    return (
        <>
        <div>
            <div className="yourself-main">
                <div className="flex align-items-center justify-content-center flex-column yourself-wrapper">
                    <div className="flex align-items-center justify-content-center yourself-head">
                        <img className="yourself-items-img" src={ProfileUpWhite}/>
                        <span className="yourself-head-text">Please share a few things about yourself</span>
                    </div>
                    <div className="yourself-profile">
                        <img src={ProfileUpload}/>
                    </div>
                    <div className="yourself-formgroup">
                        <Form>
                            <FormGroup>
                                <Label className="yourself-lable" for="location">Location</Label>
                                <div className="form-group-location">
                                    <img className="location-img" src={Location}/>
                                    <Input className="location-input" type="text" name="location" id="location" onChange="" value="" onBlur="" />
                                    <img className="location-search" src={Search}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label className="yourself-lable" for="bio">Share your Bio</Label>
                                <Input className="yourself-textarea" type="textarea" name="bio" id="bio" onChange="" value="" onBlur="" />
                            </FormGroup>
                            <FormGroup>
                                <div className="flex align-items-center justify-content-center">
                                    <Button className="yourself-button">Next <img className="yourself-button-img" src={NextArrow}/></Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default withRouter(AboutSelf)
