import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const adminLogin = (data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.LOGIN_INIT,
      })
      await api
        .postWithoutToken(`${process.env.REACT_APP_API_URL}/admin/checkLogin`, data)
        .then(res => {
          if(res.result) {
            console.log(113)
            dispatch({
              type: actionTypes.LOGIN_SUCCESS,
              payload: res,
            })
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          dispatch({
            type: actionTypes.LOGIN_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const adminCreatePassword = (data, onSuccess, onFail) => {
    return async dispatch => {
      await api
        .postWithoutToken(`${process.env.REACT_APP_API_URL}/admin/createPassword`, data)
        .then(res => {
          if(res.result) {
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const adminResetPassword = (data, onSuccess, onFail) => {
    return async dispatch => {
      await api
        .postWithoutToken(`${process.env.REACT_APP_API_URL}/admin/resetPassword`, data)
        .then(res => {
          if(res.result) {
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const adminForgotPassword = (data, onSuccess, onFail) => {
    return async dispatch => {
      await api
        .postWithoutToken(`${process.env.REACT_APP_API_URL}/admin/forgotPassword`, data)
        .then(res => {
          if(res.result) {
            console.log(113)
            onSuccess();
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const changePassword = (data) => {
    return async dispatch => {
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/perks`, data)
        .then(res => {
          
          return
        })
        .catch(error => {
          throw error?.response?.data?.error || error?.message
        })
    }
}


  