import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { DatePicker, message, Spin, Upload } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import UploadIcon from "../../../../src/assets/images/inventive_byte/upload-icon.png";
import "./AddPerk.scss";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import { ArrowLeftOutlined } from "@ant-design/icons";
import api from '../../../utils/api'
import * as PerkActions from "../../../redux/PerkFlowControl/PerkActions";
import { useDispatch, useSelector } from "react-redux";


const AddPerk = (props) => {
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.PerkReducers)
  const [data, setData] = useState({name: '', website: '', perk_type: 'HIGH_VALUE', description: '', details: '', draft: false, image: ''})
  const [loader, setLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [draftLoader, setDraftLoader] = useState(false)
  const onChange = (event) => {
    let newData = {...data}
    newData[event.target.name] = event.target.value;
    setData(newData)
  }

  const onChangeType = (type) => {
    let newData = {...data}
    newData["perk_type"] = type;
    setData(newData)
  }
  
  useEffect(() => {
    if(props?.location?.state?.data) {
      let { name, website, perk_type, description, details, draft, image } = props?.location?.state?.data
      setData({name, website, perk_type, description, details, draft, image})
    }
  },[props?.location?.state?.data?.id])

  const props1 = {
    name: "file",
    multiple: true,
    accept: ".png, .jpeg, .jpg",
    onChange(info) {
      const { status } = info.file;
      setLoader(true)
      if (status !== "uploading") {
        api
          .get(
            `${process.env.REACT_APP_API_URL}/admin/getPresignedUrl?key_type=perks&file_name=${info.file.name}`
          )
          .then((data) => {
            setLoader(false)
            uploadFile(info.fileList, data?.data?.fields, data?.data?.url);
          })
          .catch((err) => console.log(err));
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const uploadFile = (file, fields, uploadURL) => {
    console.log(file[0], "file[0]");
    const formData = new FormData();
    formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
    formData.append("key", fields?.key);
    formData.append("policy", fields?.policy);
    formData.append("signature", fields?.signature);
    formData.append("file", file[0].originFileObj);

    // Upload file to s3 bucket
    let request = new XMLHttpRequest();
    request.open("POST", uploadURL);
    request.send(formData)
    setData({...data, image: fields?.key})
  };

  const onSubmit = (draft) => {
    let payload = {...data}
    payload.draft = draft;
    
    if(payload?.website) {
      let valid = checkValidUrl.test(payload?.website)
      if(!valid) {
        return message.warn("Please enter a valid website url")
      }
    }
    if(!draft) {
      setSubmitLoader(true)
    }else {
      setDraftLoader(true)
    }
    if(props?.location?.state?.data?.id) {
      dispatch(PerkActions.editPerk(payload, props?.location?.state?.data?.id)).then(() => {
        setDraftLoader(false)
        setSubmitLoader(false)
        props.history.goBack()
      })
    }else {
      dispatch(PerkActions.addPerk(payload)).then(() => {
        setDraftLoader(false)
        setSubmitLoader(false)
        props.history.goBack()
      })
    }
  }

  const checkValidUrl = new RegExp("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");

  const checkDisabled = () => {
    if(data?.name.trim() && data?.website.trim() && data?.website.trim() && data?.perk_type && data?.description.trim() && data?.details.trim() && data?.image) {
      return false
    }
    return true
  }

  return (
    <div className="add-perk-wrapper">
      <div className="header-left d-flex align-items-center justify-content-between">
        <span
          className="back-button flex align-items-center"
          onClick={() => props.history.goBack()}
          style={{cursor: 'pointer'}}
        >
          <ArrowLeftOutlined /> Back
        </span>
      </div>
      <div className="perk-card-main">
        <div className="perk-title-wrapper d-flex justify-content-between align-items-center">
          <h5 className="perk-title">New Perk</h5>
          <CloseOutlined className="perk-close-icon" onClick={() => props.history.goBack()}
          style={{cursor: 'pointer'}}/>
        </div>
        <div className="add-perk-card d-flex flex-column flex-md-row justify-content-between addPerkCard">
          <Form className="perk-form w-100">
            <FormGroup className="perk-form-group">
              <Label className="perk-label" for="website">
                Perk Name
              </Label>
              <Input
                className="perk-input"
                type="text"
                name="name"
                id="name"
                onChange={onChange}
                value={data?.name}
              />
            </FormGroup>
            <FormGroup className="perk-form-group">
              <Label className="perk-label" for="website">
                Website
              </Label>
              <Input
                className="perk-input"
                type="text"
                name="website"
                id="website"
                onChange={onChange}
                value={data?.website}
              />
            </FormGroup>
            <FormGroup className="perk-form-group flex flex-column">
              <Label className="perk-label" for="website">
                Type
              </Label>
              <div className="d-flex perk-button-group">
                <Button className={`perk-type-button ${data?.perk_type === 'HIGH_VALUE' && 'active'}`} onClick={() => onChangeType('HIGH_VALUE')}>High Value</Button>
                <Button className={`perk-type-button ${data?.perk_type === 'MEDIUM_VALUE' && 'active'}`} onClick={() => onChangeType('MEDIUM_VALUE')}>Medium Value</Button>
                <Button className={`perk-type-button ${data?.perk_type === 'Low_VALUE' && 'active'}`} onClick={() => onChangeType('Low_VALUE')}>Low Value</Button>
              </div>
            </FormGroup>
            <FormGroup className="perk-form-group flex flex-column">
              <Label className="perk-label" for="description">
                Description
              </Label>
              <Input
                className="perk-textarea"
                type="textarea"
                name="description"
                id="description"
                onChange={onChange}
                value={data?.description}
              />
            </FormGroup>
            <FormGroup className="perk-form-group flex flex-column">
              <Label className="perk-label" for="website">
                Details
              </Label>
              <Input
                className="perk-textarea"
                type="textarea"
                name="details"
                id="details"
                onChange={onChange}
                value={data?.details}
              />
            </FormGroup>
          </Form>
          <div className="add-perk-img-wrapper flex justify-content-end align-items-center w-100">
            <div className="flex h-100 flex-column perk-img-upload">
              <span className="add-banner-text">Add Logo</span>
              <div className="add-perk-user-wrapper d-flex flex-column justify-content-center align-items-center">
                <Dragger {...props1}>
                  <div>
                    <img src={UploadIcon} />
                  </div>
                  <div className="flex flex-column align-items-center perk-upload-box">
                    <span className="perk-upload-type">
                      Upload JPG or PNG only
                    </span>
                    <span className="perk-upload-drag">
                      Drag and drop your Image file here
                    </span>
                    <span className="perk-or-text">OR</span>
                    <div className="perk-upload-buttons">
                      <Button className="upload-btn-choose" style={{pointerEvents: 'none'}}>Choose File</Button>
                    </div>
                  </div>
                  {loader && <div style={{marginTop: 10}}><Spin /></div>}
                </Dragger>
              </div>
            </div>
          </div>
        </div>
        <div className="add-perk-buttons flex">
          <div className="button-group flex align-items-center justify-content-between justify-content-md-between">
            <Button className="button-cancel" onClick={() => props.history.goBack()} style={{width: 142}}>Cancel</Button>
            <Button className="button-save" onClick={() => onSubmit(true)} disabled={checkDisabled() || draftLoader} style={{width: 142}}>{draftLoader ? <Spin /> : "Save Draft"}</Button>
          </div>
          <Button className="button-publish" onClick={() => onSubmit(false)} disabled={checkDisabled() || submitLoader} style={{width: 142}}>{submitLoader ? <Spin /> : "Submit"}</Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddPerk);
