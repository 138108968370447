import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const getTeamMemberList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_TEAM_MEMBERS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/members`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getTeamMemberListWithParam = (page) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_TEAM_MEMBERS_INIT,
      })
      await api
        .getwithReqForm(`${process.env.REACT_APP_API_URL}/admin/members?page=`+page)
        .then(res => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getDepartmentList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_DEPARTMENT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/departments`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_DEPARTMENT_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_DEPARTMENT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projects`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PROJECT_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getPermissionList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PERMISSION_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/permissions`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PERMISSION_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PERMISSION_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getTeamMemberFilterList = (data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/members`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_TEAM_MEMBERS_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getInvitedTeamMemberList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_INVITED_TEAM_MEMBERS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/invitedTeamMembers`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_INVITED_TEAM_MEMBERS_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_INVITED_TEAM_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getTeamMemberDetail = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_TEAM_MEMBERS_DETAIL_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/member/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_DETAIL_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_TEAM_MEMBERS_DETAIL_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const editTeamDetail = (id, data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.EDIT_TEAM_MEMBER_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/member/${id}`,data)
        .then(res => {
          dispatch({
            type: actionTypes.EDIT_TEAM_MEMBER_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.EDIT_TEAM_MEMBER_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const addInviteTeamMember = (data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.POST_INVITE_MEMBER_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/inviteTeamMembers`,data)
        .then(res => {
          if(res.result) {
            dispatch({
              type: actionTypes.POST_INVITE_MEMBER_SUCCESS,
              payload: res.data,
            })
            onSuccess(res.data)
          }else {
            dispatch({
              type: actionTypes.POST_INVITE_MEMBER_FAILED,
            })
            onFail(res.error)
          }
        })
        .catch(error => {
          dispatch({
            type: actionTypes.POST_INVITE_MEMBER_FAILED,
          })
          onFail(error)
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const deleteTeamMember = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.DELETE_TEAM_MEMBER_INIT,
      })
      await api
        .delete(`${process.env.REACT_APP_API_URL}/admin/member/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.DELETE_TEAM_MEMBER_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.DELETE_TEAM_MEMBER_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const resendInvitation = (id, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.RESEND_INVITE_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/resendInvitation/${id}`)
        .then(res => {
          if(res.result) {
            dispatch({
              type: actionTypes.RESEND_INVITE_SUCCESS,
              payload: res,
            })
            onSuccess(res.data)
          }else {
            dispatch({
              type: actionTypes.RESEND_INVITE_FAILED,
            })
            onFail(res.error)
          }  
          return
        })
        .catch(error => {
          onFail(error.message)
          dispatch({
            type: actionTypes.RESEND_INVITE_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }