import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { Tabs, Pagination, Spin, Input, Tooltip } from "antd";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import TeamIcon from "../../../../src/assets/images/inventive_byte/team-icon.png";
import InviteTeamIcon from "../../../../src/assets/images/inventive_byte/invite-team-icon.png";
import AddBlog from "../../../../src/assets/images/inventive_byte/add-blog.png";
import BlogName from "../../../../src/assets/images/inventive_byte/blog-name.png";
import InfoIcon from "../../../../src/assets/images/inventive_byte/info-icon.png";
import BlogEdit from "../../../../src/assets/images/inventive_byte/blog-edit.png";
import BlogDelete from "../../../../src/assets/images/inventive_byte/blog-delete.png";
import DropDownIcon from "../../../../src/assets/images/inventive_byte/drop-down-icon.png";
import SearchIcon from "../../../../src/assets/images/inventive_byte/search.png";
import FilterIcon from "../../../../src/assets/images/inventive_byte/filter-icon.png";
import CancelIcon from "../../../../src/assets/images/inventive_byte/cancel-icon2.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import TeamDetail from "./TeamDetail";
import InviteTeamMember from "./InviteTeamMember";
import TeamTabs from "./TeamTabs";
import * as TeamActions from "../../../redux/TeamFlowControl/TeamActions";
import "./TeamDashboard.scss";

const TeamDashboard = (props) => {
  const dispatch = useDispatch();
  const { team_member_list, loading } = useSelector(
    (state) => state.TeamReducers
  );

  console.log(team_member_list,"team_member_list")
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const [activeProfile, setActiveProfile] = useState(false);

  const pageSize = 5;

  useEffect(() => {
    dispatch(TeamActions.getTeamMemberList());
  }, []);

  useEffect(() => {
    setState({
      data: team_member_list?.paginations?.total_docs,
      totalPage: team_member_list?.paginations?.total_docs / pageSize,
      current: team_member_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, [team_member_list]);

  const handleProfileClick = () => {
    setActiveProfile(!activeProfile);
  };

  const handleActiveDetailClick = (id) => {
    props.history.push(`/inventive/team-detail/${id}`);
  };

  const handleInviteTeamMemberClick = () => {
    props.history.push("/inventive/invite-team");
  };

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    dispatch(await TeamActions.getTeamMemberListWithParam(page));
  };

  const onDeleteTeamMember = async (id) => {
    dispatch(await TeamActions.deleteTeamMember(id))
    dispatch(await TeamActions.getTeamMemberList())
  }

  return (
    <>
      <div className="team-tabs-main">
        <div className="tabs-wrapper">
          <div className="blog-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
            <div className="blog-icon-wrapper flex align-items-center justify-content-start">
              <img className="blog-icon" src={TeamIcon} />{" "}
              <span className="blog-icon-text">Team Management</span>
            </div>

            <div className="flex">
              <div>
                <Button
                  className="blog-button"
                  onClick={handleInviteTeamMemberClick}
                >
                  <img className="blog-button-img" src={InviteTeamIcon} />
                  Invite team
                </Button>
              </div>
            </div>
          </div>
          <div></div>
          <div className="team-desktop-wrap">
            {loading && <Spin className="investor-spin" size="large" />}
            <div className="main-table-wrapper">
              <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                <div className="th-data">
                  No{" "}
                  <span className="img-icon">
                    <img src="" />{" "}
                  </span>
                </div>
                <div className="th-data">
                  Full Name{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>
                </div>
                <div className="th-data">
                  Email Address{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data">
                  Department{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data">
                  Assigned Projects{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data justify-content-center">
                  Status{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
                <div className="th-data justify-content-center">
                  Action{" "}
                  <span className="img-icon">
                    <img src={DropDownIcon} />{" "}
                  </span>{" "}
                </div>
              </div>
            </div>
            {team_member_list?.data?.map((data, index) => {
              let projectData="";
              let permissionData="";
              data?.projects?.map((p) => {
                projectData += p.name + ", ";
              })
              data?.permissions?.map((p) => {
                permissionData += p.name + ", "
              })
              let projectTooltip= projectData.slice(0,-2);
              let permissionTooltip= permissionData.slice(0,-2);
              return (
                index >= state.minIndex &&
                index < state.maxIndex && (
                  <div className="table-body flex justify-content-start align-items-center">
                    <div className="table-data order-0 order-lg-0">
                      <p
                        className="username mb-0"
                        onClick={()=>{handleActiveDetailClick(data?.id)}}
                      >
                        {index + 1}
                      </p>
                    </div>
                    <div className="table-data order-1 order-lg-1">
                      <div
                        className="d-flex align-items-center justify-content-center username"
                        onClick={()=>{handleActiveDetailClick(data?.id)}}
                      >
                        <p className="d-flex align-items-center justify-content-center user-icon">{data?.first_name?.charAt(0).toUpperCase()}</p>
                        <p className="user-name">{data?.first_name} {data?.last_name}</p>
                      </div>
                    </div>
                    <div className="table-data order-2 order-lg-2">
                      <div className="flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Email Address
                        </label>
                        <div className="technology"><Tooltip className="removeSpanCss" placement="top" title={data?.email}>{data?.email}</Tooltip></div>
                      </div>
                    </div>
                    <div className="table-data order-3 order-lg-3">
                      <div className="flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Department
                        </label>
                        <Tooltip className="department-tooltip" placement="right" title={permissionTooltip}>
                        <div className="job">
                          {data?.department?.name} <img src={InfoIcon} />
                        </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="table-data order-4 order-lg-4">
                      <div className="flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Assigned Projects
                        </label>
                        <Tooltip placement="right" title={projectTooltip}><div className="project">{data?.projects?.length}</div></Tooltip>
                      </div>
                    </div>
                    <div className="table-data order-5 order-lg-5 flex justify-content-center">
                      <div className="flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Status
                        </label>
                        {
                          data?.active ? <Button className="blog-button">active</Button> :  <Button className="blog-button-draft">inactive</Button>
                        }
                      </div>
                    </div>
                    <div className="table-data blog-action order-6 order-lg-6 flex justify-content-center">
                      <img className="action-img" src={BlogEdit} onClick={()=>{handleActiveDetailClick(data?.id)}}/>
                      <img className="action-img" src={BlogDelete} onClick={()=>{onDeleteTeamMember(data?.id)}}/>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          {/* <div className="team-filter-main d-block d-lg-none">
            <div className="team-filter-wrapper">
              <Dropdown isOpen={activeProfile} >
                <DropdownToggle className="team-filter-img">
                  <img src={FilterIcon} onClick={handleProfileClick}/>
                </DropdownToggle>
                <DropdownMenu className="team-filter-menu">
                  <div>
                    <div className="flex justify-content-between align-items-center team-filter-cancel">
                      <h4 className="team-filter-text"> Filter </h4>
                      <img src={CancelIcon} className="img-fluid team-cancel" onClick={handleProfileClick}/>
                    </div>
                    <div className="team-dashboard-button flex justify-content-center align-items-center">
                      <div className="flex w-100">
                        <select className="team-btn w-100">
                          <option>Department</option>
                          <option>U.S. Savings</option>
                          <option>International/Emerging Markets</option>
                        </select>

                        <select className="team-btn w-100">
                          <option>Gravity Points</option>
                          <option>U.S. Savings</option>
                          <option>International/Emerging Markets</option>
                        </select>
                      </div>
                      <Input
                          type="number"
                          className="team-btn w-100"
                          placeholder="No. Assigned Projects"
                       />
                      <select className="team-btn w-100">
                        <option>Gravity Points</option>
                        <option>U.S. Savings</option>
                        <option>International/Emerging Markets</option>
                      </select>
                    </div>
                    <div className="team-input-wrapper flex justify-content-center align-items-center">
                      <img className="team-input-img" src={SearchIcon} />
                      <input
                        className="team-input"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                    <Button className="team-apply-btn">Apply</Button>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div> */}

          {!loading && <div className="flex align-items-baseline justify-content-center team-pagination">
            <Pagination
              defaultCurrent={1}
              total={state?.data}
              pageSize={pageSize}
              onChange={handleChange}
              current={state.current}
            />
          </div>}
        </div>
      </div>
    </>
  );
};

export default withRouter(TeamDashboard);
