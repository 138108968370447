import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Spin } from "antd";
import LeadIcon from "../../../../../assets/images/inventive_byte/acc-rec.png";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import IncompleteIcon from "../../../../../assets/images/inventive_byte/incomplete-invoice.svg";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import LeadActiveImg from "../../../../../assets/images/inventive_byte/lead-active.png";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import "./InvoiceList.scss";
import moment from "moment";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const InvoiceList = (props) => {
  const dispatch = useDispatch();
  const { invoice_list, loading } = useSelector((state) => state.LeadReducers);

  useEffect(() => {
    dispatch(LeadActions.getInvoiceList(props?.clientID));
  }, []);

  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const pageSize = 5;

  useEffect(() => {
    setState({
      data: invoice_list?.paginations?.total_docs,
      totalPage: invoice_list?.paginations?.total_docs / pageSize,
      current: invoice_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, [invoice_list]);

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: "page", data: page }];
    dispatch(await LeadActions.getInvoiceList(props?.clientID, newParams));
  };

  return (
    <div className="invoice-main">
      <div className="invoice-desktop-wrap">
        <div className="main-lead-wrapper">
          {loading && <Spin className="invoice-spin" />}
          <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
            <div className="th-data">
              Invoice{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Amount Due{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Due Date{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data">
              Status{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data flex justify-content-center">
              Action{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        {invoice_list?.data?.length ? invoice_list?.data?.map((data, index) => {
          return (
            index >= state.minIndex &&
            index < state.maxIndex && (
              <div className="table-body flex justify-content-start align-items-center">
                <div className="table-data">
                  <div className="username" onClick="">
                    <img className="invoice-img" src={LeadIcon} />
                    {data?.invoice_no}
                  </div>
                </div>
                <div className="table-data">
                  <div className="flex invoice-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Amount Due
                    </label>
                    <div className="amount flex align-items-center">
                      ${data?.amount}
                    </div>
                  </div>
                </div>

                <div className="table-data">
                  <div className="flex invoice-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Due Date
                    </label>
                    <div className="flex align-items-center">
                      <span className="date">
                        {moment(data?.due_date).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="table-data">
                  <div className="flex invoice-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Status
                    </label>
                    {data?.paid === false ? (
                      <div className="incomplete">
                        <img className="incomplete-img" src={IncompleteIcon} />
                        Incomplete
                      </div>
                    ) : (
                      <div className="complete-status">
                        <img className="incomplete-img" src={LeadActiveImg} />
                        Completed
                      </div>
                    )}
                  </div>
                </div>
                <div className="table-data flex justify-content-center">
                  <div className="flex invoice-card justify-content-center">
                    <a
                      href={
                        `https://d3u1baow9deq5h.cloudfront.net/` +
                        data?.invoice_pdf
                      }
                    >
                      <img src={DownloadIcon} />
                    </a>
                  </div>
                </div>
              </div>
            )
          );
        }): 
          <NoDataFound />
        }
      </div>
      {!loading && invoice_list?.paginations?.total_docs > 5 && <div className="flex align-items-baseline justify-content-center lead-pagination">
        <Pagination
          defaultCurrent={1}
          total={invoice_list?.paginations?.total_docs}
          pageSize={pageSize}
          onChange={handleChange}
          current={state.current}
        />
      </div>}
    </div>
  );
};

export default withRouter(InvoiceList);
