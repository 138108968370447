import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { message, Tabs } from "antd";
import { Button } from "react-bootstrap";
import LeadProfileIcon from "../../../../assets/images/inventive_byte/lead-profile-icon.png";
import BlackCheck from "../../../../assets/images/inventive_byte/black-check.png";
import WhiteCheck from "../../../../assets/images/inventive_byte/white-check.png";
import EditIcon from "../../../../assets/images/inventive_byte/edit-icon.svg";
import AddIcon from "../../../../assets/images/inventive_byte/add-blog.png";
import Header from "../../Header/Header";
import HeaderMobile from "../../Header/HeaderMobile";
import LeftPanel from "../../LeftPanel";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import InvoiceList from "./Invoices/InvoiceList";
import AgreementList from "./Agreements/AgreementList";
import TeamList from "./Team/TeamList";
import SupportChat from "./Support/SupportChat";
import "./LeadMemberDetailMain.scss";
import * as LeadActions from "../../../../redux/LeadFlowControl/LeadActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";

const LeadMemberDetailMain = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams();
  const [loader, setLoader] = useState(false)
  const { TabPane } = Tabs;
  const [active, setActive] = useState("Project Details");
  const { project_detail } = useSelector((state) => state.LeadReducers);

  const { user_permission } = useSelector(
    (state) => state.DashboardReducers
  );
 
  const handleChange = (k) => {
    setActive(k);
  };

  const redirectToInvoice = () => {
    props.history.push("/inventive/lead/invoice");
  };

  const redirectToAgreement = () => {
    props.history.push(`/inventive/lead/agreement/${params?.id}`);
  };

  const redirectToTeam = () => {
    props.history.push(`/inventive/lead/team/${params?.id}`);
  };

  const redirectToEditProfile = () => {
    props.history.push(`/inventive/lead/profile/${params?.id}`);
  };

  const sendReminder = (reminder_type) => {
    dispatch(LeadActions.sendReminderLead(
      params?.id, [{ label: 'reminder_type', data: reminder_type }],
      (res) => {
        message.success(res);
      },
      (err) => {
        message.error(err || "Failed to send Reminder.");
      }
    ));
  }

  useEffect(() => {
    setLoader(true)
    dispatch(LeadActions.getProjectDetail(params?.id)).then(() => {
      setLoader(false)
    });
    dispatch(LeadActions.getProjectType());
    dispatch(LeadActions.getProjectCategory());
    dispatch(LeadActions.getProjectPhase());
    dispatch(LeadActions.getProjectFeatures());
  }, []);

  const operations =
    // active === "Invoices" ? (
    //   <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
    //     <Button
    //       className="invoice-button d-flex align-items-center"
    //       // onClick={redirectToInvoice}
    //     >
    //       <img className="invoice-button-img" src={AddIcon} />
    //       Add Invoice
    //     </Button>
    //   </div>
    // ) : 
    active === "Agreements" && user_permission?.includes('Agreements') ? (
      <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
        <Button
          className="invoice-button d-flex align-items-center"
          onClick={redirectToAgreement}
        >
          <img className="invoice-button-img" src={AddIcon} />
          Add Agreement
        </Button>
      </div>
    ) : active === "Team" && user_permission?.includes('Team Management') ? (
      <div className="flex justify-content-end d-none d-md-flex invoice-button-wrapper">
        <Button
          className="invoice-button d-flex align-items-center"
          onClick={redirectToTeam}
        >
          <img className="invoice-button-img" src={AddIcon} />
          Add Team Member
        </Button>
      </div>
    ) : (
      ""
    );
  return (
    <>
    {loader && project_detail?.data ? <Loader /> :
    <div className="lead-member-details-wrapper">
      <div className="header-left">
        <span className="back-button" onClick={() => history.goBack()} style={{cursor: 'pointer'}}>
          <ArrowLeftOutlined /> Back
        </span>
      </div>
      <div className="lead-member-profile flex ">
        <div className="flex flex-column align-items-center justify-content-center avatarMainCon" style={{minWidth: 200}}>
          {/* <img src={project_detail?.data?.profile_image ? `https://d3u1baow9deq5h.cloudfront.net/` + project_detail?.data?.profile_image : LeadProfileIcon} style={{width: 100, borderRadius: '50%'}} /> */}
          {!project_detail?.data?.profile_image ? (
            <p className="d-flex align-items-center justify-content-center user-icon">
              {project_detail?.data?.first_name?.charAt(0).toUpperCase()}
            </p>
          ) : (
            <img
              className="blog-img user-icon"
              src={
                `https://d3u1baow9deq5h.cloudfront.net/` +
                project_detail?.data?.profile_image
              }
            />
          )}
          <span className="user-name" style={{marginBottom: 5}}>
            {project_detail?.data?.first_name + ' ' + project_detail?.data?.last_name} <img src={EditIcon} onClick={redirectToEditProfile} />
          </span>
          <Button className="active-btn" style={!project_detail?.data?.active ? {background: 'rgba(242, 72, 34, 0.34)', color: '#F24822'} : {}}>{project_detail?.data?.active ? "Active" : "Inactive"}</Button>
        </div>
        <div className="d-flex alignitems-center lead-profile-card-wrapper justify-content-center">
          <div className="flex flex-row flex-md-column align-items-center justify-content-center lead-profile-card lead-profile-done">
            <div className="flex flex-row flex-md-column align-items-center justify-content-center mb-3 mb-md-0">
              <img src={BlackCheck} />
              <span className="lead-profile-card-text">Shared Idea</span>
            </div>
          </div>
          <div className="flex flex-row flex-md-column align-items-center justify-content-center lead-profile-card lead-profile-done">
            <div className="flex flex-row flex-md-column align-items-center justify-content-center mb-3 mb-md-0">
              <img src={BlackCheck} />
              <span className="lead-profile-card-text">Schedule Call</span>
            </div>
            {project_detail?.data?.boarding_stage === 1 && <div className="flex align-items-center justify-content-center lead-reminder" style={{cursor: 'pointer'}} onClick={() => sendReminder('scheduled')}>
              <span>send reminder</span>
            </div>}
          </div>
          <div className="flex flex-row flex-md-column align-items-center justify-content-center lead-profile-card lead-profile-done">
            <div className="flex flex-row flex-md-column align-items-center justify-content-center mb-3 mb-md-0">
              <img src={[2,3,4].includes(project_detail?.data?.boarding_stage) ? BlackCheck : WhiteCheck} />
              <span className="lead-profile-card-text">Agreement</span>
            </div>
            {project_detail?.data?.boarding_stage === 2 && <div className="flex align-items-center justify-content-center lead-reminder" style={{cursor: 'pointer'}} onClick={() => sendReminder('agreement')}>
              <span>send reminder</span>
            </div>}
          </div>
          <div className="flex flex-row flex-md-column align-items-center justify-content-center lead-profile-card lead-profile-done">
            <div className="flex flex-row flex-md-column align-items-center justify-content-center mb-3 mb-md-0">
            <img src={[3,4].includes(project_detail?.data?.boarding_stage) ? BlackCheck : WhiteCheck} />
              <span className="lead-profile-card-text">Invoice</span>
            </div>
            {project_detail?.data?.boarding_stage === 3 && <div className="flex align-items-center justify-content-center lead-reminder" style={{cursor: 'pointer'}} onClick={() => sendReminder('invoice')}>
              <span>send reminder</span>
            </div>}
          </div>
        </div>
      </div>
      <div>
        <Tabs
          className="status-tab"
          defaultActiveKey="1"
          onChange={handleChange}
          tabBarExtraContent={operations}
        >
          <TabPane tab="Project Details" key="Project Details">
            <ProjectDetail clientID={params?.id} showEmail={true}/>
          </TabPane>
          {user_permission?.includes('Agreements') ? <TabPane className="" tab="Agreements" key="Agreements">
            <AgreementList clientID={params?.id} />
          </TabPane>:null}
          {user_permission?.includes('Invoices') ? <TabPane className="" tab="Invoices" key="Invoices">
            <InvoiceList clientID={params?.id} />
          </TabPane>:null}
          {user_permission?.includes('Support') ?<TabPane className="" tab="Support" key="Support">
            <SupportChat clientID={params?.id}/>
          </TabPane>:null}
          {user_permission?.includes('Team Management') ? <TabPane className="" tab="Team" key="Team">
            <TeamList clientID={params?.id} />
          </TabPane>:null}
        </Tabs>
      </div>
    </div>}
    </>
  );
};

export default withRouter(LeadMemberDetailMain);
