import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "react-bootstrap";
import { message, Pagination, Spin, Tooltip } from "antd";
import LeadIcon from "../../../../../assets/images/inventive_byte/acc-pen.png";
import LeadActiveImg from "../../../../../assets/images/inventive_byte/lead-active.png";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import IncompleteIcon from "../../../../../assets/images/inventive_byte/incomplete-invoice.svg";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import AgreementDetail from "./AgreementDetail";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import * as DashboardActions from "../../../../../redux/DashboardFlowControl/DashboardActions";
import "./AgreementList.scss";
import { saveAs } from 'file-saver';
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const AgreementList = (props) => {
  const dispatch = useDispatch();
  const { agreement_list, loading } = useSelector(
    (state) => state.LeadReducers
  );
  const [activeDetail, setActiveDetail] = useState(false);
  const [isLoader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(LeadActions.getAgreementList(props?.clientID));
  }, []);

  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const pageSize = 5;

  useEffect(() => {
    setState({
      data: agreement_list?.paginations?.total_docs,
      totalPage: agreement_list?.paginations?.total_docs / pageSize,
      current: agreement_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, [agreement_list]);

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: "page", data: page }];
    dispatch(await LeadActions.getAgreementList(props?.clientID,newParams));
  };

  const handleDetailClick = (id) => {
    props.history.push(`/inventive/lead/agreement-detail/${id}`);
  };

  const sendReminder = (id) => {
    setLoader(id)
    dispatch(DashboardActions.sendReminder(
      id,
      (res) => {
        setLoader(false)
        message.success(res);
      },
      (err) => {
        setLoader(false)
        message.error(err);
      }
    ));
  }

  const onDownload = (data, key) => {
    if(data[key]) {
      saveAs(
        `https://d3u1baow9deq5h.cloudfront.net/${data[key]}`,
        data?.name || data[key].split('/').slice(-1)[0]
      );
    }else {
      message.warn("No Agreement Found")

    }
  }

  return (
    <div className="agreement-main">
      <div className="agreement-desktop-wrap">
        <div className="main-lead-wrapper">
          {loading && <Spin className="lead-spin" />}
          <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
            <div className="th-data">
              Agreements{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Issue date{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Status{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data d-flex justify-content-center">
              Action{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        {agreement_list?.data?.length ? agreement_list?.data?.map((data, index) => {
          return (
            index >= state.minIndex &&
            index < state.maxIndex && (
              <div className="table-body flex justify-content-start align-items-center">
                <div className="table-data">
                  {!data?.agreement_pdf ? (
                    <div
                      className="username"
                      onClick={() => {
                        handleDetailClick(data?.id);
                      }}
                    >
                      <img className="agreement-img" src={LeadIcon} />
                      <Tooltip title={data?.name} placement="top">
                        <span className="user-name">{data?.name}</span>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="username">
                      <img className="agreement-img" src={LeadIcon} />
                      <Tooltip title={data?.name} placement="top">
                        <span className="user-name">{data?.name}</span>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="table-data">
                  <div className="flex agreement-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Issue date
                    </label>
                    <div className="amount flex align-items-center">
                      {moment(data?.created_at).format("MM-DD-YYYY")}
                    </div>
                  </div>
                </div>

                <div className="table-data">
                  <div className="flex agreement-card">
                    <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                      Status
                    </label>
                    {data?.completed === false ? (
                      <div className="incomplete">
                        <img className="incomplete-img" src={IncompleteIcon} />
                        Incomplete
                      </div>
                    ) : (
                      <div className="complete-status">
                        <img className="incomplete-img" src={LeadActiveImg} />
                        Completed
                      </div>
                    )}
                  </div>
                </div>

                <div className="table-data flex justify-content-center">
                  <div className="flex agreement-card justify-content-center align-items-center">
                    {!data?.agreement_pdf ? (
                      ""
                    ) : (
                      
                        <img src={DownloadIcon} alt="" onClick={() => onDownload(data, "agreement_pdf")}/>
                    )}
                    {data?.completed === false ? (
                      <Button className="reminder-buttons" onClick={() => sendReminder(data?.id)}>
                        {isLoader === data?.id ? 'Loading...' : 'Send Reminder'}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )
          );
        }): 
          <NoDataFound />
        }
      </div>
      {!loading && agreement_list?.paginations?.total_docs > 5 && <div className="flex align-items-baseline justify-content-center lead-pagination">
        <Pagination
          defaultCurrent={1}
          total={agreement_list?.paginations?.total_docs}
          pageSize={pageSize}
          onChange={handleChange}
          current={state.current}
        />
      </div>}
    </div>
  );
};

export default withRouter(AgreementList);
