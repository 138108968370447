import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { setTokenInLocalStorage,getTokenInLocaleStorage } from '../utils/general'
import AdminLogin from '../pages/admin/AdminLogin'

import BlogList from '../pages/admin/Blogs/BlogList'
import Dashboard from '../pages/admin/Dashboard/Dashboard'
import LeadDashboard from '../pages/admin/LeadManagement/LeadDashboard'
import AddInvoice from '../pages/admin/LeadManagement/LeadMemberDetail/Invoices/AddInvoice'
import AddAgreementMain from '../pages/admin/LeadManagement/LeadMemberDetail/Agreements/AddAgreementMain'
import AgreementDetail from '../pages/admin/LeadManagement/LeadMemberDetail/Agreements/AgreementDetail'
import AddTeamMates from '../pages/admin/LeadManagement/LeadMemberDetail/Team/AddTeamMates'
import EditLeadProfile from '../pages/admin/LeadManagement/LeadMemberDetail/EditLeadProfile'
import TeamTabs from '../pages/admin/TeamManagement/TeamTabs'
import InviteTeamMember from '../pages/admin/TeamManagement/InviteTeamMember'
import ClientDashboard from '../pages/admin/ClientManagement/ClientDashboard'
import ClientAddInvoice from '../pages/admin/ClientManagement/ClientManagementDetail/Invoices/AddInvoice'
import ClientAddTextAgreement from '../pages/admin/ClientManagement/ClientManagementDetail/Agreements/AddTextAgreement'
import ClientAgreementDetail from '../pages/admin/ClientManagement/ClientManagementDetail/Agreements/AgreementDetail'
import ClientAddUpdate from '../pages/admin/ClientManagement/ClientManagementDetail/Updates/AddUpdate'
import ClientUpdateDetail from '../pages/admin/ClientManagement/ClientManagementDetail/Updates/UpdateDetail'
import ClientFeedBackReply from '../pages/admin/ClientManagement/ClientManagementDetail/FeedBack/FeedBackReply'
import ClientAddTeamMates from '../pages/admin/ClientManagement/ClientManagementDetail/Team/AddTeamMates'
import EditClientDetail from '../pages/admin/ClientManagement/ClientManagementDetail/EditClientDetail'
import EditClientProfile from '../pages/admin/ClientManagement/ClientManagementDetail/EditClientProfile'
import PerkList from '../pages/admin/Perks/PerkList'
import Profile from '../pages/admin/Profile/Profile'
import ChangePassword from '../pages/admin/Settings/ChangePassword'
import ErrorRoute404 from '../pages/ErrorRoute404/ErrorRoute404'
import {adminLogin} from '../redux/AdminLoginFlowControl/AdminLoginActions'
import BlogDetails from '../pages/admin/Blogs/BlogDetails'
import AddNewBlog from '../pages/admin/Blogs/AddNewBlog'
import TeamDetail from '../pages/admin/TeamManagement/TeamDetail'
import './App.css'
import InviteSuccess from '../pages/admin/TeamManagement/InviteSuccess'
import AddTextAgreement from '../pages/admin/LeadManagement/LeadMemberDetail/Agreements/AddTextAgreement'
import UploadAgreement from '../pages/admin/LeadManagement/LeadMemberDetail/Agreements/UploadAgreement'
import EditUpdate from '../pages/admin/ClientManagement/ClientManagementDetail/Updates/EditUpdate'
import AddPerk from '../pages/admin/Perks/AddPerk'
import ViewPerkDetail from '../pages/admin/Perks/ViewPerkDetail'
import MainLayout from '../Components/MainLayout/MainLayout'
import LeadMemberDetailMain from '../pages/admin/LeadManagement/LeadMemberDetail/LeadMemberDetailMain'
import ClientManagementDetail from '../pages/admin/ClientManagement/ClientManagementDetail/ClientManagementDetail'
import { getUserPermissions } from '../redux/DashboardFlowControl/DashboardActions'
import EditProjectNameAndDesc from '../pages/admin/LeadManagement/LeadMemberDetail/EditProjectNameAndDesc'
import CreatePassword from '../pages/admin/CreatePassword'
import ForgotPassword from '../pages/admin/ForgotPassword'
import ResetPassword from '../pages/admin/ResetPassword'
import ProtectedRoute from '../Routes/PrivateRoute'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      previousToken: props.token.token,
    }
  }

  componentDidMount() {
    // Add event listener on the storage token to update the redux react state
    // token when the storage token is modified.
    if(getTokenInLocaleStorage()) {
      this.props.getUserPermissions()
    }
    window.addEventListener('storage', event => {
      if (event.key === 'token') {
        const currentStorageToken = getTokenInLocaleStorage()
        if (currentStorageToken !== this.props.token.token) {
          this.props.adminLogin(currentStorageToken)
        }
      }
    })

    // // Emit page event on initial page load
    // emitPageEvent()
  }

  componentDidUpdate(previousProps) {
    // if (this.props.location.pathname !== previousProps.location.pathname) {
    //   // Emit page event for any change in the path.
    //   emitPageEvent()
    // }
  }

  render() {

    if (this.props.token.token !== this.state.previousToken) {
      setTokenInLocalStorage(this.props.token.token)

      this.setState({
        previousToken: this.props.token.token,
      })
    }
    const currentToken = getTokenInLocaleStorage()
    if (currentToken) {
      let decodeTokenData = jwt_decode(currentToken)
      if (new Date().getTime() >= decodeTokenData.exp * 1000) {
        localStorage.clear()
        this.props.history.push('/')
      }
    }

    // Update the previous token and local storage token if the token is changed

    return (
        // <ErrorBoundary>
        <div className="App">
          <Helmet>
            <title>Inventive Byte</title>
            <meta
                name="description"
                content="Connecting the most relevant founders and investors through data-driven intros at the pre-seed and seed stages."
            />
            <meta
                name="keywords"
                content="angel investors for startups, find angel investors, find angel investors online, angel investors search, angel investors website,
          angel investors online, how to find angel investors, technology angel investors, corporate angel investors, angel investors"
            />
          </Helmet>
          {/* {scoutPage ? (
          <HeaderScout />
        ) : investorPage ? (
          <HeaderInvestor />
        ) : startupPage ? (
          <HeaderStartup />
        ) : null} */}

          {/* <BrowserRouter> */}
          {
            !currentToken ? 
            <Switch>
              <Route exact path="/" component={AdminLogin} />
              <Route path="/create-password" component={CreatePassword} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route component={ErrorRoute404} />
            </Switch>:
            <MainLayout>
            <Switch>
              <ProtectedRoute exact path="/" component={Dashboard} />
              <ProtectedRoute exact path="/inventive/admin" component={Dashboard} />
              <ProtectedRoute exact path="/inventive/blogs" component={BlogList} />
              <ProtectedRoute exact path="/inventive/blogs/detail" component={BlogDetails} />
              <ProtectedRoute exact path="/inventive/blogs/add-blog" component={AddNewBlog} />
              <ProtectedRoute exact path="/inventive/team" component={TeamTabs} />
              <ProtectedRoute exact path="/inventive/team-detail/:id" component={TeamDetail} />
              <ProtectedRoute exact path="/inventive/invite-team" component={InviteTeamMember} />
              <ProtectedRoute exact path="/inventive/invite-team/success" component={InviteSuccess} />
              <ProtectedRoute exact path="/inventive/lead" component={LeadDashboard} />
              <ProtectedRoute exact path="/inventive/lead/:id" component={LeadMemberDetailMain} />
              <ProtectedRoute exact path="/inventive/lead/invoice" component={AddInvoice} />
              <ProtectedRoute exact path="/inventive/lead/agreement/:id" component={AddAgreementMain} />
              <ProtectedRoute exact path="/inventive/lead/agreement/text-editor/:id" component={AddTextAgreement} />
              <ProtectedRoute exact path="/inventive/lead/agreement/upload-pdf/:id" component={UploadAgreement} />
              <ProtectedRoute exact path="/inventive/lead/agreement-detail/:id" component={AgreementDetail} />
              <ProtectedRoute exact path="/inventive/lead/team/:id" component={AddTeamMates} />
              <ProtectedRoute exact path="/inventive/lead/profile/:id" component={EditLeadProfile} />
              <ProtectedRoute exact path="/inventive/lead/project/:id" component={EditProjectNameAndDesc} />
              <ProtectedRoute exact path="/inventive/client" component={ClientDashboard} />
              <ProtectedRoute exact path="/inventive/client/:id" component={ClientManagementDetail} />
              <ProtectedRoute exact path="/inventive/client/invoice" component={ClientAddInvoice} />
              <ProtectedRoute exact path="/inventive/client/agreement/:id" component={ClientAddTextAgreement} />
              <ProtectedRoute exact path="/inventive/client/agreement-detail/:id" component={ClientAgreementDetail} />
              <ProtectedRoute exact path="/inventive/client/update/:id" component={ClientAddUpdate} />
              <ProtectedRoute exact path="/inventive/client/edit-update/:id" component={EditUpdate} />
              <ProtectedRoute exact path="/inventive/client/update-detail/:id" component={ClientUpdateDetail} />
              <ProtectedRoute exact path="/inventive/client/feedback/:clientID/:feedbackID" component={ClientFeedBackReply} />
              <ProtectedRoute exact path="/inventive/client/team/:id" component={ClientAddTeamMates} />
              <ProtectedRoute exact path="/inventive/client/detail/:id" component={EditClientDetail} />
              <ProtectedRoute exact path="/inventive/client/profile/:id" component={EditClientProfile} />
              <ProtectedRoute exact path="/inventive/perk" component={PerkList} />
              <ProtectedRoute exact path="/inventive/add-perk" component={AddPerk} />
              <ProtectedRoute exact path="/inventive/view-perk/:id" component={ViewPerkDetail} />
              <ProtectedRoute exact path="/inventive/profile" component={Profile} />
              <ProtectedRoute exact path="/inventive/setting" component={ChangePassword} />
              {/* Error */}
              <Route component={ErrorRoute404} />
            </Switch>
            </MainLayout>
          }
        </div>
        // </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  token: state.AdminLoginReducers.user
})

const mapDispatchToProps = dispatch => ({
  adminLogin : token => {
    dispatch(adminLogin(token))
  },
  getUserPermissions: () => {
    dispatch(getUserPermissions())
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
