import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { message, Spin, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "../../../../../assets/images/inventive_byte/upload-pdf.png";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import "./UploadAgreement.scss";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import * as UploadActions from "../../../../../redux/UploadFlowControl/UploadActions";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import LeftPanel from "../../../LeftPanel";

const UploadAgreement = (props) => {
  const { Dragger } = Upload;
  const params = useParams();
  const dispatch = useDispatch();
  const { presigned_url } = useSelector((state) => state.UploadReducers);
  const [active, setActive] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [presignedState, setPresignedState] = useState(presigned_url);
  const [state, setState] = useState({
    name: "",
    agreement_pdf: "",
  });

  useEffect(() => {
    if (presignedState?.agreement) {
      setState({
        ...state,
        agreement_pdf: presignedState?.agreement?.data?.fields?.key,
      });
    }
    setLoadingPDF(false);
  }, [presignedState?.agreement]);

  const onPDFSelect = async (file) => {
    setLoadingPDF(true);
    setState({ ...state, name: file.name });
    dispatch(UploadActions.getPresignedUrl("agreement", file));
  };

  const onSubmit = async () => {
    await dispatch(LeadActions.addAgreement(params?.id,state, (res) => {
      if(res) {
        props.history.push("/inventive/lead")
      } else {
        message.error(res)
      }
    }, (err) => {
      message.error(err)
    }))
   setState({
    ...state,
    name: '',
    agreement_pdf: ''
   })
   
  }

  const cancelClick = () => {
    props.history.goBack()
  }

  return (
    <div className="upload-agreements-wrapper">
      <div className="header-left d-flex align-items-center justify-content-between">
        <span
          className="back-button flex align-items-center"
          onClick={() => props.history.goBack()}
        >
          <ArrowLeftOutlined /> Back
        </span>

        {/* <div className="d-flex align-items-center agreements-templates">
          <span className="agreements-templates-text">Templates:</span>
          <span className="agreements-templates-sow">SOW</span>
          <span className="agreements-templates-msa">MSA</span>
        </div> */}
      </div>
      <div className="upload-agreements-main-wrapper">
        <div className="upload-agreements-title-wrapper d-flex justify-content-between align-items-center">
          <h5 className="upload-agreements-title">Text Editor</h5>
          <CloseOutlined />
        </div>
        <div className="upload-agreements-card-main">
          <div className="upload-agreements-card">
            <Form className="upload-agreements-form">
              <div className="upload-agreements-user-main">
                <div className="upload-agreements">Upload Agreement</div>
                <div className="upload-agreements-user-wrapper flex flex-column justify-content-center align-items-center">
                  {/* <div>
                    <img src={UploadIcon} />
                  </div>
                  <span className="agreements-upload-drag">
                    Drag and drop your PDF file here
                  </span>
                  <span className="agreements-or-text">OR</span>
                  <div className="agreements-upload-buttons">
                    <Button className="upload-btn-choose">Choose File</Button>
                  </div> */}
                  <Dragger
                    openFileDialogOnClick={!loadingPDF}
                    className="file-upload-wrapper"
                    accept=".pdf"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(info) => onPDFSelect(info?.file)}
                  >
                    {loadingPDF && <Spin size="large" tip="uploading.." />}
                    {state?.agreement_pdf && (
                      <div className="slide-deck-file-selected-view-wrap">
                        <div className="img-icon">
                          {/* <img src={SlidePdf} /> */}
                        </div>
                        <a
                          href={state?.agreement_pdf}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {state?.name}
                        </a>
                        <span className="btn-file d-flex align-items-center justify-content-center">
                          Remove and Reupload
                        </span>
                      </div>
                    )}
                    {!loadingPDF && !state?.agreement_pdf && (
                      <div className="file-upload-main d-flex flex-column align-items-center">
                        <div className="upload-icon">
                          <img className="img-fluid" src={UploadIcon} style={{width: 80}} />
                        </div>
                        <p className="description-sec">
                          Drag and drop your Image file here OR
                        </p>
                        <span className="btn-file d-flex align-items-center justify-content-center">
                          Choose File
                        </span>
                      </div>
                    )}
                  </Dragger>
                </div>
              </div>
              <div className="agreements-buttons flex">
                <div className="button-group flex align-items-center justify-content-center">
                  <Button className="button-save" onClick={onSubmit} disabled={!state?.agreement_pdf}>
                    Save
                  </Button>
                  <Button className="button-cancel" onClick={cancelClick}>Cancel</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UploadAgreement);
