import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Pagination, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import moment from 'moment'
import { debounce } from "lodash";
import TeamIcon from "../../../../src/assets/images/inventive_byte/team-icon.png";
import ClientManagementIcon from "../../../../src/assets/images/inventive_byte/client-icon.svg";
import InviteTeamIcon from "../../../../src/assets/images/inventive_byte/invite-team-icon.png";
import LeadIcon from "../../../../src/assets/images/inventive_byte/lead-icon.png";
import LeadActive from "../../../../src/assets/images/inventive_byte/lead-active.png";
import LeadInactive from "../../../../src/assets/images/inventive_byte/lead-inactive.png";
import BlogName from "../../../../src/assets/images/inventive_byte/blog-name.png";
import DropDownIcon from "../../../../src/assets/images/inventive_byte/drop-down-icon.png";
import SearchIcon from "../../../../src/assets/images/inventive_byte/search.png";
import FilterIcon from "../../../../src/assets/images/inventive_byte/filter-icon.png";
import CancelIcon from "../../../../src/assets/images/inventive_byte/cancel-icon2.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import ClientManagementDetail from "./ClientManagementDetail/ClientManagementDetail";
import * as LeadActions from "../../../redux/LeadFlowControl/LeadActions";
import * as ClientActions from "../../../redux/ClientFlowControl/ClientActions";
import "./ClientDashboard.scss";

const ClientDashboard = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { client_list, loading } = useSelector((state) => state.ClientReducers);
  const [activeDetail, setActiveDetail] = useState(false);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [activeProfile, setActiveProfile] = useState(false);
  const [clientID, setClientID] = useState("");
  const [activeFilter, setActiveFilter] = useState("all");
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
    searchText: ''
  });

  const pageSize = 5;

  useEffect(() => {
    dispatch(ClientActions.getClientList());
  }, []);

  useEffect(() => {
    setState({
      data: client_list?.paginations?.total_docs,
      totalPage: client_list?.paginations?.total_docs / pageSize,
      current: client_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
      searchText: state?.searchText
    });
    setActiveFilter(activeFilter)
  }, [client_list])
  

  const handleProfileClick = () => {
    setActiveProfile(!activeProfile);
  };

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: 'page' , data: page },{ label: 'search', data: state.searchText },{ label: 'filter', data: activeFilter }]
    dispatch(await ClientActions.getClientFilterList(newParams));
  };
  const handleActiveDetailClick = async (id) => {
    // setClientID(id)
    // await dispatch(LeadActions.getProjectDetail(id))
    // setActiveDetail(true);
    history.push(`/inventive/client/${id}`)
  };

  const searchHandle = async (e) => {
    const value = e.target.value;
    setState({ ...state, searchText: value });
    await debouncedSearchdata(value);
  };
  const debouncedSearchdata = useCallback(
    debounce(async (searchText) => {
      await dispatch(ClientActions.getClientFilterList([{ label: 'search', data: searchText }, { label: 'page',data: state.current }]));
    }, 500),
    []
  );

  const handleFilter = async (value) => {
    setActiveFilter(value);

    let newParams = [
      { label: "filter", data: value },
      { label: "page", data: state.current },
    ];
    dispatch(await ClientActions.getClientFilterList(newParams));
  };

  const handleInviteTeamMemberClick = () => {
    props.history.push("/inventive/invite-team");
  };

  return (
    <>
    {activeDetail ? (
      <ClientManagementDetail clientID={clientID} />
    ) : (
      <div className="client-main">
        {loading && <Spin className="client-spin" />}
        <div className="client-wrapper">
          <div className="client-section d-flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
            <div className="client-icon-wrapper d-flex align-items-center justify-content-start">
              <img className="client-icon" src={ClientManagementIcon} />{" "}
              <span className="client-icon-text">client management</span>
            </div>

            <div className="d-flex d-none d-md-none d-lg-flex filterBoxContainer">
              <div className="client-input-wrapper">
                <img className="client-input-img" src={SearchIcon} style={{width: 20}}/>
                <input
                  className="client-input"
                  type="text"
                  placeholder="Search"
                  onChange={searchHandle}
                />
              </div>
              <div className="btn-client-wrapper">
                <div
                  className="btn-group btn-toggle custom-toggle-button custom-switch"
                  data-toggle="buttons"
                >
                  <label
                    className={
                      activeFilter === "all"
                        ? "btn btn-default active active-all"
                        : "btn btn-default"
                    }
                    onClick={() => {
                      handleFilter("all");
                    }}
                  >
                    All
                  </label>
                  <label
                    className={
                      activeFilter === "active"
                        ? "btn btn-default active"
                        : "btn btn-default"
                    }
                    onClick={() => {
                      handleFilter("active");
                    }}
                  >
                    Active
                  </label>
                  <label
                    className={
                      activeFilter === "inactive"
                        ? "btn btn-default active active-inactive"
                        : "btn btn-default"
                    }
                    onClick={() => {
                      handleFilter("inactive");
                    }}
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="client-desktop-wrap">
            <div className="main-client-wrapper">
              <div className="table-header d-flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                <div className="th-data align-items-center">
                  No
                  <span className="img-icon">
                    <img src="" />
                  </span>
                </div>

                <div className="th-data align-items-center">
                  Clients Name
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center">
                  Email Address
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center flex07">
                  Project
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center flex07">
                  Project Start Date
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center justify-content-center flex07">
                  Team
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center flex07">
                  Pending Invoices
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>

                <div className="th-data align-items-center justify-content-center flex07">
                  Status
                  <span className="img-icon">
                    <img src={DropDownIcon} />
                  </span>
                </div>
              </div>
            </div>
            {client_list?.data?.map((data, index) => {
              let teamData="";
              data?.team?.map((t) => {
                teamData += t.first_name + " " + t.last_name  + ", ";
              })
              let teamTooltip= teamData.slice(0,-2);
              return (
                index >= state.minIndex &&
                index < state.maxIndex && (
                  <div className="table-body d-flex justify-content-start align-items-center">
                    <div className="table-data order-0 order-lg-0">
                      <p
                        className="username mb-0"
                        onClick={() => {
                          handleActiveDetailClick(data?.client?.id);
                        }}
                      >
                        {(state.current - 1) * 5 + index + 1}
                      </p>
                    </div>
                    <div className="table-data order-1 order-lg-1">
                      <div
                        className="username"
                        onClick={() => {
                          handleActiveDetailClick(data?.client?.id);
                        }}
                      >
                        {!data?.client?.profile_image ? (
                          <p className="d-flex align-items-center justify-content-center user-icon">
                            {data?.client?.first_name
                              ?.charAt(0)
                              .toUpperCase()}
                          </p>
                        ) : (
                          <img
                            className="blog-img"
                            src={
                              `https://d3u1baow9deq5h.cloudfront.net/` +
                              data?.client?.profile_image
                            }
                            alt=""
                          />
                        )}
                        <Tooltip title={`${data?.client?.first_name}  ${data?.client?.last_name}`} placement="top">
                        <span className="user-name">{data?.client?.first_name} {data?.client?.last_name}</span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="table-data order-2 order-lg-2">
                      <div className="d-flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Email Address
                        </label>
                        <div className="email d-flex align-items-center">
                        <Tooltip title={data?.client?.email} placement="top" className="tooltipEmail">
                          {data?.client?.email}{" "}
                          </Tooltip>
                          {data?.client?.active ? (
                            <img src={LeadActive} />
                          ) : (
                            <img src={LeadInactive} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="table-data order-3 order-lg-3">
                      <div className="d-flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Project
                        </label>
                        <div className="stage d-flex align-items-center">
                          <span className="">
                            {data?.client?.project?.category?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="table-data order-4 order-lg-4 flex07">
                      <div className="d-flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Project Start Date
                        </label>
                        <div className="date">
                          {moment(data?.created_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="table-data justify-content-center order-5 order-lg-5 flex07">
                      <div className="d-flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Team
                        </label>
                        <Tooltip title={teamTooltip} placement="top">
                        <div className="team d-flex align-items-center justify-content-center">
                          {data?.team_count}
                        </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="table-data order-6 order-lg-6 flex07">
                      <div className="d-flex blog-card">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Pending Invoices
                        </label>
                        <div className="pending">
                          <img className="pending-img" src={LeadActive} />
                          {data?.client?.active === true ? "Paid" : "Remain"}
                        </div>
                      </div>
                    </div>
                    <div className="table-data order-7 order-lg-7 d-flex justify-content-start justify-content-lg-center flex07">
                      <div className="d-flex blog-card justify-content-start justify-content-lg-center">
                        <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                          Status
                        </label>
                          {data?.client?.active ? (
                            <Button className="blog-button-active">
                              Active
                            </Button>
                          ) : (
                            <Button className="blog-button-draft">
                              Inactive
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          {/* <div className="team-filter-main d-block d-lg-none">
            <div className="team-filter-wrapper">
              <Dropdown isOpen={activeProfile} toggle={handleProfileClick}>
                <DropdownToggle className="team-filter-img">
                  <img src={FilterIcon} />
                </DropdownToggle>
                <DropdownMenu className="team-filter-menu">
                  <DropdownItem>
                    <div className="d-flex justify-content-between align-items-center team-filter-cancel">
                      <h4 className="team-filter-text"> Filter </h4>
                      <img
                        src={CancelIcon}
                        className="img-fluid team-cancel"
                      />
                    </div>
                    <div className="team-dashboard-button d-flex justify-content-center align-items-center d-block d-md-block d-lg-none">
                      <div className="">
                        <div className="team-input-wrapper d-flex justify-content-center align-items-center">
                          <img className="team-input-img" src={SearchIcon} />
                          <input
                            className="team-input"
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                        <div className="btn-team-wrapper">
                          <div
                            className="btn-group btn-toggle custom-toggle-button custom-switch"
                            data-toggle="buttons"
                          >
                            <label className="btn btn-default">All</label>
                            <label className="btn btn-default active">
                              Active
                            </label>
                            <label className="btn btn-default">
                              Inactive
                            </label>
                          </div>
                        </div>
                        <Button className="team-apply-btn">Apply</Button>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div> */}

          {!loading && <div className="d-flex align-items-baseline justify-content-center lead-pagination">
            <Pagination
              defaultCurrent={1}
              total={client_list?.paginations?.total_docs}
              pageSize={pageSize}
              onChange={handleChange}
              current={state.current}
            />
          </div>}
        </div>
      </div>
    )}
  </>
  );
};

export default withRouter(ClientDashboard);
