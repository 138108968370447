import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SuccessIcon from "../../../../src/assets/images/inventive_byte/success-invite.png";
import './InviteSuccess.scss'

const InviteSuccess = (props) => {

  useEffect(() => {
    setTimeout(() => {
      props.history.goBack()
    }, 3000);
  },[])
 
  return (
   <div className="invite-succeess-main">
      <div className="d-flex align-items-center justify-content-center flex-column invite-succeess-wrapper">
        <img src={SuccessIcon}/>
        <span className="invite-succeess-text">Invited Successfully</span>
      </div>
   </div>
  );
};

export default withRouter(InviteSuccess);
