import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from "antd";
import Tubelight from "../../../src/assets/images/inventive_byte/tubelight.png";
import DashboardIcon from "../../../src/assets/images/inventive_byte/dashboard.svg";
import LeadManagementIcon from "../../../src/assets/images/inventive_byte/lead-management.svg";
import ClientManagementIcon from "../../../src/assets/images/inventive_byte/client-management.svg";
import BlogsIcon from "../../../src/assets/images/inventive_byte/blogs.svg";
import CloseIcon from "../../../src/assets/images/inventive_byte/close-menu.png";
import "./LeftPanel.scss";

const LeftPanel = (props) => {
  const { Header, Content, Footer, Sider } = Layout;
  const currentRoute =
    props.location.pathname.split("/")[
      props.location.pathname.split("/").length - 1
    ];
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    if (currentRoute === "admin") {
      setActiveKey("2");
      return;
    }
    if (currentRoute === "lead") {
      setActiveKey("3");
      return;
    }
    if (currentRoute === "client") {
      setActiveKey("4");
      return;
    }
    if (currentRoute === "blogs") {
      setActiveKey("5");
      return;
    }
  }, [currentRoute]);

  const handleClick = (e) => {
    switch (e.key) {
      case "1":
        props.history.push("/inventive/admin");
        break;
      case "2":
        props.history.push("/inventive/admin");
        break;
      case "3":
        props.history.push("/inventive/lead");
        break;
      case "4":
        props.history.push("/inventive/client");
        break;
      case "5":
        props.history.push("/inventive/blogs");
        break;
      default:
        return e.key;
    }
  };

  return (
    <div className={`${props.isActiveHeader ? "mobile-menu" : "desktop-menu"}`}>
      {/*<Sider collapsible >*/}

      <Menu
        className="left-menu flex flex-column d-lg-flex"
        theme="dark"
        selectedKeys={[activeKey]}
        mode="inline"
      >
        {props.isActiveHeader && (
          <Menu.Item key="1">
            <img
              className="close-menu"
              src={CloseIcon}
              onClick={props.handleMobilePanel}
            />
          </Menu.Item>
        )}

        <Menu.Item key="1" onClick={handleClick} disabled>
          <img className="left-menu-img" src={Tubelight} />
        </Menu.Item>
        <Menu.Item key="2" onClick={handleClick}>
          <img className="left-menu-img" src={DashboardIcon} />
          <p className="left-menu-p">Dashboard</p>
        </Menu.Item>
        <Menu.Item key="3" onClick={handleClick}>
          <img className="left-menu-img" src={LeadManagementIcon} />
          <p className="left-menu-p">Lead Management</p>
        </Menu.Item>
        <Menu.Item key="4" onClick={handleClick}>
          <img className="lef-menu-img" src={ClientManagementIcon} />
          <p className="left-menu-p">Client Management</p>
        </Menu.Item>
        <Menu.Item key="5" onClick={handleClick}>
          <img className="left-menu-img" src={BlogsIcon} />
          <p className="left-menu-p">Blogs</p>
        </Menu.Item>
      </Menu>
      {/*</Sider>*/}
    </div>
  );
};

export default withRouter(LeftPanel);
