import React from 'react'
import { Spin } from 'antd'
import './Loader.scss'
const Loader = () => {

    return (
        <div className='loaderContainer'>
            <Spin className="themeSpinner"/>
        </div>
    )
}

export default Loader;
