import * as actionTypes from "./ActionTypes";
import api from "../../utils/api";

export const getPresignedUrl = (data, file, onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRESIGNED_URL_INIT,
    });
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/admin/getPresignedUrl?key_type=${data}&file_name=${file?.name}` 
      )
      .then(async (res) => {
        dispatch({
          type: actionTypes.GET_PRESIGNED_URL_SUCCESS,
          payload: { key: data, res },
        });
        console.log(res,"resresresresresres")
        const formData = new FormData();
        formData.append("AWSAccessKeyId", res.data.fields.AWSAccessKeyId);
        formData.append("key", res.data.fields.key);
        formData.append("policy", res.data.fields.policy);
        formData.append("signature", res.data.fields.signature);
        formData.append("file", file);
        
        await api.postwithReqForm(res.data.url, formData).then(resp => {
          if(res.result && onSuccess) {
            onSuccess(res.data.fields.key)
          }
        }).catch(err => {
          if(res.result && onSuccess) {
            onSuccess(res.data.fields.key)
          }
        })
        return
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PRESIGNED_URL_FAILED,
        });
        throw error?.response?.data?.error || error?.message;
      });
  };
};
