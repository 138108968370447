import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import { Modal } from 'react-bootstrap'
import InventiveLogo from '../../../src/assets/images/inventive_byte/inventive-logo2.png'
import EmailTempImg from '../../../src/assets/images/inventive_byte/email-temp.png'
import SocialIcons from '../../../src/assets/images/inventive_byte/social-icon.png'
import './EmailTemp.scss'

const EmailTemp = props => {
    const [active, setActive] = useState(false)

    return (
        <div className="email-temp-wrapper flex justify-content-center flex-column">
            <div className="email-temp-img">
                <img src={InventiveLogo}/>
            </div>
            <div className="email-temp-main flex align-items-center flex-column">
                <div>
                    <img src={EmailTempImg}/>
                </div>
                <div className="email-temp-name">
                    <h4 className="email-temp-text">Hello, Ankit!</h4>
                    <p className="email-temp-desc">Welcome to Team Inventive Byte. We make founder’s dreams into realities and now you will get to join us in our mission. Let’s change the world!</p>
                    <Button className="email-temp-button">Sign Up</Button>
                </div>
                <div className="email-temp-message">
                    <div className="email-temp-msg">
                        <p className="message">If this message was not meant for you, please contact us immediately at<a className="email" href="mailto:support@inventivebyte.com">support@inventivebyte.com</a></p>
                    </div>
                    <div className="email-temp-greet flex flex-column">
                        <span className="email-temp-thankyou">Thank you,</span>
                        <span className="email-temp-thankyou">Team Inventive Byte </span>
                    </div>
                </div>
            </div>
            <div className="email-temp-footer flex flex-column justify-content-center align-items-center">
                <div className="email-temp-social">
                    <img src={SocialIcons}/>
                </div>
                <div className="email-temp-email">
                    <a className="info-email" href="mailto:info@inventivebyte.com">info@inventivebyte.com</a>
                </div>
                <div className="email-temp-copyrights flex">
                    <span className="rights">©2021 Inventive Byte. All Right Reserved.</span>
                    <span className="conditions">Terms & Conditions</span>
                    <span className="policy">Privacy Policy</span>
                </div>
            </div>
        </div>
    )
}

export default withRouter(EmailTemp)
