import * as React from "react";

function UploadImgIcon(props) {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_162_1146)">
        <circle cx="23.5" cy="19.5" r="19.5" fill="white" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.704 13.6757C18.7711 13.5823 18.8457 13.468 18.9408 13.3139C18.9756 13.2576 19.0703 13.1014 19.1408 12.985L19.1409 12.9849L19.2204 12.8539C20.0062 11.5685 20.5774 10.9785 21.6074 10.9785H26.347V12.8743H21.6074C21.5252 12.8743 21.2666 13.1414 20.8379 13.8427L20.769 13.9564C20.6978 14.0738 20.5941 14.2449 20.5542 14.3096C20.4376 14.4984 20.3408 14.6468 20.243 14.7827C19.8181 15.3734 19.3934 15.7181 18.7637 15.7181H15.9199C15.3964 15.7181 14.972 16.1425 14.972 16.666V25.1973C14.972 25.7208 15.3964 26.1452 15.9199 26.1452H31.0866C31.6101 26.1452 32.0345 25.7208 32.0345 25.1973V19.5098H33.9303V25.1973C33.9303 26.7678 32.6572 28.041 31.0866 28.041H15.9199C14.3494 28.041 13.0762 26.7678 13.0762 25.1973V16.666C13.0762 15.0955 14.3494 13.8223 15.9199 13.8223H18.5894C18.621 13.7862 18.6597 13.7373 18.704 13.6757ZM28.243 15.7196V13.8237H30.1382V11.9285H32.0341V13.8237H33.9305V15.7196H32.0341V17.616H30.1382V15.7196H28.243ZM23.4962 25.1976C20.8786 25.1976 18.7567 23.0756 18.7567 20.458C18.7567 17.8404 20.8786 15.7184 23.4962 15.7184C26.1138 15.7184 28.2358 17.8404 28.2358 20.458C28.2358 23.0756 26.1138 25.1976 23.4962 25.1976ZM23.505 23.2965C25.0756 23.2965 26.3488 22.0233 26.3488 20.4528C26.3488 18.8822 25.0756 17.609 23.505 17.609C21.9344 17.609 20.6613 18.8822 20.6613 20.4528C20.6613 22.0233 21.9344 23.2965 23.505 23.2965Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_d_162_1146"
          x="0"
          y="0"
          width="47"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_162_1146"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_162_1146"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default UploadImgIcon;
