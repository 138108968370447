import React, { useCallback, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Pagination, Spin, Tooltip } from "antd";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import TeamIcon from "../../../../src/assets/images/inventive_byte/team-icon.png";
import LeadIcon from "../../../../src/assets/images/inventive_byte/lead-icon.png";
import LeadActive from "../../../../src/assets/images/inventive_byte/lead-active.png";
import LeadInactive from "../../../../src/assets/images/inventive_byte/lead-inactive.png";
import DropDownIcon from "../../../../src/assets/images/inventive_byte/drop-down-icon.png";
import SearchIcon from "../../../../src/assets/images/inventive_byte/search.png";
import FilterIcon from "../../../../src/assets/images/inventive_byte/filter-icon.png";
import CancelIcon from "../../../../src/assets/images/inventive_byte/cancel-icon2.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import LeadMemberDetailMain from "./LeadMemberDetail/LeadMemberDetailMain";
import * as LeadActions from "../../../redux/LeadFlowControl/LeadActions";
import "./LeadDashboard.scss";
import moment from "moment";

const LeadDashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { lead_member_list, loading } = useSelector((state) => state.LeadReducers);
  const [activeDetail, setActiveDetail] = useState(false);
  const [activeProfile, setActiveProfile] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [clientID, setClientID] = useState("");
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
    searchText: ''
  });

  const pageSize = 5;

  useEffect(() => {
    dispatch(LeadActions.getLeadMemberList());
  }, []);

  useEffect(() => {
    setState({
      data: lead_member_list?.paginations?.total_docs,
      totalPage: lead_member_list?.paginations?.total_docs / pageSize,
      current: lead_member_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
      searchText: state?.searchText
    });
    setActiveFilter(activeFilter)
  }, [lead_member_list]);

  const searchHandle = async (e) => {
    const value = e.target.value;
    setState({ ...state, searchText: value });
    await debouncedSearchdata(value);
  };
  const debouncedSearchdata = useCallback(
    debounce(async (searchText) => {
      await dispatch(LeadActions.getLeadMemberFilterList([{ label: 'search', data: searchText }, { label: 'page',data: state.current }]));
    }, 500),
    []
  );

  const handleProfileClick = () => {
    setActiveProfile(!activeProfile);
  };

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: 'page' , data: page },{ label: 'search', data: state.searchText },{ label: 'filter', data: activeFilter }]
    dispatch(await LeadActions.getLeadMemberFilterList(newParams));
  };
  const handleActiveDetailClick = async (id) => {
    // setClientID(id)
    // await dispatch(LeadActions.getProjectDetail(id))
    // setActiveDetail(true);
    history.push(`/inventive/lead/${id}`)
  };

  const handleFilter = async (value) => {
    setActiveFilter(value)
    let newParams = [{ label: 'filter', data: value }, { label: 'page',data: state.current }];
    dispatch(await LeadActions.getLeadMemberFilterList(newParams));
  };

  return (
    <>
      {activeDetail ? (
        <LeadMemberDetailMain clientID={clientID}/>
      ) : (
        <div className="lead-main">
          <div className="lead-wrapper">
            {loading &&
             <Spin className="lead-spin"/>}
            <div className="lead-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
              <div className="lead-icon-wrapper flex align-items-center justify-content-start">
                <img className="lead-icon" src={TeamIcon} />{" "}
                <span className="lead-icon-text">Lead Management</span>
              </div>

              <div className="flex d-none d-md-none d-lg-flex filterBoxContainer">
                <div className="lead-input-wrapper">
                  <img className="lead-input-img" src={SearchIcon} style={{width: 20}}/>
                  <input
                    className="lead-input"
                    type="text"
                    placeholder="Search"
                    onChange={searchHandle}
                  />
                </div>
                <div className="btn-lead-wrapper">
                  <div
                    className="btn-group btn-toggle custom-toggle-button custom-switch"
                    data-toggle="buttons"
                  >
                    <label
                      className={activeFilter === "all" ? "btn btn-default active active-all" : "btn btn-default"}
                      onClick={() => {
                        handleFilter("all");
                      }}
                    >
                      All
                    </label>
                    <label
                      className={activeFilter === "active" ? "btn btn-default active" : "btn btn-default"}
                      onClick={() => {
                        handleFilter("active");
                      }}
                    >
                      Active
                    </label>
                    <label
                      className={activeFilter === "inactive" ? "btn btn-default active active-inactive" : "btn btn-default"}
                      onClick={() => {
                        handleFilter("inactive");
                      }}
                    >
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="lead-desktop-wrap">
              <div className="main-lead-wrapper">
                <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                  <div className="th-data">
                    No{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Full Name{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Email Address{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Current Stage{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Signup Date{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data justify-content-center">
                    Status{" "}
                    <span className="img-icon">
                      <img src={DropDownIcon} />{" "}
                    </span>{" "}
                  </div>
                </div>
              </div>
              {lead_member_list?.data?.map((data, index) => {
                return (
                  index >= state.minIndex &&
                  index < state.maxIndex && (
                    <div className="table-body flex justify-content-start align-items-center">
                      <div className="table-data order-0 order-lg-0">
                        <p
                          className="username mb-0">
                          {(state.current - 1) * 5 + index + 1}
                        </p>
                      </div>
                      <div className="table-data order-1 order-lg-1">
                        <div
                          className="d-flex align-items-center username"
                          onClick={()=>{handleActiveDetailClick(data?.id)}}
                        >
                          {!data?.profile_image ? (
                            <p className="d-flex align-items-center justify-content-center user-icon">
                              {data?.first_name?.charAt(0).toUpperCase()}
                            </p>
                          ) : (
                            <img
                              className="blog-img"
                              src={
                                `https://d3u1baow9deq5h.cloudfront.net/` +
                                data?.profile_image
                              }
                            />
                          )}
                          <Tooltip title={`${data?.first_name}  ${data?.last_name}`} placement="top">
                            <p className="user-name">
                              {data?.first_name} {data?.last_name}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="table-data order-3 order-lg-2">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Email Address
                          </label>
                          <div className="email flex align-items-center">
                            <div className="tooltipEmail">{data?.email}{" "}</div>
                            {data?.active ? (
                              <img src={LeadActive} />
                            ) : (
                              <img src={LeadInactive} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="table-data order-4 order-lg-3">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Current Stage
                          </label>
                          <div className="stage flex align-items-center">
                            {data?.boarding_stage ? (
                              <span className="stage-number">
                                {data?.boarding_stage}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                            {data?.boarding_stage === 1
                              ? "Shared Idea"
                              : data?.boarding_stage === 2
                              ? "Schedule Call"
                              : data?.boarding_stage === 3
                              ? "Agreement"
                              : data?.boarding_stage === 4
                              ? "Invoice"
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="table-data order-5 order-lg-4">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                            Signup Date
                          </label>
                          <div className="date">
                            {moment(data?.created_at).format("DD-MM-YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="table-data order-2 order-lg-5 flex justify-content-center">
                        <div className="flex blog-card justify-content-center">
                          {data?.active ? (
                            <Button className="blog-button-active">
                              Active
                            </Button>
                          ) : (
                            <Button className="blog-button-draft">
                              Inactive
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
{/* 
            <div className="lead-filter-main d-block d-lg-none">
              <div className="lead-filter-wrapper">
                <Dropdown isOpen={activeProfile} toggle={handleProfileClick}>
                  <DropdownToggle className="team-filter-img">
                    <img src={FilterIcon} />
                  </DropdownToggle>
                  <DropdownMenu className="lead-filter-menu">
                    <DropdownItem>
                      <div className="flex justify-content-between align-items-center lead-filter-cancel">
                        <h4 className="lead-filter-text"> Filter </h4>
                        <img
                          src={CancelIcon}
                          className="img-fluid lead-cancel"
                        />
                      </div>
                      <div className="lead-dashboard-button flex justify-content-center align-items-center d-block d-md-block d-lg-none">
                        <div className="">
                          <div className="lead-input-wrapper flex justify-content-center align-items-center">
                            <img className="lead-input-img" src={SearchIcon} />
                            <input
                              className="lead-input"
                              type="text"
                              placeholder="Search"
                            />
                          </div>
                          <div className="btn-lead-wrapper">
                            <div
                              className="btn-group btn-toggle custom-toggle-button custom-switch"
                              data-toggle="buttons"
                            >
                              <label className="btn btn-default">All</label>
                              <label className="btn btn-default active">
                                Active
                              </label>
                              <label className="btn btn-default">
                                Inactive
                              </label>
                            </div>
                          </div>
                          <Button className="lead-apply-btn">Apply</Button>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div> */}

            {!loading && lead_member_list?.paginations?.total_docs > 5 && <div className="flex align-items-baseline justify-content-center lead-pagination">
              <Pagination
                defaultCurrent={1}
                total={lead_member_list?.paginations?.total_docs}
                pageSize={pageSize}
                onChange={handleChange}
                current={state.current}
              />
            </div>}
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(LeadDashboard);
