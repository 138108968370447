export const GET_CLIENT_LIST_INIT= 'GET_CLIENT_LIST_INIT'
export const GET_CLIENT_LIST_SUCCESS= 'GET_CLIENT_LIST_SUCCESS'
export const GET_CLIENT_LIST_FAILED= 'GET_CLIENT_LIST_FAILED'
export const GET_CLIENT_DETAIL_INIT= 'GET_CLIENT_DETAIL_INIT'
export const GET_CLIENT_DETAIL_SUCCESS= 'GET_CLIENT_DETAIL_SUCCESS'
export const GET_CLIENT_DETAIL_FAILED= 'GET_CLIENT_DETAIL_FAILED'
export const GET_UPDATE_LIST_INIT= 'GET_UPDATE_LIST_INIT'
export const GET_UPDATE_LIST_SUCCESS= 'GET_UPDATE_LIST_SUCCESS'
export const GET_UPDATE_LIST_FAILED= 'GET_UPDATE_LIST_FAILED'
export const ADD_UPDATES_INIT= 'ADD_UPDATES_INIT'
export const ADD_UPDATES_SUCCESS= 'ADD_UPDATES_SUCCESS'
export const ADD_UPDATES_FAILED= 'ADD_UPDATES_FAILED'
export const EDIT_UPDATE_INIT= 'EDIT_UPDATE_INIT'
export const EDIT_UPDATE_SUCCESS= 'EDIT_UPDATE_SUCCESS'
export const EDIT_UPDATE_FAILED= 'EDIT_UPDATE_FAILED'
export const GET_SINGLE_UPDATE_INIT= 'GET_SINGLE_UPDATE_INIT'
export const GET_SINGLE_UPDATE_SUCCESS= 'GET_SINGLE_UPDATE_SUCCESS'
export const GET_SINGLE_UPDATE_FAILED= 'GET_SINGLE_UPDATE_FAILED'
export const GET_REVIEWS_INIT= 'GET_REVIEWS_INIT'
export const GET_REVIEWS_SUCCESS= 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAILED= 'GET_REVIEWS_FAILED'
export const POST_REVIEWS_INIT= 'POST_REVIEWS_INIT'
export const POST_REVIEWS_SUCCESS= 'POST_REVIEWS_SUCCESS'
export const POST_REVIEWS_FAILED= 'POST_REVIEWS_FAILED'
export const GET_REFERRALS_INIT= 'GET_REFERRALS_INIT'
export const GET_REFERRALS_SUCCESS= 'GET_REFERRALS_SUCCESS'
export const GET_REFERRALS_FAILED= 'GET_REFERRALS_FAILED'
export const EDIT_REFERRAL_STATUS_INIT= 'EDIT_REFERRAL_STATUS_INIT'
export const EDIT_REFERRAL_STATUS_SUCCESS= 'EDIT_REFERRAL_STATUS_SUCCESS'
export const EDIT_REFERRAL_STATUS_FAILED= 'EDIT_REFERRAL_STATUS_FAILED'
export const GET_FEEDBACKS_INIT= 'GET_FEEDBACKS_INIT'
export const GET_FEEDBACKS_SUCCESS= 'GET_FEEDBACKS_SUCCESS'
export const GET_FEEDBACKS_FAILED= 'GET_FEEDBACKS_FAILED'
export const GET_SINGLE_FEEDBACK_INIT= 'GET_SINGLE_FEEDBACK_INIT'
export const GET_SINGLE_FEEDBACK_SUCCESS= 'GET_SINGLE_FEEDBACK_SUCCESS'
export const GET_SINGLE_FEEDBACK_FAILED= 'GET_SINGLE_FEEDBACK_FAILED'
export const EDIT_SINGLE_FEEDBACK_INIT= 'EDIT_SINGLE_FEEDBACK_INIT'
export const EDIT_SINGLE_FEEDBACK_SUCCESS= 'EDIT_SINGLE_FEEDBACK_SUCCESS'
export const EDIT_SINGLE_FEEDBACK_FAILED= 'EDIT_SINGLE_FEEDBACK_FAILED'
export const GET_SUPPORT_LIST_INIT= 'GET_SUPPORT_LIST_INIT'
export const GET_SUPPORT_LIST_SUCCESS= 'GET_SUPPORT_LIST_SUCCESS'
export const GET_SUPPORT_LIST_FAILED= 'GET_SUPPORT_LIST_FAILED'
export const POST_SUPPORT_INIT= 'GET_SUPPORT_LIST_INIT'
export const POST_SUPPORT_SUCCESS= 'GET_SUPPORT_LIST_SUCCESS'
export const POST_SUPPORT_FAILED= 'GET_SUPPORT_LIST_FAILED'