import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  presigned_url: {},
};

const UploadReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRESIGNED_URL_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PRESIGNED_URL_SUCCESS:
      let newState = {...state, loading:false}
      let new_presigned_url = state.presigned_url
      new_presigned_url[action.payload.key] =  action.payload.res
      newState['presigned_url'] = new_presigned_url
      return newState;
    case actionTypes.GET_PRESIGNED_URL_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default UploadReducers;
