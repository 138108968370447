import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  lead_member_list: [],
  project_detail: {},
  agreement_list: [],
  invoice_list: [],
  team_list: [],
  add_team_list: [],
  add_team_res: {},
  get_agreement_detail: {},
  project_types: [],
  project_cat: [],
  project_phase: [],
  project_feature: []
};

const LeadReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEAD_MEMBERS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEAD_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        lead_member_list: action.payload,
      };
    case actionTypes.GET_LEAD_MEMBERS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PROJECT_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        project_detail: action.payload,
      };
    case actionTypes.GET_PROJECT_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_AGREEMENT_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_AGREEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        agreement_list: action.payload,
      };
    case actionTypes.GET_AGREEMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVOICE_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_list: action.payload,
      };
    case actionTypes.GET_INVOICE_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TEAM_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        team_list: action.payload,
      };
    case actionTypes.GET_TEAM_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ADD_TEAM_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ADD_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        add_team_list: action.payload,
      };
    case actionTypes.GET_ADD_TEAM_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        add_team_res: action.payload,
      };
    case actionTypes.GET_AGREEMENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        get_agreement_detail: action.payload,
      };
    case actionTypes.GET_AGREEMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_PROJECT_TYPE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        project_types: action.payload,
      };
    case actionTypes.GET_PROJECT_TYPE_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_PROJECT_CAT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_CAT_SUCCESS:
      return {
        ...state,
        loading: false,
        project_cat: action.payload,
      };
    case actionTypes.GET_PROJECT_CAT_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_PROJECT_PHASE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_PHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        project_phase: action.payload,
      };
    case actionTypes.GET_PROJECT_PHASE_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.GET_PROJECT_FEATURE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECT_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        project_feature: action.payload,
      };
    case actionTypes.GET_PROJECT_FEATURE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default LeadReducers;
