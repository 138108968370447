import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message, Pagination, Spin } from "antd";
import moment from "moment";
import EditIcon from "../../../../../assets/images/inventive_byte/edit-update-icon.svg";
import DeleteIcon from "../../../../../assets/images/inventive_byte/blog-delete.png";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import UpdateIcon from "../../../../../assets/images/inventive_byte/update-icon.svg";
import * as ClientActions from "../../../../../redux/ClientFlowControl/ClientActions";
import api from "../../../../../utils/api";
import "./UpdateList.scss";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const UpdateList = (props) => {
  const dispatch = useDispatch();
  const { update_list, loading } = useSelector((state) => state.ClientReducers);
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
    searchText: "",
  });

  const pageSize = 5;

  useEffect(() => {
    dispatch(ClientActions.getUpdateList(props.clientID));
  }, []);

  useEffect(() => {
    setState({
      data: update_list?.paginations?.total_docs,
      totalPage: update_list?.paginations?.total_docs / pageSize,
      current: update_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
      searchText: state?.searchText,
    });
  }, [update_list]);

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    let newParams = [{ label: "page", data: page }];
    dispatch(await ClientActions.getUpdateList(props.clientID, newParams));
  };

  const deleteUpdate = async (id) => {
    await api
      .delete(`${process.env.REACT_APP_API_URL}/admin/update/${id}`)
      .then((res) => {
        if (res.result === true) {
          message.success(res.data);
          dispatch(ClientActions.getUpdateList(props.clientID));
        } else {
          message.error(res.data);
        }
      });
  };

  const editUpdate = (id) => {
    props.history.push(`/inventive/client/edit-update/${id}`)
  }

  const handleDetailClick = (id) => {
    props.history.push(`/inventive/client/update-detail/${id}`);
  };
  return (
    <div className="updates-main">
      <Spin className="update-spin" spinning={loading}>
        <div className="updates-desktop-wrap">
          <div className="main-lead-wrapper">
            <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
              <div className="th-data">
                Updates{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>
              </div>
              <div className="th-data">
                Submission Date{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>
              </div>
              <div className="th-data">
                Status{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>{" "}
              </div>
              <div className="th-data d-flex justify-content-center">
                Action{" "}
                <span className="img-icon">
                  <img src={DropDownIcon} />{" "}
                </span>{" "}
              </div>
            </div>
          </div>
          {update_list?.data?.length ? update_list?.data?.map((data, index) => {
            return (
              index >= state.minIndex &&
              index < state.maxIndex && (
                <div className="table-body flex justify-content-start align-items-center">
                  <div className="table-data">
                    <div className="username" onClick={()=>{handleDetailClick(data?.id)}}>
                      <img className="updates-img" src={UpdateIcon} />
                      <span>{data?.name}</span>
                    </div>
                  </div>
                  <div className="table-data">
                    <div className="flex updates-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Submission Date
                      </label>
                      <div className="amount flex align-items-center">
                        {moment(data?.created_at).format("MM-DD-YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="table-data">
                    <div className="flex updates-card">
                      <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                        Status
                      </label>
                      <div className="status">
                        {data?.draft === false ? (
                          <span className="status-sent">Sent</span>
                        ) : (
                          <span className="status-draft">Draft</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="table-data flex justify-content-center">
                    <div className="flex updates-card justify-content-center align-items-center">
                      {/*<img src={DownloadIcon} />*/}
                      <img className="img-fluid mr-2" src={EditIcon} alt="" onClick={()=>{editUpdate(data?.id)}}/>
                      <img
                        className="img-fluid"
                        src={DeleteIcon}
                        onClick={() => {
                          deleteUpdate(data?.id);
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )
            );
          }): 
            <NoDataFound />
          }
        </div>
        {update_list?.paginations?.total_docs > 5 ? <div className="flex align-items-baseline justify-content-center updates-pagination">
          <Pagination
            defaultCurrent={1}
            total={update_list?.paginations?.total_docs}
            pageSize={pageSize}
            onChange={handleChange}
            current={state.current}
          />
        </div>:null}
      </Spin>
    </div>
  );
};

export default withRouter(UpdateList);
