import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const getLeadMemberList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_LEAD_MEMBERS_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/leads`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_LEAD_MEMBERS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_LEAD_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getLeadMemberFilterList = (data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/leads`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_LEAD_MEMBERS_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_LEAD_MEMBERS_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_LEAD_MEMBERS_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const sendReminderLead = (id, data, onSuccess, onFail) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/boardingReminder/${id}`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      await api
      .getwithReqForm(url.href)
      .then(res => {
        if(res.data) {
          onSuccess(res.data);
        }else {
          onFail(res.error);
        }
        return
      })
      .catch(error => {
        onFail(error?.message)
        throw error?.response?.data?.error || error?.message
      })
    }

  }

  export const getProjectDetail = (id, onSuccess) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_DETAIL_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/client/`+id)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PROJECT_DETAIL_SUCCESS,
            payload: res,
          })
          if(res.result && onSuccess) {
            onSuccess(res.result)
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_DETAIL_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getAgreementList = (id, data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/agreements/${id}`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_AGREEMENT_LIST_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_AGREEMENT_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_AGREEMENT_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getInvoiceList = (id, data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/invoices/${id}`)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_INVOICE_LIST_INIT,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_INVOICE_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_INVOICE_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getTeamList = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_TEAM_LIST_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/team/`+id)
        .then(res => {
          dispatch({
            type: actionTypes.GET_TEAM_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_TEAM_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const deleteTeamMember = (clientId, teamMemberId) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.DELETE_TEAM_MEMBER_INIT,
      })
      await api
        .delete(`${process.env.REACT_APP_API_URL}/admin/team/${clientId}/${teamMemberId}`)
        .then(res => {
          dispatch({
            type: actionTypes.DELETE_TEAM_MEMBER_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.DELETE_TEAM_MEMBER_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getAddTeamMemberList = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_ADD_TEAM_LIST_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/addTeamMember/`+id)
        .then(res => {
          dispatch({
            type: actionTypes.GET_ADD_TEAM_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_ADD_TEAM_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const addTeamMemberList = (id,data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_TEAM_MEMBER_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/addTeamMember/${id}`, data)
        .then(res => {
          dispatch({
            type: actionTypes.ADD_TEAM_MEMBER_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_TEAM_MEMBER_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getAddTeamFilterList = (clientId,data) => {
    let url = new URL(`${process.env.REACT_APP_API_URL}/admin/addTeamMember/`+clientId)
    data?.map((x)=>{
      url.searchParams.set(x.label,x.data);
    }) 
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_ADD_TEAM_LIST_FAILED,
      })
      await api
        .getwithReqForm(url.href)
        .then(res => {
          dispatch({
            type: actionTypes.GET_ADD_TEAM_LIST_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_ADD_TEAM_LIST_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const addAgreement = (id,data, onSuccess, onFail) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_AGREEMENT_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/agreements/${id}`, data)
        .then(res => {
          if(res.result) {
            dispatch({
              type: actionTypes.ADD_AGREEMENT_SUCCESS,
              payload: res,
            })
            if(onSuccess) {
              onSuccess(res.result);
            }
          }else {
            dispatch({
              type: actionTypes.ADD_AGREEMENT_FAILED,
            })
            if(onFail) {
              onFail(res.error);
            }
          }
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_AGREEMENT_FAILED,
          })
          if(onFail) {
            onFail(error?.message)
          }
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getAgreementDetail = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_AGREEMENT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/agreement/`+id)
        .then(res => {
          dispatch({
            type: actionTypes.GET_AGREEMENT_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_AGREEMENT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectType= () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_TYPE_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projectType`)
        .then(res => {
          console.log(res,"resss")
          dispatch({
            type: actionTypes.GET_PROJECT_TYPE_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_TYPE_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectCategory = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_CAT_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projectCategory`)
        .then(res => {
          console.log(res,"resss")
          dispatch({
            type: actionTypes.GET_PROJECT_CAT_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_CAT_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectPhase = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_PHASE_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projectPhase`)
        .then(res => {
          console.log(res,"resss")
          dispatch({
            type: actionTypes.GET_PROJECT_PHASE_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_PHASE_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const getProjectFeatures = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PROJECT_FEATURE_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/projectFeature`)
        .then(res => {
          console.log(res,"resss")
          dispatch({
            type: actionTypes.GET_PROJECT_FEATURE_SUCCESS,
            payload: res,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROJECT_FEATURE_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
  }

  export const saveClientProject = (id,data, onSuccess, onFail) => {
    return async dispatch => {
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/clientProject/${id}`, data)
        .then(res => {
          if(res.result) {
            onSuccess(res.result);
          }else {
            onFail(res.error);
          }
          return
        })
        .catch(error => {
          onFail(error?.message)
          throw error?.response?.data?.error || error?.message
        })
    }
  }