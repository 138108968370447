import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Spin, Pagination, Popconfirm, message } from "antd";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import BlogIcon from "../../../../src/assets/images/inventive_byte/blog-icon.png";
import SearchIcon from "../../../../src/assets/images/inventive_byte/search.png";
import AddBlog from "../../../../src/assets/images/inventive_byte/add-blog.png";
import BlogIcons from "../../../../src/assets/images/Icons/BlogIcon";
import BlogPause from "../../../../src/assets/images/inventive_byte/blog-pause.png";
import BlogPlay from "../../../../src/assets/images/inventive_byte/blog-play.png";
import BlogEdit from "../../../../src/assets/images/inventive_byte/blog-edit.png";
import BlogDelete from "../../../../src/assets/images/inventive_byte/blog-delete.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import AddNewBlog from "./AddNewBlog";
import LeftPanel from "../LeftPanel";
import * as BlogActions from "../../../redux/BlogFlowControl/BlogActions";
import BlogDetails from "./BlogDetails";
import "./BlogList.scss";

const BlogList = (props) => {
  const dispatch = useDispatch();
  const { blog_list, loading } = useSelector((state) => state.BlogReducers);
  const [activeDetail, setActiveDetail] = useState(false);
  const [activeAddBlog, setActiveAddBlog] = useState(false);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [activePublish, setActivePublish] = useState(false);
  const [blogData, setBlogData] = useState({})
  const [state, setState] = useState({
    data: 0,
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });
  const pageSize = 5;
  useEffect(() => {
    dispatch(BlogActions.getBlogList());
  }, []);

  useEffect(() => {
    setState({
      data: blog_list?.paginations?.total_docs,
      totalPage: blog_list?.paginations?.total_docs / pageSize,
      current: blog_list?.paginations?.page,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, [blog_list]);

  const handlePublishBlog = (data) => {
    setBlogData(data)
    setActivePublish(true);
  };

  const handleAddBlog = () => {
    props.history.push('/inventive/blogs/add-blog')
  };

  const handleChange = async (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
    dispatch(await BlogActions.getBlogListWithParam(page));
  };

  const handleActiveDetailClick = () => {
    setActiveDetail(true);
  };
  
  const onDraft = (type, id) => {
    dispatch(BlogActions.editBlog(
      {draft: type}, id,
      () => {
        message.success("Blog updated successfully");
      },
      (err) => {
        message.error(err || "Failed to update blog.");
      }
    ));
  }

  return (
    <>
      <div className="blog-main">
        {activePublish ? (
          <BlogDetails blogData = {blogData}/>
        ) : (
          <div className="blog-wrapper">
            <div className="blog-section flex align-items-center justify-content-between flex-column flex-sm-column flex-md-column flex-lg-row">
              <div className="blog-icon-wrapper flex align-items-center justify-content-start">
                <img className="blog-icon" src={BlogIcon} />{" "}
                <span className="blog-icon-text">Blogs</span>
              </div>

              <div className="flex blogListCon">
                <div className="blog-input-warpper">
                  <img className="blog-input-img" src={SearchIcon} />
                  <input
                    className="blog-input"
                    type="text"
                    placeholder="Search"
                  />
                </div>
                <div>
                  <Button className="blog-button" onClick={handleAddBlog}>
                    <img className="blog-button-img" src={AddBlog} />
                    Add Blog
                  </Button>
                </div>
              </div>
            </div>

            <div className="investor-desktop-wrap">
              {loading && <Spin className="investor-spin" size="large" />}
              <div className="main-table-wrapper">
                <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                  <div className="th-data">
                    No{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Name{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>
                  </div>
                  <div className="th-data">
                    Category{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Published By{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Published Date{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data">
                    Status{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>{" "}
                  </div>
                  <div className="th-data justify-content-center">
                    Action{" "}
                    <span className="img-icon">
                      <img src="" />{" "}
                    </span>{" "}
                  </div>
                </div>
              </div>
              {console.log(blog_list?.data,"blog_list?.data")}
              {blog_list?.data?.map((data, index) => {
                return (
                  index >= state.minIndex &&
                  index < state.maxIndex && (
                    <div className="table-body flex justify-content-start align-items-center">
                      <div className="table-data order-0 order-lg-0">
                        <p
                          className="username mb-0"
                          // onClick={handleActiveDetailClick}
                        >
                          {(state.current - 1) * 5 + index + 1}
                        </p>
                      </div>

                      <div className="table-data order-1 order-lg-1">
                        <div
                          className="username"
                          // onClick={handleActiveDetailClick}
                          style={{display: 'flex', alignItems: 'center'}}
                        >
                          {
                          data?.banner_image ?
                          <img
                            className="blog-img"
                            src={`https://d3u1baow9deq5h.cloudfront.net/${data?.banner_image}`}
                            onClick={()=>{handlePublishBlog(data)}}
                          />:
                          <span className="blog-img"><BlogIcons /></span>}
                          <p style={{margin: 0}}>{data?.author_first_name} {data?.author_last_name}</p>
                        </div>
                      </div>
                      <div className="table-data order-3 order-lg-2">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0">
                            Category
                          </label>
                          <div className="technology">{data?.category}</div>
                        </div>
                      </div>
                      <div className="table-data order-4 order-lg-3">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0">
                            Published by
                          </label>
                          <div className="job">
                            {data?.author_first_name} {data?.author_last_name}
                          </div>
                        </div>
                      </div>
                      <div className="table-data order-5 order-lg-4">
                        <div className="flex blog-card">
                          <label className="d-block d-sm-block d-md-block d-lg-none mb-0">
                            Published Date
                          </label>
                          <div className="date">
                            {moment(data?.publish_date).format("DD-MM-YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="table-data order-2 order-lg-5">
                        {!data?.draft ? (
                          <span className="blog-button">Public</span>
                        ) : (
                          <span className="blog-button-draft">Draft</span>
                        )}
                      </div>
                      <div className="table-data blog-action order-6 order-lg-6">
                        <img className="action-img" src={data?.draft ? BlogPlay : BlogPause} onClick={() => onDraft(!data?.draft, data?.id)}/>
                        <img className="action-img" src={BlogEdit} onClick={()=> props.history.push('/inventive/blogs/add-blog', {id: data?.id})}/>
                        <Popconfirm
                          title="Are you sure to delete this blog?"
                          onConfirm={(e) => dispatch(BlogActions.deleteBlog(data?.id,
                            () => {
                              if(blog_list?.data?.length === 1) {
                                dispatch(BlogActions.getBlogList());
                              }
                              message.success("Blog deleted successfully");
                              // dispatch(BlogActions.getBlogList());
                            },
                            (err) => {
                              message.error(err || "Failed to delete blog.");
                            }
                          ))}
                          onCancel={(e) => console.log(e)}
                          okText="Yes"
                          cancelText="No"
                        >
                           <img className="action-img" src={BlogDelete} />
                        </Popconfirm>
                       
                      </div>
                    </div>
                  )
                );
              })}
              {!loading && <div className="flex align-items-baseline justify-content-center client-feedback-pagination">
                <Pagination
                  defaultCurrent={1}
                  total={state?.data}
                  pageSize={pageSize}
                  onChange={handleChange}
                  current={state.current}
                />
              </div>}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(BlogList);
