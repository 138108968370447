import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { message, Select } from "antd";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import InviteTeamIcon from "../../../../src/assets/images/inventive_byte/invite-team-white.png";
import InviteNowIcon from "../../../../src/assets/images/inventive_byte/invite-team-icon.png";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import * as TeamActions from "../../../redux/TeamFlowControl/TeamActions";
import "./InviteTeamMember.scss";

const InviteTeamMember = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { department_list, project_list, permission_list } = useSelector(
    (state) => state.TeamReducers
  );
  const [inviteMember, setInviteMember] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      department: "",
      projects: [],
    },
  ]);

  const[activeSuccessPage, setActiveSuccessPage] = useState(false)

  useEffect(() => {
    dispatch(TeamActions.getDepartmentList());
    dispatch(TeamActions.getProjectList());
  }, []);

  useEffect(() => {}, [department_list, project_list]);

  const projectLists = project_list?.map((x) => (
    <Option key={x.name} value={x.id}>
      {x.name}
    </Option>
  ));

  const onProjectSelect = (detail, selected) => {
    let newInvite = [...inviteMember];
    newInvite[detail].projects = selected;
    setInviteMember(newInvite);
  };

  const onDepartmentSelect = (detail, selected) => {
    let newInvite = [...inviteMember];
    newInvite[detail].department = selected.target.value;
    setInviteMember(newInvite);
  };

  const onHandleAddMoreMember = () => {
    let newMember = [...inviteMember];
    newMember.push({
      first_name: "",
      last_name: "",
      email: "",
      department: "",
      projects: [],
    });
    setInviteMember(newMember);
  };

  const onHandleChange = (detail, e) => {
    let newInvite = [...inviteMember];
    newInvite[detail][e.target.name] = e.target.value;
    setInviteMember(newInvite);
  };

  const renderInviteMember = (inviteMember) => {
    return inviteMember.map((detail, index) => (
      <div className="input-fields blog-name-wrapper flex" key={index}>
        <div className="flex align-items-center d-none d-md-none d-lg-flex">
          <span className="span-number">{index + 1}</span>
        </div>
        <FormGroup className="blog-fname blog-form-group">
          <Label className="blog-label" for="website">
            First Name
          </Label>
          <Input
            className="blog-input"
            type="text"
            name="first_name"
            id="first_name"
            onChange={(e) => {
              onHandleChange(index, e);
            }}
            value={inviteMember[index]?.first_name}
          />
        </FormGroup>
        <FormGroup className="blog-lname blog-form-group">
          <Label className="blog-label" for="website">
            Last Name
          </Label>
          <Input
            className="blog-input"
            type="text"
            name="last_name"
            id="last_name"
            onChange={(e) => {
              onHandleChange(index, e);
            }}
            value={inviteMember[index]?.last_name}
          />
        </FormGroup>
        <FormGroup className="blog-email blog-form-group">
          <Label className="blog-label" for="website">
            Email Address
          </Label>
          <Input
            className="blog-input"
            type="text"
            name="email"
            id="email"
            onChange={(e) => {
              onHandleChange(index, e);
            }}
            value={inviteMember[index]?.email}
          />
        </FormGroup>
        <FormGroup className="blog-department blog-form-group">
          <Label className="blog-label" for="website">
            Department
          </Label>
          <select
            className="blog-select"
            onChange={(e) => {
              onDepartmentSelect(index, e);
            }}
            name="department"
          >
            <option value={""}></option>
            {department_list?.map((data) => {
              return <option value={data?.id}>{data?.name}</option>;
            })}
          </select>
        </FormGroup>
        <FormGroup className="blog-project blog-form-group">
          <Label className="blog-label" for="website">
            Project
          </Label>
          <Select
          className="select-project"
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags Mode"
            onChange={(e) => {
              onProjectSelect(index, e);
            }}
            maxTagCount="responsive"
          >
            {projectLists}
          </Select>
        </FormGroup>
      </div>
    ));
  };

  const onInviteTeamMember = () => {
    dispatch(TeamActions.addInviteTeamMember(inviteMember, (res) => {
      message.success(res)
      props.history.push('/inventive/invite-team/success')
    }, (err) => {
      message.error(err)
    }));
    
    // setTimeout(() => {
    //   setActiveSuccessPage(false)
    // }, 5000);
    
  };

  return (
   
    <div className="add-team-wrapper">
      <div className="header-left">
        <span
          className="back-button flex align-items-center"
          onClick={() => props.history.goBack()}
          style={{cursor: 'pointer'}}
        >
          <ArrowLeftOutlined /> Back
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between blog-title-wrapper">
        <h5 className="blog-title">
          <img src={InviteTeamIcon} />
          Invite team members
        </h5>
        <Button className="d-flex align-items-center member-button" onClick={onHandleAddMoreMember}><PlusOutlined className="member-button-icon" />Add member</Button>
      </div>
      <div className="blog-card-main">
        <div className="add-blog-card">
          <Form className="blog-form">
            <div className="add-blog-buttons d-flex flex-column">
              {renderInviteMember(inviteMember)}
              <div className="button-group flex align-items-center justify-content-center">
                <Button className="blog-invite" onClick={onInviteTeamMember}>
                  <img className="blog-button-img" src={InviteNowIcon} />
                  Invite Now
                </Button>
                <Button className="button-cancel" onClick={() => props.history.goBack()}>Cancel</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
   
  );
};

export default withRouter(InviteTeamMember);
