import * as actionTypes from './ActionTypes'
import api from '../../utils/api'

export const updatePerkList = data => ({
    type: actionTypes.UPDATE_PERK_LIST,
    payload: data,
})

export const getPerkList = () => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PERK_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/perks`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PERK_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PERK_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
}

export const getPerkDetail = (id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_PERK_DETAIL_INIT,
      })
      await api
        .get(`${process.env.REACT_APP_API_URL}/admin/perks/${id}`)
        .then(res => {
          dispatch({
            type: actionTypes.GET_PERK_DETAIL_SUCCESS,
            payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PERK_DETAIL_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
}

export const addPerk = (data) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_PERK_INIT,
      })
      await api
        .post(`${process.env.REACT_APP_API_URL}/admin/perks`, data)
        .then(res => {
          dispatch({
            type: actionTypes.ADD_PERK_SUCCESS,
            // payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_PERK_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
}

export const deletePerk = (id) => {
    return async dispatch => {
      await api
        .delete(`${process.env.REACT_APP_API_URL}/admin/perks/${id}`)
        .then(async res => {
            console.log(res,"dsfdfsdf")
            if (res.result) {
                // dispatch({
                //   type: actionTypes.POST_INVESTING_STEPS_SUCCESS,
                //   payload: res,
                // })
                return await res
              } else {
                //console.log("get user info failed! message:" + res.message);
                // dispatch({
                //   type: actionTypes.POST_INVESTING_STEPS_FAILED,
                // })
                // if (res.error === 'token expired' && res.is_expired) {
                //   dispatch(fetchInvestorUserLogout())
                //   dispatch({
                //     type: 'SET_AUTH_EXPIRED',
                //   })
                // }
                return false
            }
        })
        .catch(error => {
          throw error?.response?.data?.error || error?.message
        })
    }
}

export const editPerk = (data, id) => {
    return async dispatch => {
      dispatch({
        type: actionTypes.ADD_PERK_INIT,
      })
      await api
        .put(`${process.env.REACT_APP_API_URL}/admin/perks/${id}`, data)
        .then(res => {
          dispatch({
            type: actionTypes.ADD_PERK_SUCCESS,
            // payload: res.data,
          })
          return
        })
        .catch(error => {
          dispatch({
            type: actionTypes.ADD_PERK_FAILED,
          })
          throw error?.response?.data?.error || error?.message
        })
    }
}
