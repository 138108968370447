import fetch from "cross-fetch";

import { getTokenInLocaleStorage } from "./general";

function sendRequest(method, url, body, withToken) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: getTokenInLocaleStorage(),
  };

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers,
    }).then((response) => response.json());
  }
}

function sendRequestForm(method, url, body, withToken) {
  if (body) {
    return fetch(url, {
      method,
      body: body,
    }).then(response => response.json())
  } else {
    return fetch(url, {
      method,
    }).then(response => response.json())
  }
}

function sendRequestFormWithHeader(method, url, body, withToken) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: getTokenInLocaleStorage(),
  };
  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers
    }).then((response) => {
      return response.json();
    });
  }
}

function sendRequestFormFile(method, url, body, withToken) {
  const headers = {
    Authorization: getTokenInLocaleStorage(),
  };
  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers
    }).then((response) => {
      return response.json();
    });
  }
}

function sendRequestFormWithParams(method, url, body, withToken, params) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: getTokenInLocaleStorage(),
  };
  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
      params:{}
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers,
      params
    }).then((response) => {
      return response.json();
    });
  }
}

function sendFile(url, file, fileType) {
  const headers = {
    Authorization: getTokenInLocaleStorage(),
  };
  const formData = new FormData();
  formData.append("file_obj", file);
  formData.append("type", fileType);
  return fetch(url, {
    method: "POST",
    headers,
    body: formData,
  }).then((response) => response.json());
}

export default {
  getWithoutToken: (url, body) => {
    return sendRequest("GET", url, body, false);
  },
  post: (url, body) => {
    return sendRequest("POST", url, body, true);
  },
  postWithoutToken: (url, body) => {
    return sendRequest("POST", url, body, false);
  },
  patch: (url, body) => {
    return sendRequest("PATCH", url, body, true);
  },
  put: (url, body) => {
    return sendRequest("PUT", url, body, true);
  },
  delete: (url, body) => {
    return sendRequest("DELETE", url, body, true);
  },
  get: (url, body) => {
    return sendRequest("GET", url, body, true);
  },
  postwithReqForm: (url, body) => {
    return sendRequestForm("POST", url, body, true);
  },
  getwithReqForm: (url, body) => {
    return sendRequestFormWithHeader("GET", url, body, true);
  },
  postwithReqFormToken: (url, body) => {
    return sendRequestFormFile("POST", url, body, true);
  },
  getwithReqFormParams: (url, body, params) => {
    return sendRequestFormWithParams("GET", url, body, true, params);
  },
  sendFile,
};
