import * as React from 'react'

function CameraIcon(props) {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_504_13592)">
            <circle cx="21.4521" cy="17.4521" r="17.4521" fill="white"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1579 12.2381C17.218 12.1546 17.2847 12.0523 17.3699 11.9144C17.401 11.864 17.4858 11.7241 17.5489 11.6199L17.5489 11.6198L17.62 11.5026C18.3233 10.3522 18.8346 9.82422 19.7564 9.82422H23.9982V11.521H19.7564C19.6828 11.521 19.4514 11.76 19.0677 12.3876L19.006 12.4893C18.9424 12.5944 18.8495 12.7475 18.8138 12.8055C18.7095 12.9745 18.6228 13.1073 18.5353 13.2289C18.155 13.7576 17.7749 14.0661 17.2113 14.0661H14.6662C14.1977 14.0661 13.8178 14.4459 13.8178 14.9144V22.5497C13.8178 23.0183 14.1977 23.3981 14.6662 23.3981H28.2401C28.7086 23.3981 29.0885 23.0183 29.0885 22.5497V17.4595H30.7852V22.5497C30.7852 23.9554 29.6457 25.0948 28.2401 25.0948H14.6662C13.2606 25.0948 12.1211 23.9554 12.1211 22.5497V14.9144C12.1211 13.5088 13.2606 12.3693 14.6662 12.3693H17.0554C17.0836 12.337 17.1182 12.2932 17.1579 12.2381ZM25.695 14.0677V12.3709H27.3928V10.6731H29.0896V12.3709H30.7852V14.0677H29.0896V15.7633H27.3928V14.0677H25.695ZM21.4526 22.5507C19.1099 22.5507 17.2108 20.6515 17.2108 18.3088C17.2108 15.9661 19.1099 14.067 21.4526 14.067C23.7953 14.067 25.6945 15.9661 25.6945 18.3088C25.6945 20.6515 23.7953 22.5507 21.4526 22.5507ZM21.4537 20.8507C22.8593 20.8507 23.9988 19.7113 23.9988 18.3056C23.9988 16.9 22.8593 15.7605 21.4537 15.7605C20.0481 15.7605 18.9086 16.9 18.9086 18.3056C18.9086 19.7113 20.0481 20.8507 21.4537 20.8507Z" fill="black"/>
            <defs>
            <filter id="filter0_d_504_13592" x="0" y="0" width="42.9043" height="42.9062" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_504_13592"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_504_13592" result="shape"/>
            </filter>
            </defs>
        </svg>
    )
}

export default CameraIcon
