import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  user_permission: [],
  project_counts: {},
  get_monthly_account: [],
  get_pending_agreement: [],
  pendingAgreementLoading: false,
  monthlyLoading: false,
  projectCountLoading: false,
  user: null
};

const DashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PERMISSION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user_permission: action.payload,
        user: action.user
      };
    case actionTypes.GET_USER_PERMISSION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PROJECT_COUNT_INIT:
      return {
        ...state,
        projectCountLoading: true,
      };
    case actionTypes.GET_PROJECT_COUNT_SUCCESS:
      return {
        ...state,
        projectCountLoading: false,
        project_counts: action.payload,
      };
    case actionTypes.GET_PROJECT_COUNT_FAILED:
      return {
        ...state,
        projectCountLoading: false,
      };
    case actionTypes.GET_MONTHLY_ACCOUNT_INIT:
      return {
        ...state,
        monthlyLoading: true,
      };
    case actionTypes.GET_MONTHLY_ACCOUNT_SUCCESS:
      return {
        ...state,
        monthlyLoading: false,
        get_monthly_account: action.payload,
      };
    case actionTypes.GET_MONTHLY_ACCOUNT_FAILED:
      return {
        ...state,
        monthlyLoading: false,
      };
    case actionTypes.GET_PENDING_AGREEMENT_INIT:
      return {
        ...state,
        pendingAgreementLoading: true,
      };
    case actionTypes.GET_PENDING_AGREEMENT_SUCCESS:
      return {
        ...state,
        pendingAgreementLoading: false,
        get_pending_agreement: action.payload,
      };
    case actionTypes.GET_PENDING_AGREEMENT_FAILED:
      return {
        ...state,
        pendingAgreementLoading: false,
      };
    default:
      return { ...state };
  }
};

export default DashboardReducers;
