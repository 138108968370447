import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import LeadIcon from "../../../../../assets/images/inventive_byte/acc-pen.png";
import DropDownIcon from "../../../../../assets/images/inventive_byte/drop-down-icon.png";
import CrossIcon from "../../../../../assets/images/inventive_byte/cross-icon.svg";
import DownloadIcon from "../../../../../assets/images/inventive_byte/download.png";
import * as LeadActions from "../../../../../redux/LeadFlowControl/LeadActions";
import api from "../../../../../utils/api";
import "./TeamList.scss";
import NoDataFound from "../../../../../Components/NoDataFound/NoDataFound";

const TeamList = (props) => {
  const dispatch = useDispatch();
  const { team_list, loading } = useSelector((state) => state.LeadReducers);

  useEffect(() => {
    dispatch(LeadActions.getTeamList(props.clientID));
  }, []);

  useEffect(() => {}, [team_list]);

  const deleteTeamMember = async (id) => {
    await api
      .delete(
        `${process.env.REACT_APP_API_URL}/admin/team/${props.clientID}/${id}`
      )
      .then((res) => {
        if (res.data) {
          message.success(res.data);
          dispatch(LeadActions.getTeamList(props.clientID));
        }
      });
  };

  return (
    <div className="team-main">
      <div className="team-list-wrap">
        <div className="main-lead-wrapper">
        {loading && <Spin className="team-spin" />}
          <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
            <div className="th-data">
              Members Name{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Email Address{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>
            </div>
            <div className="th-data">
              Department{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
            <div className="th-data d-flex justify-content-center">
              Action{" "}
              <span className="img-icon">
                <img src={DropDownIcon} />{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        {team_list?.data?.length ? team_list?.data?.map((data) => {
          return (
            <div className="table-body flex justify-content-start align-items-center">
              <div className="table-data">
                <div className="username" onClick="">
                  <img className="team-img" src={LeadIcon} />
                  {data?.first_name} {data?.last_name}
                </div>
              </div>
              <div className="table-data">
                <div className="flex team-card">
                  <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                    Email Address
                  </label>
                  <div className="amount flex align-items-center">
                    {data?.email}
                  </div>
                </div>
              </div>

              <div className="table-data">
                <div className="flex team-card">
                  <label className="d-block d-sm-block d-md-block d-lg-none mb-0 table-data-label">
                    Department
                  </label>
                  <div className="department">{data?.department?.name}</div>
                </div>
              </div>

              <div className="table-data flex justify-content-center">
                <div className="flex team-card justify-content-center align-items-center">
                  {/*<img src={DownloadIcon} />*/}
                  <Button
                    className="remove-buttons"
                    onClick={() => deleteTeamMember(data?.id)}
                  >
                    <img className="remove-buttons-img" src={CrossIcon} />
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          );
        }): 
          <NoDataFound />
        }
      </div>
    </div>
  );
};

export default withRouter(TeamList);
