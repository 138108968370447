import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import ProfileUpWhite from "../../../../assets/images/inventive_byte/profile-white.png";
import ProfileUpload from "../../../../assets/images/inventive_byte/profile-upload.png";
import LeftPanel from "../../LeftPanel";
import Header from "../../Header/Header";
import HeaderMobile from "../../Header/HeaderMobile";
import VerifyEmail from "./VerifyEmail";
import "./EditClientProfile.scss";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import * as ClientActions from "../../../../redux/ClientFlowControl/ClientActions";
import { message, Spin, Upload } from "antd";
import AntdImgCrop from "antd-img-crop";
import api from "../../../../utils/api";
import { CameraOutlined } from "@ant-design/icons";


const EditClientProfile = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loader, setLoader] = useState(false)
  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);
  const [emailError, setEmailError] = useState(false)
  console.log(data,"datadata")
  const { loading, single_client } = useSelector(
    (state) => state.ClientReducers
  );
  const handleVerifyEmail = () => {
    if(!data?.profile_image) {
      delete data?.profile_image
    }
    dispatch(ClientActions.editClient(
      params.id, data,
      (res) => {
        message.success(res);
        // setActive(true);
      },
      (err) => {
        message.error(err || "Failed to edit client.");
      }
    ));
  };

  useEffect(() => {
    dispatch(ClientActions.getClientDetail(params?.id));
  },[])

  useEffect(() => {
    if(single_client?.data) {
      setData({
        first_name: single_client?.data?.first_name,
        last_name: single_client?.data?.last_name,
        email: single_client?.data?.email,
        active: single_client?.data?.active,
        time_zone: single_client?.data?.time_zone,
        profile_image: single_client?.data?.profile_image,
      });
    }
  }, [single_client]);

  const onChangeData = (event, key) => {
    let newData = {...data}
    newData[key] = event
    if(key === "email") {
      const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (validEmail.test(event)) {
        setEmailError(false)
      }else {
        setEmailError(true)
      }
    }
    setData(newData)
  }
  const timeZones = moment.tz.names();

  const uploadProfilePic = ({ file }) => {
    setLoader(true);
    api
      .get(
        `${process.env.REACT_APP_API_URL}/admin/getPresignedUrl?key_type=client_profile&file_name=${file.name}`
      )
      .then((data) => {
        console.log(data,"datadatadata")
        updateProfile({ profile_image: data?.data?.fields?.key });
        uploadFile(file, data?.data?.fields, data?.data?.url);
      })
      .catch((err) => console.log(err));
  };

  const updateProfile = (bodyFormData) => {
    api
      .put(
        `${process.env.REACT_APP_API_URL}/admin/client/${params.id}`,
        bodyFormData
      )
      .then((res) => {
        setLoader(false);
        if (res.result) {
          message.info(res.data);
          setLoader(false);
        } else {
          message.error(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadFile = (file, fields, uploadURL) => {
    console.log(file,fields, uploadURL, "file[0]");
    const formData = new FormData();
    formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
    formData.append("key", fields?.key);
    formData.append("policy", fields?.policy);
    formData.append("signature", fields?.signature);
    formData.append("file", file);

    // Upload file to s3 bucket
    let request = new XMLHttpRequest();
    request.open("POST", uploadURL);
    request.send(formData)
    setData({...data, profile_image: fields?.key})
  };

  return (
    <div style={{width: '100%'}}>
      {loading ? <Loader /> : <>
      <div className="edit-lead-main">
        <span className="edit-lead-headding">Edit client Info</span>
        {active ? (
          <VerifyEmail />
        ) : (
          <div className="flex align-items-center justify-content-center flex-column edit-lead-wrapper">
            <div className="edit-lead-profile">
              {/* <img src={data?.profile_image ? `https://d3u1baow9deq5h.cloudfront.net/${data?.profile_image}` : ProfileUpload} /> */}
              <div className="profile-img-wrapper avatarMainCon">
                {loader ? (
                  <Spin className="profilePicLoader" />
                ) : (
                  !data?.profile_image ? (
                    <p className="d-flex align-items-center justify-content-center user-icon" style={{width: '100%', height: '100%'}}>
                      {data?.first_name?.charAt(0).toUpperCase()}
                    </p>
                  ) : (
                    <img
                      className="blog-img user-icon"
                      style={{width: '100%', height: '100%'}}
                      src={process.env.REACT_APP_IMAGE_URL + data?.profile_image}
                    />
                  )
                )}

                <AntdImgCrop rotate>
                  <Upload
                    id="myFileInput"
                    style={{ display: "none" }}
                    showUploadList={false}
                    customRequest={(e) => uploadProfilePic(e)}
                    accept=".png, .jpeg, .jpg"
                  >
                    <CameraOutlined className="profile-camera-img" />
                  </Upload>
                </AntdImgCrop>
              </div>
            </div>
              <FormGroup className="edit-lead-type blog-form-group">
              <div className="edit-lead-toggle-wrapper">
                <div
                  className="btn-group btn-toggle custom-toggle-button custom-switch"
                  data-toggle="buttons"
                >
                  <label className={data?.active === true ? 'btn btn-default active' : 'btn btn-default'} onClick={() => onChangeData(true, "active")}>Active</label>
                  <label className={data?.active === false ? 'btn btn-default active' : 'btn btn-default'} onClick={() => onChangeData(false, "active")}>Inactive</label>
                </div>
              </div>
              </FormGroup>
            <div className="edit-lead-formgroup d-flex align-items-center justify-content-center">

              <Form>
                <div className="d-flex name-wrapper">
                  <FormGroup className="mb-4">
                    <Label className="edit-lead-lable" for="first_name">
                      First Name
                    </Label>
                    <Input
                      className="edit-lead-input"
                      type="text"
                      name="first_name"
                      id="first_name"
                      onChange={(event) => onChangeData(event.target.value, 'first_name')}
                      value={data?.first_name}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="edit-lead-lable" for="last_name">
                      Last Name
                    </Label>
                    <Input
                      className="edit-lead-input"
                      type="text"
                      name="last_name"
                      id="last_name"
                      onChange={(event) => onChangeData(event.target.value, 'last_name')}
                      value={data?.last_name}
                    />
                  </FormGroup>
                </div>
                <FormGroup className="mb-4">
                  <Label className="edit-lead-lable" for="email">
                    Email Address
                  </Label>
                  <Input
                    className="edit-lead-input"
                    type="text"
                    name="email"
                    id="email"
                    onChange={(event) => onChangeData(event.target.value, 'email')}
                    value={data?.email}
                  />
                  {emailError ? <span style={{color: 'red'}}>Please enter valid email</span>:null}
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label className="edit-lead-lable" for="time_zone">
                    Time Zone
                  </Label>
                  <select
                    className="edit-lead-input w-100"
                    name="time_zone"
                    id="time_zone"
                    onChange={(event) => onChangeData(event.target.value, 'time_zone')}
                    value={data?.time_zone}
                  >
                    <option value=""></option>
                    {timeZones?.map((time_zone) => {
                      return (
                        <option
                          value={time_zone}
                        >{`${
                          time_zone.split("/")[time_zone.split("/").length - 1]
                        } (GMT${moment.tz(time_zone).format("Z")})`}</option>
                      );
                    })}
                  </select>
                </FormGroup>
                <FormGroup className="mb-4">
                  <div className="flex align-items-center justify-content-center edit-lead-button">
                    <Button
                      className="edit-lead-button-save"
                      onClick={handleVerifyEmail}
                      disabled={(data?.first_name && data?.last_name && data?.email && data?.time_zone && data?.email && !emailError) ? false : true}
                    >
                      Save
                    </Button>
                    <Button className="edit-lead-button-cencle" onClick={() => props.history.goBack()}>
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </div>
        )}
      </div>
      </>}
    </div>
  );
};

export default withRouter(EditClientProfile);
