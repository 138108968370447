import React, { useState } from "react";
import "./EditProjectNameAndDesc.scss";

// import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Space } from "antd";
// import { updateProjectData } from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as LeadActions from "../../../../redux/LeadFlowControl/LeadActions";

const EditProjectNameAndDesc = (props) => {
  console.log(props,"propsprops")
  const params = useParams()
  const [updatedData, setupdatedData] = useState({ projectName: props?.location?.state?.name, ProjectDisc: props?.location?.state?.description });
  const [loader, setLoader] = useState(false)
  const [projectDiscErr, setProjectDiscErr] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch();
  const initialValues = {
    projectName: props?.location?.state?.name,
    ProjectDisc: props?.location?.state?.description,
  };

  const handleChange = (e) => {
    setupdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const rules = {
    projectName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter the project name");
          } else if (new RegExp("^[A-Za-z]( ?[A-Za-z0-9] ?)*$").test(value)) {
            return Promise.resolve();
          }
          return Promise.reject("Please enter valid project name");
        },
      },
    ],
    ProjectDisc: [
      {
        validator(_, value) {
          if (!value) {
            setProjectDiscErr(true);
            return Promise.reject("Please enter the project description");
          }
          setProjectDiscErr(false);
          return Promise.resolve();
        },
      },
    ],
  };

  const handleSubmit = (formData) => {
    setLoader(true)
    dispatch(LeadActions.saveClientProject(
      params.id, {name: formData?.projectName, description: formData?.ProjectDisc},
      (res) => {
        message.success("Project detail updated successfully");
        setLoader(false)
        history.goBack()
      },
      (err) => {
        setLoader(false)
        message.error(err || "Failed to update Project.");
      }
    ));
  };

  return (
    <div className="editProjectNameAndDescPage-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">
                  Edit project name & Description
                </div>
                <Form onFinish={handleSubmit} initialValues={initialValues}>
                  <div className="fieldsCon">
                    <div className="fieldConText">Project Name</div>
                    <Form.Item rules={rules.projectName} name="projectName">
                      <Input
                        size="large"
                        name="projectName"
                        onChange={handleChange}
                        defaultValue={initialValues?.projectName}
                      />
                    </Form.Item>
                  </div>
                  <div className="fieldsCon">
                    <div className="fieldConText">Project Description</div>
                    <Form.Item
                      name="ProjectDisc"
                      rules={rules.ProjectDisc}
                      className={`custom-edit-textarea ${
                        projectDiscErr ? "error-wrapper" : ""
                      }`}
                    >
                      <Input.TextArea
                        style={{ height: 180 }}
                        name="ProjectDisc"
                        onChange={handleChange}
                        defaultValue={initialValues?.ProjectDisc}
                      />
                    </Form.Item>
                  </div>
                  <div className="buttonContainer">
                    <Space>
                      <Button
                        className="sendButton"
                        htmlType="submit"
                        loading={loader}
                      >
                        Send
                      </Button>
                      <Button
                        className="cancelButton"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectNameAndDesc;
