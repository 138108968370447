import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import UploadPDFIcon from "../../../../../assets/images/inventive_byte/upload-pdf-icon.png";
import TextEditorIcon from "../../../../../assets/images/inventive_byte/text-editor.svg";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import Header from "../../../Header/Header";
import HeaderMobile from "../../../Header/HeaderMobile";
import LeftPanel from "../../../LeftPanel";
import AddTextAgreement from "./AddTextAgreement";
import UploadAgreement from "./UploadAgreement";
import "./AddAgreementMain.scss";

const AddAgreementMain = (props) => {
  const params = useParams()
  const [selectAgreement, setSelectAgreement] = useState("text-editor")

  const selectAgreementOption = (value) => {
    if(value === "text-editor"){
      setSelectAgreement("text-editor")
    }else{
      setSelectAgreement("upload-pdf")
    }
  }

  const onSubmit = () => {
    if(selectAgreement === "text-editor"){
      props.history.push(`/inventive/lead/agreement/text-editor/${params?.id}`)
    }else{
      props.history.push(`/inventive/lead/agreement/upload-pdf/${params?.id}`)
    }
  }

  return (
    <div className="add-agreement-wrapper">
      <div className="header-left d-flex align-items-center justify-content-between">
        <span
          className="back-button flex align-items-center"
          onClick={() => props.history.goBack()}
        >
          <ArrowLeftOutlined /> Back
        </span>
      </div>

        <div className="add-agreement-main">
          <div className="d-flex align-items-center justify-content-center agreement-head-wrapper">
            <h2 className="agreement-head">Add Agreement</h2>
          </div>
          <div className="add-agreement-card-wrapper d-flex flex-column flex-md-row align-items-center justify-content-around">
            <div className={selectAgreement==="text-editor" ? "agreement-editor-wrapper d-flex align-items-center justify-content-center active" : "agreement-editor-wrapper d-flex align-items-center justify-content-center"} onClick={()=>{selectAgreementOption("text-editor")}}>
              <img
                className="img-fluid"
                src={TextEditorIcon}
              />
              <span className="agreement-editor-text">text Editor</span>
            </div>
            <div className={selectAgreement==="upload-pdf" ? "agreement-upload-wrapper d-flex align-items-center justify-content-center active" : "agreement-upload-wrapper d-flex align-items-center justify-content-center" } onClick={()=>{selectAgreementOption("upload-pdf")}}>
              <img
                className="img-fluid"
                src={UploadPDFIcon}
              />
              <span className="agreement-upload-text">Upload PDF</span>
            </div>
          </div>
          <div className="add-agreement-button d-flex align-items-center justify-content-center">
            <button className="agreement-button" onClick={onSubmit}>submit</button>
          </div>
        </div>

    </div>
  );
};

export default withRouter(AddAgreementMain);
