import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../Header/Header";
import HeaderMobile from "../Header/HeaderMobile";
import LeftPanel from "../LeftPanel";
import Eye from "../../../../src/assets/images/inventive_byte/eye.png";
import { Form, Input, Button, message, Spin } from "antd";
import SettingIcon from "../../../assets/images/inventive_byte/setting-icon.png";
import "./ChangePassword.scss";
import api from '../../../utils/api'
import { useDispatch, useSelector } from "react-redux";

const Profile = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false)
    const onFinish = (values) => {
        setLoader(true)
        api
        .post(`${process.env.REACT_APP_API_URL}/admin/changePassword`, values)
        .then(res => {
          setLoader(false)
          if(res.result) {
            form.setFieldsValue({
                old_password: '',
                new_password: '',
                confirm_password: ''
            })
            message.success(res.data)
          }else {
            message.error(res.error)
          }
        })
        .catch(error => {
          console.log(error,"error")
        })
    }

    return (
        <div className="change-password-main-wrapper">
            <div className="d-flex align-items-center">
            <img src={SettingIcon} />
            <h6 className="mb-0 ml-2 setting-text ">Settings</h6>
            </div>
            <div className="form-main-wrapper">
                <div className="form-header-wrap d-flex align-items-center d-lg-none">
                <span className="mr-3">
                    <i className="fa fa-facebook"></i>
                </span>
                    <h6 className="mb-0">Change Password</h6>
                </div>
                <div className="custom-form-wrapper d-flex flex-lg-row flex-column-reverse align-items-center justify-content-center">
                    <Form className="form-content" onFinish={onFinish} name="basic" form={form}
                        initialValues={{
                            old_password: '',
                            new_password: '',
                            confirm_password: ''
                        }}>
                        <div className="form-header-wrap d-lg-block d-none">
                            <h6>Change Password</h6>
                        </div>
                        <Form.Item className="input-btn-wrapper" 
                            name="old_password"
                            rules={[
                                {
                                    min: 8,
                                    message: 'Password must be 8 charcters!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your Old Password!',
                                },
                            ]}>
                            <Input.Password
                                placeholder="Current Password"
                                className="input-box text-truncate"
                            />
                        </Form.Item>

                        <Form.Item className="input-btn-wrapper" 
                            name="new_password"
                            rules={[
                                {
                                    min: 8,
                                    message: 'Password must be 8 charcters!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your New Password!',
                                },
                            ]}>
                            <Input.Password
                                className="input-box text-truncate"
                                placeholder="New Password"
                            />
                        </Form.Item>
                        <Form.Item className="input-btn-wrapper" 
                            label=""
                            name="confirm_password"
                                rules={[
                                    {
                                        min: 8,
                                        message: 'Password must be 8 charcters!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your Confirm Password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                            
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                            <Input.Password
                                placeholder="Confirm New Password"
                                className="input-box text-truncate"
                            />
                        </Form.Item>
                        <div className="d-flex justify-content-center">
                            <Button className="btn-submit d-flex justify-content-center align-items-center" htmlType="submit" style={{height: 42}} disabled={loader}>
                                {loader ? <Spin /> : <span>Change Password</span>}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Profile);
