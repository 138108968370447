import * as actionTypes from "./ActionTypes";

const initialState = {
  loading: false,
  perkList: [],
  perkDetail: null
};

const PerkReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PERK_SUCCESS:
      return {
        ...state,
        loading: false,
        perkList: action.payload,
      };
    case actionTypes.GET_PERK_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_PERK_LIST:
        return {
          ...state,
          loading: false,
          perkList: action.payload
        };
      case actionTypes.GET_PERK_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PERK_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        perkDetail: action.payload,
      };
    case actionTypes.GET_PERK_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      };
      case actionTypes.ADD_PERK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_PERK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_PERK_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default PerkReducers;
